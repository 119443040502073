/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Archive } from '../model/archive';
import { ChildProfileBulkDelete } from '../model/childProfileBulkDelete';
import { ChildProfileCreate } from '../model/childProfileCreate';
import { ChildProfileDetail } from '../model/childProfileDetail';
import { ChildProfileUpdate } from '../model/childProfileUpdate';
import { ChildTransfer } from '../model/childTransfer';
import { ClassChildren } from '../model/classChildren';
import { CurrentChildAbsence } from '../model/currentChildAbsence';
import { ImportResults } from '../model/importResults';
import { PaginatedChildProfileListList } from '../model/paginatedChildProfileListList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChildrenService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Detail children EP.
     * @param attendanceRecordGroupId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenAbsenceDestroy(attendanceRecordGroupId: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenAbsenceDestroy(attendanceRecordGroupId: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenAbsenceDestroy(attendanceRecordGroupId: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenAbsenceDestroy(attendanceRecordGroupId: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attendanceRecordGroupId === null || attendanceRecordGroupId === undefined) {
            throw new Error('Required parameter attendanceRecordGroupId was null or undefined when calling childrenAbsenceDestroy.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenAbsenceDestroy.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/children/${encodeURIComponent(String(id))}/absence/${encodeURIComponent(String(attendanceRecordGroupId))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param id 
     * @param inGroup 
     * @param isArchived 
     * @param primaryClass 
     * @param search A search term.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenAbsenceList(id: string, inGroup?: string, isArchived?: boolean, primaryClass?: string, search?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CurrentChildAbsence>>;
    public childrenAbsenceList(id: string, inGroup?: string, isArchived?: boolean, primaryClass?: string, search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CurrentChildAbsence>>>;
    public childrenAbsenceList(id: string, inGroup?: string, isArchived?: boolean, primaryClass?: string, search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CurrentChildAbsence>>>;
    public childrenAbsenceList(id: string, inGroup?: string, isArchived?: boolean, primaryClass?: string, search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenAbsenceList.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inGroup !== undefined && inGroup !== null) {
            queryParameters = queryParameters.set('in_group', <any>inGroup);
        }
        if (isArchived !== undefined && isArchived !== null) {
            queryParameters = queryParameters.set('is_archived', <any>isArchived);
        }
        if (primaryClass !== undefined && primaryClass !== null) {
            queryParameters = queryParameters.set('primary_class', <any>primaryClass);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CurrentChildAbsence>>('get',`${this.basePath}/children/${encodeURIComponent(String(id))}/absence/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenBulkArchiveCreate(body: Archive, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenBulkArchiveCreate(body: Archive, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenBulkArchiveCreate(body: Archive, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenBulkArchiveCreate(body: Archive, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenBulkArchiveCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/bulk-archive/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenBulkDeleteCreate(body: ChildProfileBulkDelete, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenBulkDeleteCreate(body: ChildProfileBulkDelete, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenBulkDeleteCreate(body: ChildProfileBulkDelete, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenBulkDeleteCreate(body: ChildProfileBulkDelete, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenBulkDeleteCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/bulk-delete/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenCreate(body: ChildProfileCreate, observe?: 'body', reportProgress?: boolean): Observable<ChildProfileDetail>;
    public childrenCreate(body: ChildProfileCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChildProfileDetail>>;
    public childrenCreate(body: ChildProfileCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChildProfileDetail>>;
    public childrenCreate(body: ChildProfileCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChildProfileDetail>('post',`${this.basePath}/children/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenDestroy(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenDestroy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenDestroy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenDestroy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenDestroy.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/children/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param file 
     * @param actionType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenImportCreateForm(file: Blob, actionType: string, observe?: 'body', reportProgress?: boolean): Observable<ImportResults>;
    public childrenImportCreateForm(file: Blob, actionType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportResults>>;
    public childrenImportCreateForm(file: Blob, actionType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportResults>>;
    public childrenImportCreateForm(file: Blob, actionType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling childrenImportCreate.');
        }

        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling childrenImportCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<ImportResults>('post',`${this.basePath}/children/import/${encodeURIComponent(String(actionType))}/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param inGroup 
     * @param isArchived 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @param primaryClass 
     * @param search A search term.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenList(inGroup?: string, isArchived?: boolean, limit?: number, offset?: number, primaryClass?: string, search?: string, observe?: 'body', reportProgress?: boolean): Observable<PaginatedChildProfileListList>;
    public childrenList(inGroup?: string, isArchived?: boolean, limit?: number, offset?: number, primaryClass?: string, search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedChildProfileListList>>;
    public childrenList(inGroup?: string, isArchived?: boolean, limit?: number, offset?: number, primaryClass?: string, search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedChildProfileListList>>;
    public childrenList(inGroup?: string, isArchived?: boolean, limit?: number, offset?: number, primaryClass?: string, search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inGroup !== undefined && inGroup !== null) {
            queryParameters = queryParameters.set('in_group', <any>inGroup);
        }
        if (isArchived !== undefined && isArchived !== null) {
            queryParameters = queryParameters.set('is_archived', <any>isArchived);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (primaryClass !== undefined && primaryClass !== null) {
            queryParameters = queryParameters.set('primary_class', <any>primaryClass);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedChildProfileListList>('get',`${this.basePath}/children/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenRemoveFromClassCreate(body: ClassChildren, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenRemoveFromClassCreate(body: ClassChildren, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenRemoveFromClassCreate(body: ClassChildren, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenRemoveFromClassCreate(body: ClassChildren, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenRemoveFromClassCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/remove-from-class/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenRetrieve(id: string, observe?: 'body', reportProgress?: boolean): Observable<ChildProfileDetail>;
    public childrenRetrieve(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChildProfileDetail>>;
    public childrenRetrieve(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChildProfileDetail>>;
    public childrenRetrieve(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenRetrieve.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ChildProfileDetail>('get',`${this.basePath}/children/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenTransferToClassCreate(body: ChildTransfer, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public childrenTransferToClassCreate(body: ChildTransfer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public childrenTransferToClassCreate(body: ChildTransfer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public childrenTransferToClassCreate(body: ChildTransfer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenTransferToClassCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/transfer-to-class/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenUpdate(body: ChildProfileUpdate, id: string, observe?: 'body', reportProgress?: boolean): Observable<ChildProfileDetail>;
    public childrenUpdate(body: ChildProfileUpdate, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChildProfileDetail>>;
    public childrenUpdate(body: ChildProfileUpdate, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChildProfileDetail>>;
    public childrenUpdate(body: ChildProfileUpdate, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling childrenUpdate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChildProfileDetail>('put',`${this.basePath}/children/${encodeURIComponent(String(id))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Detail children EP.
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenUploadProfileImageUpdateForm(id: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ChildProfileDetail>;
    public childrenUploadProfileImageUpdateForm(id: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChildProfileDetail>>;
    public childrenUploadProfileImageUpdateForm(id: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChildProfileDetail>>;
    public childrenUploadProfileImageUpdateForm(id: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling childrenUploadProfileImageUpdate.');
        }


        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<ChildProfileDetail>('put',`${this.basePath}/children/${encodeURIComponent(String(id))}/upload-profile-image/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_CONFIG, AppConfig } from '@isophi/core-ui';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { environment } from '../environments/environment';
import { ApiModule } from './api';
import { ApiInterceptor } from './api/api-interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { InitService } from './core/services/init.service';
import { ChildrenModule } from './modules/children/children.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './shared/transloco-root.module';

registerLocaleData(localeCs, 'cs');

export function initApplication(initService: InitService) {
  return () => initService.initApplication();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule.forRoot(),
    HttpClientModule,
    DashboardModule,
    ChildrenModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga_measurement_id),
    NgxGoogleAnalyticsRouterModule,
    LayoutModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [InitService],
    },
    {
      provide: APP_CONFIG,
      useValue: {
        i18n: environment.i18n,
      } as AppConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChildProfileList, ChildrenService, PaginatedChildProfileListList } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import { RouterLinkFactory } from '../../core/router/router-link.factory';
import { HelperService } from '../../core/services/helper.service';
import { FilterCriteria } from './components/children-filter/filter-criteria.model';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildrenComponent implements OnInit, OnDestroy {
  children$: Observable<PaginatedChildProfileListList>;

  count: number;

  page = 1;

  limit = 100;

  offset = 0;

  markedChildren: ChildProfileList[] = [];

  private filterCriteria: FilterCriteria = {
    group: '',
    name: '',
    archived: false,
  };

  private childrenReload$ = new BehaviorSubject<void>(null);

  constructor(
    private helperService: HelperService,
    private service: ChildrenService,
    private router: Router,
    private routerLinkFactory: RouterLinkFactory,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.helperService.setTitle(location.pathname);
    this.children$ = this.childrenReload$.pipe(
      switchMap(() =>
        this.service.childrenList(
          this.filterCriteria.group,
          this.filterCriteria.archived,
          this.limit,
          this.offset,
          '',
          this.filterCriteria.name
        )
      ),
      tap((children) => {
        const childText =
          children.count > 1 ? this.translocoService.translate('shared/children') : this.translocoService.translate('shared/child');
        this.helperService.setSmallTextTitle(`${children.count} ${childText}`);
        this.resetMarkedChildren();
      })
    );
  }

  reloadChildren(): void {
    this.childrenReload$.next();
  }

  setFilter(filterCriteria: FilterCriteria): void {
    this.filterCriteria = filterCriteria;
    this.page = 1;
    this.offset = 0;
    this.reloadChildren();
  }

  openChild(id: string): void {
    this.router.navigate(this.routerLinkFactory.childrenDetail(id));
  }

  onPageChange(page: number): void {
    this.offset = (page - 1) * this.limit;
    this.reloadChildren();
  }

  markAllChildren(children: ChildProfileList[]): void {
    if (this.markedChildren.length === children.length) {
      this.resetMarkedChildren();
    } else {
      this.markedChildren = [...children];
    }
  }

  areAllChildrenMarked(children: ChildProfileList[]): boolean {
    return this.markedChildren.length === children.length && children.length !== 0;
  }

  resetMarkedChildren(): void {
    this.markedChildren = [];
  }

  isChildMarked(child: ChildProfileList): boolean {
    return this.markedChildren.findIndex((ch) => ch.id === child.id) !== -1;
  }

  markChild(child: ChildProfileList): void {
    const childIndex = this.markedChildren.findIndex((ch) => ch.id === child.id);

    const updatedChildren = [...this.markedChildren];
    if (childIndex === -1) {
      updatedChildren.push(child);
    } else {
      updatedChildren.splice(childIndex, 1);
    }

    this.markedChildren = updatedChildren;
  }

  ngOnDestroy(): void {
    this.helperService.clearSmallTextTitle();
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AttendanceOverview } from '../model/attendanceOverview';
import { ChildAttendance } from '../model/childAttendance';
import { ChildAttendanceCount } from '../model/childAttendanceCount';
import { ClassCreateOrUpdateAttendance } from '../model/classCreateOrUpdateAttendance';
import { ExpectedAttendance } from '../model/expectedAttendance';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AttendanceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Returns class overview for a class and date range, one item is for a day and a child in a class. The status filter is optional, absent status in request applies even to partial absence. Present status in request applies to present and left statuses, even if not present for an whole day. Weekends are left out.  Response status unexcused applies to all absence out of order - leaving too early, comming too late, being absent and only excused for half day, also applies to children with no status set yet, for example in the future.
     * @param classUuid Class UUID
     * @param dateFrom Date from
     * @param dateTo Date to
     * @param withAverageAttendance Does the response include average attendance count
     * @param withDailyAttendance Does the response include attendance counts for each date
     * @param status Filter by status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceClassAttendanceOverviewRetrieve(classUuid: string, dateFrom: string, dateTo: string, withAverageAttendance: boolean, withDailyAttendance: boolean, status?: string, observe?: 'body', reportProgress?: boolean): Observable<AttendanceOverview>;
    public attendanceClassAttendanceOverviewRetrieve(classUuid: string, dateFrom: string, dateTo: string, withAverageAttendance: boolean, withDailyAttendance: boolean, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttendanceOverview>>;
    public attendanceClassAttendanceOverviewRetrieve(classUuid: string, dateFrom: string, dateTo: string, withAverageAttendance: boolean, withDailyAttendance: boolean, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttendanceOverview>>;
    public attendanceClassAttendanceOverviewRetrieve(classUuid: string, dateFrom: string, dateTo: string, withAverageAttendance: boolean, withDailyAttendance: boolean, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (classUuid === null || classUuid === undefined) {
            throw new Error('Required parameter classUuid was null or undefined when calling attendanceClassAttendanceOverviewRetrieve.');
        }

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling attendanceClassAttendanceOverviewRetrieve.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling attendanceClassAttendanceOverviewRetrieve.');
        }

        if (withAverageAttendance === null || withAverageAttendance === undefined) {
            throw new Error('Required parameter withAverageAttendance was null or undefined when calling attendanceClassAttendanceOverviewRetrieve.');
        }

        if (withDailyAttendance === null || withDailyAttendance === undefined) {
            throw new Error('Required parameter withDailyAttendance was null or undefined when calling attendanceClassAttendanceOverviewRetrieve.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('date_from', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('date_to', <any>dateTo);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (withAverageAttendance !== undefined && withAverageAttendance !== null) {
            queryParameters = queryParameters.set('with_average_attendance', <any>withAverageAttendance);
        }
        if (withDailyAttendance !== undefined && withDailyAttendance !== null) {
            queryParameters = queryParameters.set('with_daily_attendance', <any>withDailyAttendance);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AttendanceOverview>('get',`${this.basePath}/attendance/class-attendance-overview/${encodeURIComponent(String(classUuid))}/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceCreateClassAttendanceGroupCreate(body: ClassCreateOrUpdateAttendance, observe?: 'body', reportProgress?: boolean): Observable<Array<ChildAttendance>>;
    public attendanceCreateClassAttendanceGroupCreate(body: ClassCreateOrUpdateAttendance, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChildAttendance>>>;
    public attendanceCreateClassAttendanceGroupCreate(body: ClassCreateOrUpdateAttendance, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChildAttendance>>>;
    public attendanceCreateClassAttendanceGroupCreate(body: ClassCreateOrUpdateAttendance, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling attendanceCreateClassAttendanceGroupCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ChildAttendance>>('post',`${this.basePath}/attendance/create-class-attendance-group/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns children presence counts for today, for no evidence, present or absent status request param.
     * @param classUuid Class UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceCurrentClassAttendanceCountsRetrieve(classUuid: string, observe?: 'body', reportProgress?: boolean): Observable<ChildAttendanceCount>;
    public attendanceCurrentClassAttendanceCountsRetrieve(classUuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChildAttendanceCount>>;
    public attendanceCurrentClassAttendanceCountsRetrieve(classUuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChildAttendanceCount>>;
    public attendanceCurrentClassAttendanceCountsRetrieve(classUuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (classUuid === null || classUuid === undefined) {
            throw new Error('Required parameter classUuid was null or undefined when calling attendanceCurrentClassAttendanceCountsRetrieve.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ChildAttendanceCount>('get',`${this.basePath}/attendance/current-class-attendance-counts/${encodeURIComponent(String(classUuid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Return current date class attendance for class for a status. Returned values are different in the morning and in the afternoon.  | Param              | Note                                                                                    | | ------------------ | --------------------------------------------------------------------------------------- | | class UUID         |                                                                                         | | status             | No evidence, present, absent. Takes into account current time of the day.               |
     * @param classUuid Class UUID
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceCurrentClassAttendanceList(classUuid: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChildAttendance>>;
    public attendanceCurrentClassAttendanceList(classUuid: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChildAttendance>>>;
    public attendanceCurrentClassAttendanceList(classUuid: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChildAttendance>>>;
    public attendanceCurrentClassAttendanceList(classUuid: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (classUuid === null || classUuid === undefined) {
            throw new Error('Required parameter classUuid was null or undefined when calling attendanceCurrentClassAttendanceList.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling attendanceCurrentClassAttendanceList.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChildAttendance>>('get',`${this.basePath}/attendance/current-class-attendance/${encodeURIComponent(String(classUuid))}/${encodeURIComponent(String(status))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns expected children presence in the morning and afternoon for today, counts as total child count minus absenct child count.
     * @param classUuid Class UUID
     * @param date Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceCurrentClassExpectedAttendanceCountsRetrieve(classUuid: string, date?: string, observe?: 'body', reportProgress?: boolean): Observable<ExpectedAttendance>;
    public attendanceCurrentClassExpectedAttendanceCountsRetrieve(classUuid: string, date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpectedAttendance>>;
    public attendanceCurrentClassExpectedAttendanceCountsRetrieve(classUuid: string, date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpectedAttendance>>;
    public attendanceCurrentClassExpectedAttendanceCountsRetrieve(classUuid: string, date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (classUuid === null || classUuid === undefined) {
            throw new Error('Required parameter classUuid was null or undefined when calling attendanceCurrentClassExpectedAttendanceCountsRetrieve.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExpectedAttendance>('get',`${this.basePath}/attendance/current-class-expected-attendance-counts/${encodeURIComponent(String(classUuid))}/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Set current class attendance for the current user&#x27;s class. Returned data are in the same format as current attendence GET endpoint.  | Param              | Note                                                                                    | | ------------------ | --------------------------------------------------------------------------------------- | | class UUID         |                                                                                         | | child UUIDs        |                                                                                         | | status             | Present sets arrival time, left sets departure time.                                    | | absence            | three absence options - morning, afternoon and all day,                                 | |                    | no_evidence resets the attendance.                                                      | | absence_reason     | Optional, for absence only.                                                             |
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attendanceSetCurrentClassAttendanceCreate(body: ClassCreateOrUpdateAttendance, observe?: 'body', reportProgress?: boolean): Observable<Array<ChildAttendance>>;
    public attendanceSetCurrentClassAttendanceCreate(body: ClassCreateOrUpdateAttendance, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChildAttendance>>>;
    public attendanceSetCurrentClassAttendanceCreate(body: ClassCreateOrUpdateAttendance, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChildAttendance>>>;
    public attendanceSetCurrentClassAttendanceCreate(body: ClassCreateOrUpdateAttendance, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling attendanceSetCurrentClassAttendanceCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ChildAttendance>>('post',`${this.basePath}/attendance/set-current-class-attendance/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

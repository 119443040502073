import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChildrenComponent } from './children.component';
import { ChildDetailComponent } from './pages/child-detail/child-detail.component';
import { CreateChildCloseRelativeComponent } from './pages/child-detail/create-close-relative/create-close-relative.component';
import { EditCloseRelativeComponent } from './pages/child-detail/edit-close-relative/edit-close-relative.component';
import { CreateChildComponent } from './pages/create-child/create-child.component';
import { EditChildComponent } from './pages/edit-child/edit-child.component';

const routes: Routes = [
  { path: '', component: ChildrenComponent },
  { path: 'new', component: CreateChildComponent },
  { path: ':id', component: ChildDetailComponent },
  { path: ':id/edit', component: EditChildComponent },
  { path: ':id/add-close-relative', component: CreateChildCloseRelativeComponent },
  { path: ':id/edit-close-relative/:closeRelativeId', component: EditCloseRelativeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChildrenRoutingModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EvidenceService {
  public childrenReload$ = new BehaviorSubject<void>(null);

  reloadChildren(): void {
    this.childrenReload$.next();
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `běžná třída` - Common Class * `třída podle § 16, odst. 9 ŠZ` - Class By Paragraph 16
 */
export type ClassTypeDesignationEnum = 'běžná třída' | 'třída podle § 16, odst. 9 ŠZ';

export const ClassTypeDesignationEnum = {
    BnTda: 'běžná třída' as ClassTypeDesignationEnum,
    TdaPodle16Odst9Z: 'třída podle § 16, odst. 9 ŠZ' as ClassTypeDesignationEnum
};
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { ChildProfileDetail, LevelOfTalentEnum } from '@isophi/mng-api';

import { disadvantagesData } from '../../../modules/children/components/child-form/data';

@Component({
  selector: 'app-child-detail-alert-info',
  templateUrl: './child-detail-alert-info.component.html',
  styleUrls: ['./child-detail-alert-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildDetailAlertInfoComponent {
  @Input()
  child: ChildProfileDetail;

  disadvantagesData = disadvantagesData;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  LevelOfTalentEnum = LevelOfTalentEnum;

  constructor(protected iconsService: IconService) {}

  get disadvantages(): string {
    return `${this.child.disadvantages.map((val) => this.disadvantagesData[val]).join(', ')}`;
  }
}

<div class="basic-info-detail" *transloco="let t">
  <app-child-detail-alert-info
    *ngIf="
      child.isHealthDisadvantaged ||
      (child.levelOfTalent && child.levelOfTalent !== LevelOfTalentEnum.BezIdentifikovanhoNadnNeboMimodnhoNadn)
    "
    [child]="child"
  ></app-child-detail-alert-info>

  <form class="flex-form">
    <div class="row no-gutters">
      <div class="form-group">
        <label for="attendanceStart">{{ t('child/attendanceStart') }}</label>
        <div id="attendanceStart" class="value">{{ child.attendanceStart | date: 'd. M. y' }}</div>
      </div>
      <div class="form-group">
        <label for="attendanceStartCode">{{ t('child/attendanceStartCode') }}</label>
        <div id="attendanceStartCode" class="value">
          {{ child.attendanceStartCode | transformEnumToReadableString: attendanceStartCode }}
        </div>
      </div>
      <div class="form-group">
        <label for="attendanceFulfillmentMethod">{{ t('child/attendanceFulfillmentMethod') }}</label>
        <div id="attendanceFulfillmentMethod" class="value">
          {{ child.attendanceFulfillmentMethod | transformEnumToReadableString: attendanceFulfillmentMethod }}
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="attendanceEnd"> {{ t('child/attendanceEnd') }}</label>
        <div id="attendanceEnd" class="value">{{ child.attendanceEnd | date: 'd. M. y' }}</div>
      </div>
      <div class="form-group">
        <label for="attendanceEndCode">{{ t('child/attendanceEndCode') }}</label>
        <div id="attendanceEndCode" class="value">{{ child.attendanceEndCode | transformEnumToReadableString: attendanceEndCode }}</div>
      </div>
      <div class="form-group">
        <label for="educationCourseChoice">{{ t('child/educationCourseChoice') }}</label>
        <div id="educationCourseChoice" class="value">
          {{ child.educationCourseChoice | transformEnumToReadableString: educationCourseChoice }}
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="ignoranceOfLanguage">{{ t('child/knowledgeOfLanguage') }}</label>
        <i *ngIf="!child.ignoranceOfLanguage" [class]="iconsService.checkCircle + ' check'"></i>
        <i *ngIf="child.ignoranceOfLanguage" [class]="iconsService.xCircle + ' warning'"></i>
      </div>
      <div class="form-group">
        <label for="healthInsurance">{{ t('child/healthInsurance') }}</label>
        <div id="healthInsurance" class="value">{{ child.insuranceCompany | transformEnumToReadableString: insuranceCompany }}</div>
      </div>
      <div class="form-group">
        <label for="socialCategoryDisadvantage">{{ t('child/socialCategoryDisadvantage') }}</label>
        <div id="socialCategoryDisadvantage" class="value">{{ socialCategoryDisadvantage[child.socialCategoryDisadvantage] }}</div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="measureLevel">{{ t('child/measureLevel') }}</label>
        <div id="measureLevel" class="value">{{ measureLevel[child.measureLevel] }}</div>
      </div>
      <div class="form-group">
        <label for="disabilityType">{{ t('child/disabilityType') }}</label>
        <div id="disabilityType" class="value">{{ child.disabilityType }}</div>
      </div>
      <div class="form-group">
        <label for="hasHeavyDisability">{{ t('child/hasHeavyDisability') }}</label>
        <i *ngIf="child.hasHeavyDisability" [class]="iconsService.checkCircle + ' check'"></i>
        <i *ngIf="!child.hasHeavyDisability" [class]="iconsService.xCircle + ' warning'"></i>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="isoNumber">{{ t('child/isoNumber') }}</label>
        <div id="isoNumber" class="value">{{ child.izoSpz }}</div>
      </div>
      <div class="form-group">
        <label for="spzRecommendationIssued">{{ t('child/spzRecommendationIssued') }}</label>
        <div id="spzRecommendationIssued" class="value">{{ child.spzRecommendationIssued | date: 'd. M. y' }}</div>
      </div>
      <div class="form-group">
        <label for="spzRecommendationDue">{{ t('child/spzRecommendationDue') }}</label>
        <div id="spzRecommendationDue" class="value">{{ child.spzRecommendationDue | date: 'd. M. y' }}</div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="supportingMeasures">{{ t('child/supportingMeasures') }}</label>
        <div id="supportingMeasures" class="value">{{ child.supportingMeasures }}</div>
      </div>
      <div class="form-group">
        <label for="aidPurchaseForm">{{ t('child/aidPurchaseForm') }}</label>
        <div id="aidPurchaseForm" class="value">{{ child.aidPurchaseForm }}</div>
      </div>
      <div class="form-group">
        <label for="isFundingRequired">{{ t('child/isFundingRequired') }}</label>
        <i *ngIf="child.isFundingRequired" [class]="iconsService.checkCircle + ' check'"></i>
        <i *ngIf="!child.isFundingRequired" [class]="iconsService.xCircle + ' warning'"></i>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="supportingMeasuresStart">{{ t('child/supportingMeasuresStart') }}</label>
        <div id="supportingMeasuresStart" class="value">{{ child.supportingMeasuresStart | date: 'd. M. y' }}</div>
      </div>
      <div class="form-group">
        <label for="supportingMeasuresEnd">{{ t('child/supportingMeasuresEnd') }}</label>
        <div id="supportingMeasuresEnd" class="value">{{ child.supportingMeasuresEnd | date: 'd. M. y' }}</div>
      </div>
    </div>
  </form>
</div>

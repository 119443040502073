<div class="info" *transloco="let t">
  <div class="description">
    <div class="note" *ngIf="child.importantNote">
      <i [class]="iconsService.warning"></i>
      <span>{{ child.importantNote }}</span>
    </div>
    <div class="warning" *ngIf="child.isHealthDisadvantaged">
      <i [class]="iconsService.warning"></i>
      <span>{{ child.disadvantages.length > 0 ? disadvantages : t('closeRelative/unsetDisadvantages') }}</span>
    </div>

    <div class="check" *ngIf="child.levelOfTalent && child.levelOfTalent !== LevelOfTalentEnum.BezIdentifikovanhoNadnNeboMimodnhoNadn">
      <i [class]="iconsService.checkCircle"></i>
      <span>{{ t('child/talentedChild') }}</span>
    </div>
  </div>
</div>

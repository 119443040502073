import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { ChildProfileList, Gender785Enum, MainContactInvitationStatusEnum } from '@isophi/mng-api';

import { MenuTypeEnum } from '../../../../core/enums/menu-type.enum';

@Component({
  selector: 'app-child-row',
  templateUrl: './child-row.component.html',
  styleUrls: ['./child-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildRowComponent {
  @Input() child: ChildProfileList;

  @Input() isChildMarked: boolean;

  @Output() childClick = new EventEmitter<ChildProfileList>();

  @Output() refreshChildren = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender785Enum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuTypeEnum = MenuTypeEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MainContactInvitationStatusEnum = MainContactInvitationStatusEnum;

  constructor(public icons: IconService) {}

  onClick() {
    this.childClick.emit(this.child);
    this.isChildMarked = !this.isChildMarked;
  }

  get secondaryClasses(): string {
    return `(${this.child.secondaryGroupNames.join(', ')})`;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HelperService } from '../../core/services/helper.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  showHeader$: BehaviorSubject<boolean>;

  constructor(private helperService: HelperService) {
    this.showHeader$ = this.helperService.showHeader$;
  }
}

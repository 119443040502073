<div class="d-flex ms-auto align-items-center" *transloco="let t">
  <div class="dropdown">
    <button
      type="button"
      id="messages"
      class="btn nav-link icon btn-white"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i [class]="icons.mail"></i>
      <span class="badge badge-primary">{{ messages.length }}</span>
    </button>

    <!-- Dropdown menu -->
    <div id="dropdownMessages" class="dropdown-menu dropdown-notification" aria-labelledby="messages">
      <div class="header">{{ t('messages/messageTitle') }}</div>
      <div>
        <a *ngFor="let message of messages" href="#" class="notification-link">
          <div class="avatar">
            <img [src]="message.user.imageUrl" [alt]="message.user.fullName + 'image'" class="avatar-image" />
          </div>
          <div class="content">
            <div class="message">
              {{ t('messages/newMessageFrom') }} <span class="sender">{{ message.user.fullName }}</span
              >: "{{ message.message | slice: 0:25 }}{{ message.message.length > 25 ? '...' : '' }}"
            </div>
            <!-- TODO replace with timestamp from backend -->
            <div class="timestamp">před 10 minutami</div>
          </div>
        </a>
        <p *ngIf="messages.length === 0" class="notification-link">{{ t('messages/noNewMessages') }}</p>
      </div>
      <a href="#" class="show-all">
        <div class="show-all-content">
          <svg class="show-all-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
            <path
              d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
            />
          </svg>
          <span>{{ t('messages/showAll') }}</span>
        </div>
      </a>
    </div>
  </div>
  <div class="dropdown">
    <button
      type="button"
      id="notifications"
      class="btn nav-link icon btn-white"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i [class]="icons.notification"></i>
      <span class="badge badge-primary">{{ notifications.length }}</span>
    </button>

    <!-- Dropdown menu -->
    <div id="dropdownNotification" class="dropdown-menu dropdown-notification" aria-labelledby="notifications">
      <div class="header">{{ t('notifications/notificationTitle') }}</div>
      <div>
        <a *ngFor="let notification of notifications" href="#" class="notification-link">
          <div class="avatar">
            <img [src]="notification.user.imageUrl" [alt]="notification.user.fullName + 'image'" class="avatar-image" />
          </div>
          <div class="content">
            <div class="message">
              {{ t('notifications/newNotificationFrom') }} <span class="sender">{{ notification.user.fullName }}</span
              >: "{{ notification.message | slice: 0:25 }}{{ notification.message.length > 25 ? '...' : '' }}"
            </div>
            <!-- TODO add timestamp from backend -->
            <div class="timestamp">dnes v 15:27</div>
          </div>
        </a>
        <p *ngIf="notifications.length === 0" class="notification-link">{{ t('notifications/noNewNotifications') }}</p>
      </div>
      <a href="#" class="show-all">
        <div class="show-all-content">
          <svg class="show-all-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
            <path
              d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
            />
          </svg>
          <span>{{ t('notifications/showAll') }}</span>
        </div>
      </a>
    </div>
  </div>
</div>

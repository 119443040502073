<div class="sidebar" [@slideInOut]="sidebarState" *transloco="let t">
  <div class="header-actions">
    <i [class]="iconsService.close" (click)="toggleSidebar(null)"></i>
    <app-context-menu
      *ngIf="closeRelative$ | withLoader | async as closeRelative"
      [icon]="iconsService.verticalDots"
      [menuType]="MenuTypeEnum.CLOSE_RELATIVE"
      [childId]="child.childId"
      [data]="closeRelative"
      [backgroundColor]="'transparent'"
    ></app-context-menu>
  </div>

  <div class="sidebar-content" *ngIf="closeRelative$ | withLoader | async">
    <div class="profile-head">
      <img *ngIf="gender === GenderEnum.Male" src="assets/img/iconsDefault/boy.svg" alt="boy" width="64px" />
      <img *ngIf="gender === GenderEnum.Female" src="assets/img/iconsDefault/girl.svg" alt="girl" width="64px" />

      <div class="name">{{ closeRelative.firstName }} {{ closeRelative.lastName }}</div>
      <div class="relationship">{{ t(relativeType) }} - {{ t(status) }}</div>
    </div>
    <div class="profile-info">
      <div class="key-value">
        <div class="title">{{ t('closeRelative/phone') }}</div>
        <div class="value">{{ closeRelative.phone ? closeRelative.phone : '-' }}</div>
      </div>
      <div class="key-value">
        <div class="title">{{ t('closeRelative/email') }}</div>
        <div class="value">{{ closeRelative.email ? closeRelative.email : '-' }}</div>
      </div>
      <div class="key-value">
        <div class="title">{{ t('closeRelative/dataBox') }}</div>
        <div class="value">{{ closeRelative.dataBox ? closeRelative.dataBox : '-' }}</div>
      </div>
      <div class="key-value">
        <div class="title">{{ t('closeRelative/bankAccount') }}</div>
        <div class="value">
          {{ closeRelative.bankAccountNumber ? closeRelative.bankAccountNumber : '-' }}
          <ng-container *ngIf="closeRelative.bankCode">/</ng-container> {{ closeRelative.bankCode }}
        </div>
      </div>
      <div class="key-value">
        <div class="title">{{ t('child/birthDate') }}</div>
        <div class="value">{{ closeRelative.birthDate ? (closeRelative.birthDate | date: 'd. M. y') : '-' }}</div>
      </div>
      <div class="key-value">
        <div class="title">{{ t('closeRelative/permanentAddress') }}</div>
        <div class="value">{{ closeRelativeAddress }}</div>
      </div>
    </div>
  </div>
</div>

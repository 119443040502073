/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconService } from '@isophi/core-ui';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor {
  @Input() childForm: FormGroup;

  @Input() formControlName: string;

  @Input() label: string;

  @Input() required? = false;

  @Input() requiredColor? = false;

  @Input() parentVisible? = false;

  @Input() formControlError?: string;

  @Input() placeholder?: string = '';

  constructor(protected iconService: IconService, private cd: ChangeDetectorRef) {}

  getFormControl(): FormControl {
    return this.childForm.get(this.formControlName) as FormControl;
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: any): void {
    this.onChange(value);
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

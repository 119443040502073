<div class="dashboard page-layout" *transloco="let t">
  <div class="row no-gutters">
    <div class="col-xl-6 col-lg-7 col-12">
      <div class="cards-section" *ngIf="{ group: groups$ | withLoader | async, gradebookList: gradeBookList$ | withLoader | async }">
        <app-attendance-today-card [group]="group" [childAttendanceCount]="childAttendanceCount$ | async"></app-attendance-today-card>
        <app-gradebook-card [group]="group" [gradebook]="gradebook"></app-gradebook-card>
      </div>
    </div>
    <div class="col-xl-6 col-lg-5 col-12">
      <app-announcement-card></app-announcement-card>
    </div>
  </div>
</div>

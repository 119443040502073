import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageConstService {
  childrenFilter = 'childrenFilter';

  groupsFilter = 'groupsFilter';

  gradebookFilter = 'gradebookFilter';
}

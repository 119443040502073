<div class="close-relative-row" [ngClass]="{ compact: !showDetail }" *transloco="let t">
  <ng-container *ngIf="closeRelative">
    <div class="basic">
      <div class="avatar">
        <img *ngIf="gender === GenderEnum.Male" src="assets/img/iconsDefault/boy.svg" alt="boy" width="64px" />
        <img *ngIf="gender === GenderEnum.Female" src="assets/img/iconsDefault/girl.svg" alt="girl" width="64px" />
        <ngx-spinner name="primary" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-pulse" name="preview">
          {{ t('shared/loading') }}
        </ngx-spinner>
      </div>

      <div class="close-relative-person-info">
        <div class="name">
          {{ closeRelative.firstName }} {{ closeRelative.lastName }}
          <img
            *ngIf="closeRelative?.invitationState && closeRelative?.invitationState === InvitationStateEnum.Invited"
            class="r-icon"
            src="assets/img/logo/iSophiR-grey.svg"
            alt="iSophiR grey"
            placement="top"
            [ngbTooltip]="t('child/infoGreyR')"
          />
          <img
            *ngIf="closeRelative?.invitationState && closeRelative?.invitationState === InvitationStateEnum.AcceptedInvitation"
            class="r-icon"
            src="assets/img/logo/iSophiR-color.svg"
            alt="iSophiR pink"
            placement="top"
            [ngbTooltip]="t('child/infoPinkR')"
          />
        </div>
        <div class="relationship">{{ t(relativeType) }} - {{ t(status) }}</div>
      </div>

      <div class="actions">
        <div class="action"><i [class]="iconsService.chevronDown" (click)="showDetail = !showDetail"></i></div>
        <div class="action">
          <app-context-menu
            [icon]="iconsService.verticalDots"
            [menuType]="MenuTypeEnum.CLOSE_RELATIVE"
            [childId]="childId"
            [data]="closeRelative"
            [backgroundColor]="'transparent'"
            (refreshData)="refreshCloseRelative.emit()"
          ></app-context-menu>
        </div>
      </div>
    </div>

    <div *ngIf="showDetail" class="detail">
      <div>
        <div class="left">{{ t('closeRelative/phone') }}:</div>
        <a class="right" [href]="'tel:' + closeRelative.phone">{{ closeRelative.phone }}</a>
      </div>
      <div>
        <div class="left">{{ t('closeRelative/email') }}:</div>
        <a class="right" [href]="'mailto:' + closeRelative.email">{{ closeRelative.email }}</a>
      </div>
      <div class="show-all" (click)="showAll()">
        <div class="left">{{ t('closeRelative/allDetail') }}</div>
        <div class="right"></div>
      </div>
    </div>
  </ng-container>
</div>

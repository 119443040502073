import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreLegacyModule } from '@isophi/core-legacy';
import { CoreUiModule } from '@isophi/core-ui';
import { TeachersSharedModule } from '@isophi/teachers-shared';
import { UiTemplateModule } from '@isophi/ui-template';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPrintModule } from 'ngx-print';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ChildDetailAlertInfoComponent } from './components/child-detail-alert-info/child-detail-alert-info.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ExcuseModalComponent } from './components/excuse-modal/excuse-modal.component';
import { GroupDetailCardComponent } from './components/group-detail-card/group-detail-card.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PreviewProductsModalComponent } from './components/preview-products-modal/preview-products-modal.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TranslateAbsenceReasonPipe } from './pipes/absence.pipe';
import { JoinStringPipe } from './pipes/string-join.pipe';
import { TransformEnumToReadableStringPipe } from './pipes/transform-enum-to-readable-string.pipe';

const SHARED_COMPONENTS = [
  ChildDetailAlertInfoComponent,
  ContextMenuComponent,
  TextInputComponent,
  GroupDetailCardComponent,
  ExcuseModalComponent,
  NotFoundComponent,
  PreviewProductsModalComponent,
];
const SHARED_PIPES = [JoinStringPipe, TransformEnumToReadableStringPipe, TranslateAbsenceReasonPipe];

@NgModule({
  imports: [
    CommonModule,
    CoreLegacyModule,
    CoreUiModule,
    NgxSpinnerModule,
    RouterModule,
    UiTemplateModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    TeachersSharedModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgxSimpleTextEditorModule,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  exports: [
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    CoreUiModule,
    NgxSpinnerModule,
    RouterModule,
    UiTemplateModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    TeachersSharedModule,
    ReactiveFormsModule,
    NgxSimpleTextEditorModule,
  ],
  providers: [SHARED_PIPES],
})
export class SharedModule {
  public static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}

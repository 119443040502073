import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinStrings',
})
export class JoinStringPipe implements PipeTransform {
  transform(value: any[]): string {
    return value.map((item) => item.name).join(', ');
  }
}

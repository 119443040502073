<ng-container *transloco="let t">
  <div class="document-info-list" *ngIf="documents$ | withLoader | async as documents">
    <input class="d-none" type="file" #fileInput (change)="addDocument($event)" />
    <button (click)="fileInput.click()" class="btn btn-sm btn-primary add-document">
      <i [class]="iconsService.plus"></i>
    </button>
    <div class="document-content">
      <div class="document-list no-gutters">
        <div class="col table-wrapper">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ t('groups/name') }}</th>
                <th scope="col">{{ t('child/addedDate') }}</th>
                <th scope="col">{{ t('child/size') }}</th>
                <th scope="col">{{ t('child/action') }}</th>
              </tr>
            </thead>
          </table>
          <div class="tbody-scroll">
            <table class="table">
              <tbody>
                <ng-container *ngIf="documents.results.length > 0; else noResults">
                  <tr *ngFor="let document of documents.results">
                    <td>
                      <div class="d-flex align-items-center">
                        <img src="assets/img/documents/pdf.svg" alt="document-icon" />
                        <p class="document-title">
                          {{ document.name }}
                        </p>
                      </div>
                    </td>
                    <td>{{ document.created | date: 'd. M. y' }}</td>
                    <td>
                      {{ document.size | fileSize }}
                      <a [href]="document.file" target="_blank">
                        <i [class]="iconsService.arrowDownCircle"></i>
                      </a>
                    </td>
                    <td>
                      <button (click)="removeDocument($event, document)" class="btn fw-bold p-0 border-r-full">
                        <i [class]="iconsService.verticalDots"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #noResults>
                  <tr class="no-results">
                    <td>{{ t('child/noDocumentFound') }}</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="ngb-pagination d-flex align-items-center justify-content-end p-2" *ngIf="documents.results.length > 0">
      <ngb-pagination
        (pageChange)="onPageChange($event)"
        [collectionSize]="documents.count"
        [(page)]="page"
        [pageSize]="limit"
        [maxSize]="7"
      ></ngb-pagination>
    </div>
  </div>
</ng-container>

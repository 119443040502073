/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GradeBookRecordSecurityInstructionCreateOrUpdate } from './gradeBookRecordSecurityInstructionCreateOrUpdate';

export interface GradeBookRecordCreate { 
    groupUuid: string;
    date: string;
    topicBlockTitle: string;
    topicTitle: string;
    morningActivities?: string;
    afternoonActivities?: string;
    note?: string;
    visitAndHospitalizationNote?: string;
    securityInstructions?: Array<GradeBookRecordSecurityInstructionCreateOrUpdate>;
    createdByName?: string;
}
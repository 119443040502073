import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconService, ToastService } from '@isophi/core-ui';
import {
  AnnouncementsService,
  Archive,
  ChildrenService,
  DayOffsService,
  DayOffTypeEnum,
  InvitationsService,
  InviteSingleCloseRelative,
} from '@isophi/mng-api';
import { PopUpComponent } from '@isophi/teachers-shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

import { MenuTypeEnum } from '../../../core/enums/menu-type.enum';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { UserService } from '../../../core/services/user.service';
import { EditAbsenceModalComponent } from '../../../modules/evidence/components/edit-absence-modal/edit-absence-modal.component';
import { HandleErrorService } from '../../services/handle-error.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent {
  @Input() menuType: MenuTypeEnum;

  @Input()
  icon: string = this.icons.horizontalDots;

  // Types: ChildProfileList || ChildProfileDetail || ChildGroupDetail || CloseRelativeList || GradeBookRecordRead || AnnouncementRead || User || ChildAttendanceOverview || CurrentChildAbsence || KindergartenDayOffRead
  @Input() data;

  @Input()
  childId: string;

  @Input()
  backgroundColor: string;

  @Input() dateFrom?: string;

  @Input() dateTo?: string;

  @Output() refreshData? = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuTypeEnum = MenuTypeEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DayOffTypeEnum = DayOffTypeEnum;

  constructor(
    public icons: IconService,
    public links: RouterLinkFactory,
    private childrenService: ChildrenService,
    private spinner: NgxSpinnerService,
    private translocoService: TranslocoService,
    private toastr: ToastService,
    private modal: NgbModal,
    private popUp: PopUpComponent,
    private announcementService: AnnouncementsService,
    private userService: UserService,
    private invitationsService: InvitationsService,
    private handleErrorService: HandleErrorService,
    private dayOffsService: DayOffsService
  ) {}

  archiveChild(event: Event, id: string): void {
    event.stopPropagation();

    this.spinner.show();
    const archiveChild: Archive = {
      childUuids: [id],
    };

    this.childrenService
      .childrenBulkArchiveCreate(archiveChild)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('group/archiveSuccess'),
            this.translocoService.translate('group/archiveChildren')
          );
          this.refreshData.emit();
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('group/archiveError'),
            this.translocoService.translate('group/archiveChildren')
          );
        },
      });
  }

  navToEdit(): string[] {
    if (this.menuType === this.MenuTypeEnum.CHILD) {
      return this.links.editChild(this.data?.id || this.data?.childId);
    } else if (this.menuType === this.MenuTypeEnum.GROUP) {
      return this.links.editGroup(this.data?.uuid);
    } else if (this.menuType === this.MenuTypeEnum.CLOSE_RELATIVE) {
      return this.links.editCloseRelative(this.childId, this.data?.id);
    } else if (this.menuType === this.MenuTypeEnum.GRADEBOOK) {
      return this.links.editGradebook(this.data?.id);
    } else if (this.menuType === this.MenuTypeEnum.ANNOUNCEMENT) {
      return this.links.editAnnouncement(this.data?.id);
    } else if (this.menuType === this.MenuTypeEnum.USER) {
      return this.links.editUser(`${this.data?.teacher?.id}`);
    } else if (this.menuType === this.MenuTypeEnum.DAY_OFF) {
      return this.links.editHolidaysDates(this.data?.id);
    }
  }

  openModal(): void {
    const modalRef = this.modal.open(EditAbsenceModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.childAttendanceOverview = this.data;
    modalRef.componentInstance.dateFrom = this.dateFrom;
    modalRef.componentInstance.dateTo = this.dateTo;
  }

  async removeAnnouncement(e): Promise<void> {
    e.stopPropagation();

    const title = this.translocoService.translate('announcement/removeAnnouncement');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();
    this.announcementService
      .announcementsDestroy(this.data?.id)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('announcement/removeAnnouncementSuccess'),
            this.translocoService.translate('announcement/removeAnnouncement')
          );
          this.refreshData.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'announcement/removeAnnouncement', 'announcement/removeAnnouncementFailed');
        },
      });
  }

  async removeUser(e): Promise<void> {
    e.stopPropagation();

    const title = this.translocoService.translate('user/remove');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();
    this.userService
      .removeUser(this.data)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('user/userRemoveSuccessToast'),
            this.translocoService.translate('user/remove')
          );
          this.refreshData.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'user/remove', 'user/userRemoveErrorToast');
        },
      });
  }

  inviteParent(): void {
    this.spinner.show();

    const data: InviteSingleCloseRelative = {
      childUuid: this.childId,
      closeRelativeId: this.data?.id,
    };

    this.invitationsService
      .invitationsInviteSingleCloseRelativeCreate(data)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('child/inviteParentSuccess'),
            this.translocoService.translate('child/inviteParent')
          );
          this.refreshData.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'child/inviteParent', 'child/inviteParentError');
        },
      });
  }

  async removeAbsence(): Promise<void> {
    const title = this.translocoService.translate('child/removeAbsence');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();
    this.childrenService
      .childrenAbsenceDestroy(this.data?.id, this.childId)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('child/removeAbsenceSuccess'),
            this.translocoService.translate('child/removeAbsence')
          );
          this.refreshData.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'child/removeAbsence', 'child/removeAbsenceFailed');
        },
      });
  }

  async removeDayOff(): Promise<void> {
    const title = this.translocoService.translate('settings/removeDayOff');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();
    this.dayOffsService
      .dayOffsDestroy(this.data?.id)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('settings/removeDayOffSuccess'),
            this.translocoService.translate('settings/removeDayOff')
          );
          this.refreshData.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'settings/removeDayOff', 'settings/removeDayOffFailed');
        },
      });
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `present` - present * `absent` - absent * `present_morning` - present_morning * `present_afternoon` - present_afternoon * `unexcused` - unexcused
 */
export type OverviewStatusEnum = 'present' | 'absent' | 'present_morning' | 'present_afternoon' | 'unexcused';

export const OverviewStatusEnum = {
    Present: 'present' as OverviewStatusEnum,
    Absent: 'absent' as OverviewStatusEnum,
    PresentMorning: 'present_morning' as OverviewStatusEnum,
    PresentAfternoon: 'present_afternoon' as OverviewStatusEnum,
    Unexcused: 'unexcused' as OverviewStatusEnum
};
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChildProfileDetail } from '@isophi/mng-api';

import { environment } from '../../../../../../../src/environments/environment';

enum DetailedInfoTabsEnum {
  BASIC,
  EDUCATION,
  DIAGNOSTIC,
  DOCUMENTS,
}

@Component({
  selector: 'app-child-detailed-info',
  templateUrl: './child-detailed-info.component.html',
  styleUrls: ['./child-detailed-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildDetailedInfoComponent {
  @Input()
  child: ChildProfileDetail;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  tabs: typeof DetailedInfoTabsEnum = DetailedInfoTabsEnum;

  private _selectedTab: DetailedInfoTabsEnum = DetailedInfoTabsEnum.BASIC;

  get selectedTab(): DetailedInfoTabsEnum {
    return this._selectedTab;
  }

  isProduction = environment.production;

  /**
   * Activate action type and form by user select (MANUAL/by_code).
   *
   * @param actionType
   * @param event
   */
  selectTab(selectedTab: DetailedInfoTabsEnum, event: MouseEvent): void {
    event.preventDefault();
    this._selectedTab = selectedTab;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Kindergarten, KindergartenContract, Teacher, User } from '@isophi/core-legacy';
import { ListResponseContract, TeacherContract } from '@isophi/teachers-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserToAdd } from '../../modules/user/models/user-to-add.model';
import { toSnakecaseFun } from '../utils/object.utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedKindergarten: Kindergarten | null;

  usersCount: number;

  private teacherSubject$ = new BehaviorSubject<void>(null);

  constructor(private httpClient: HttpClient) {}

  /**
   * List of teachers (Users with role teacher)
   */
  get teachers$(): Observable<User[]> {
    const url = `${environment.teacherAPI}/teachers/`;

    return this.teacherSubject$.pipe(
      distinctUntilChanged(),
      switchMap(() => {
        return this.httpClient.get<ListResponseContract<TeacherContract>>(url).pipe(
          tap((response) => (this.usersCount = response.count)),
          map((response) =>
            response.results.map((teacherData) => {
              const teacherDataSnake = toSnakecaseFun(teacherData);
              const user = User.deserialize(teacherDataSnake.user);
              Teacher.deserialize(teacherDataSnake, user);
              return user;
            })
          )
        );
      }),
      shareReplay(1)
    );
  }

  reloadTeachersList(): void {
    this.teacherSubject$.next();
  }

  /**
   * Download logged kindergarten.
   *
   * Kindergarten is downloaded during login,
   * in application use directly loggedKindergarten field.
   */
  downloadLoggedKindergarten(): Promise<void> {
    const url = `${environment.teacherAPI}/general/logged-kindergarten/`;

    return this.httpClient
      .get<KindergartenContract>(url)
      .toPromise()
      .then((response) => {
        this.loggedKindergarten = Kindergarten.deserialize(toSnakecaseFun(response));
        return null;
      });
  }

  /**
   * Add new user
   * @param body - User details to be added
   * @returns Observable of the added user response
   */
  addUser(body: any): Observable<UserToAdd> {
    const url = `${environment.teacherAPI}/teachers/`;

    return this.httpClient.post<UserToAdd>(url, body);
  }

  /**
   * Edit existing user
   * @param body - User details to be edited
   * @returns Observable of the edited user response
   */
  editUser(body: any, id: number): Observable<UserToAdd> {
    const url = `${environment.teacherAPI}/teachers/${id}/`;

    return this.httpClient.put<UserToAdd>(url, body);
  }

  /**
   * Get user by id
   *
   * @param id
   */
  getUser(id: number): Observable<User> {
    const url = `${environment.teacherAPI}/teachers/${id}/`;

    return this.httpClient.get<any>(url).pipe(
      map((teacherData) => {
        const teacherDataSnake = toSnakecaseFun(teacherData);
        const user = User.deserialize(teacherDataSnake.user);
        Teacher.deserialize(teacherDataSnake, user);
        return user;
      })
    );
  }

  /**
   * Remove user.
   *
   * @param user
   */
  public removeUser(user: User): Observable<any> {
    const url = `${environment.teacherAPI}/teachers/${user.teacher.id}/`;

    return this.httpClient.delete(url);
  }
}

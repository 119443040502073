import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChildProfileDetail } from '@isophi/mng-api';

@Component({
  selector: 'app-child-info-diagnostic-tab',
  templateUrl: './child-info-diagnostic-tab.component.html',
  styleUrls: ['./child-info-diagnostic-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoDiagnosticTabComponent implements OnInit {
  @Input()
  child: ChildProfileDetail;

  constructor() {
    console.log('ChildInfoDiagnosticTabComponent');
  }

  ngOnInit(): void {
    console.log('ChildInfoDiagnosticTabComponent');
  }
}

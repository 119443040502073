/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `standard` - Standardní * `transfer_from_other_school` - Přestup z jiné školy * `additional_deferment` - Dodatečný odklad (ze ZŠ) * `return_from_abroad` - Návrat ze zahraničí
 */
export type AttendanceStartCodeEnum = 'standard' | 'transfer_from_other_school' | 'additional_deferment' | 'return_from_abroad';

export const AttendanceStartCodeEnum = {
    Standard: 'standard' as AttendanceStartCodeEnum,
    TransferFromOtherSchool: 'transfer_from_other_school' as AttendanceStartCodeEnum,
    AdditionalDeferment: 'additional_deferment' as AttendanceStartCodeEnum,
    ReturnFromAbroad: 'return_from_abroad' as AttendanceStartCodeEnum
};
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { CloseRelativeList, Gender785Enum, InvitationStateEnum } from '@isophi/mng-api';

import { MenuTypeEnum } from '../../../../../../core/enums/menu-type.enum';
import { HelperService } from '../../../../../../core/services/helper.service';

@Component({
  selector: 'app-child-close-relative-row',
  templateUrl: './child-close-relative-row.component.html',
  styleUrls: ['./child-close-relative-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildCloseRelativeRowComponent implements OnInit {
  @Input()
  closeRelative: CloseRelativeList;

  @Input()
  childId: string;

  @Output() refreshCloseRelative = new EventEmitter();

  showDetail: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender785Enum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuTypeEnum = MenuTypeEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  InvitationStateEnum = InvitationStateEnum;

  get gender(): Gender785Enum {
    if (!this.closeRelative) return;
    return this.helperService.getGender(this.closeRelative.relativeType);
  }

  get relativeType(): string {
    if (!this.closeRelative) return;
    return 'closeRelative/' + this.closeRelative.relativeType.toLowerCase().replace('_', '');
  }

  get status(): string {
    if (!this.closeRelative) return;
    return 'closeRelative/' + this.closeRelative.status.toLowerCase().replace('_', '');
  }

  constructor(protected iconsService: IconService, private helperService: HelperService) {}

  ngOnInit(): void {
    if (!this.closeRelative) return;
    this.showDetail = this.closeRelative.isPrimary;
  }

  showAll(): void {
    this.helperService.toggleSidebar$.next(this.closeRelative.id);
  }
}

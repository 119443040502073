import { Injectable } from '@angular/core';
import { ToastService } from '@isophi/core-ui';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(private translocoService: TranslocoService, private toastr: ToastService) {}

  handleFormError(e, title: string, text: string) {
    if (typeof e.error === 'object' && Object.keys(e.error).length) {
      for (const error in e.error) {
        e.error[error].forEach((errVal: string) => {
          this.toastr.error(errVal, this.translocoService.translate(title));
        });
      }
    } else {
      this.toastr.error(this.translocoService.translate(text), this.translocoService.translate(title));
    }
  }
}

<div class="close-relative" *transloco="let t">
  <div class="close-relative-header">
    <div class="title">{{ t('child/closePersons') }}</div>
    <div class="btns-right">
      <button class="btn btn-sm btn-primary" (click)="addClosePerson()">
        <i [class]="iconsService.plus"></i>
      </button>
    </div>
  </div>
  <div class="close-relative-content" *ngIf="closeRelatives$ | async as relatives">
    <app-child-close-relative-row
      *ngFor="let rel of relatives"
      [closeRelative]="rel"
      [childId]="child.childId"
      (refreshCloseRelative)="reloadCloseRelatives()"
    ></app-child-close-relative-row>
  </div>
</div>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IconService } from '@isophi/core-ui';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  messages = [
    {
      user: {
        fullName: 'Lucie Kratochvílová',
        imageUrl: '/assets/img/ucitelka-3.png',
      },
      message: 'Lorem ipsum dolor sit amet',
    },
    {
      user: {
        fullName: 'Lucie Kratochvílová',
        imageUrl: '/assets/img/ucitelka-3.png',
      },
      message: 'Lorem ipsum dolor sit amet',
    },
  ];

  notifications = [
    {
      user: {
        fullName: 'Antonín Novák',
        imageUrl: '/assets/img/ucitelka-3.png',
      },
      message: 'Dětský klub v ostružiní',
    },
  ];

  constructor(protected icons: IconService) {}

  ngOnInit(): void {
    console.log('NotificationComponent');
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { DistinctionEnum } from './distinctionEnum';
import { ExportContact } from './exportContact';
import { KindergartenOperationTypeEnum } from './kindergartenOperationTypeEnum';
import { NullEnum } from './nullEnum';

export interface PatchedKindergarten { 
    name?: string;
    /**
     * IZO
     */
    izo?: string;
    /**
     * RED_IZO = Resortní identifikátor právnické osoby vykonávající činnost školy
     */
    redIzo?: string;
    /**
     * IČO
     */
    lawId?: string;
    /**
     * Datová schránka
     */
    databox?: string;
    /**
     * Webová stránka
     */
    website?: string;
    /**
     * Název pracoviště
     */
    workplaceName?: string;
    /**
     * Číslo části školy
     */
    workplaceSchoolPartNumber?: string;
    /**
     * Ulice a číslo
     */
    workplaceStreetAddress?: string;
    /**
     * Obec
     */
    workplaceCity?: string;
    /**
     * Okres
     */
    workplaceDistrict?: string;
    /**
     * PSČ
     */
    workplacePostcode?: string;
    /**
     * Druh provozu  * `full_day` - Celodenní * `half_day` - Polodenní * `boarding` - Internátní
     */
    kindergartenOperationType?: KindergartenOperationTypeEnum | BlankEnum | NullEnum;
    /**
     * Rozlišení mateřské školy  * `without_distinction` - Mateřská škola bez rozlišení * `company_kindergarten` - Firemní mateřská škola * `forest_kindergarten` - Lesní mateřská škola * `kindergarten_at_an_institutional_education_facility` - Mateřská škola při zařízení pro ústavní výchovu nebo ochrannou výchovu
     */
    distinction?: DistinctionEnum | BlankEnum | NullEnum;
    /**
     * Průměrná doba mateřské školy v celodenním provozu
     */
    avgFullDayHours?: number;
    /**
     * Průměrná doba mateřské školy v polodenním provozu
     */
    avgHalfDayHours?: number;
    /**
     * Průměrná doba mateřské školy v internátním provozu
     */
    avgBoardingHours?: number;
    contactPerson?: ExportContact;
}
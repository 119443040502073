/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `standard` - Standardní předškolní vzdělávání * `education_under_34_10` - Vzdělávání podle § 34 odst. 10 ŠZ * `individual_education_under_34b` - Individuální vzdělávání podle § 34b ŠZ
 */
export type AttendanceFulfillmentMethodEnum = 'standard' | 'education_under_34_10' | 'individual_education_under_34b';

export const AttendanceFulfillmentMethodEnum = {
    Standard: 'standard' as AttendanceFulfillmentMethodEnum,
    EducationUnder3410: 'education_under_34_10' as AttendanceFulfillmentMethodEnum,
    IndividualEducationUnder34b: 'individual_education_under_34b' as AttendanceFulfillmentMethodEnum
};
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconService, ToastService } from '@isophi/core-ui';
import { ChildProfileDetail, ChildProfileDocumentRead, DocumentsService, PaginatedChildProfileDocumentReadList } from '@isophi/mng-api';
import { PopUpComponent } from '@isophi/teachers-shared';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, finalize, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-child-info-documents-tab',
  templateUrl: './child-info-documents-tab.component.html',
  styleUrls: ['./child-info-documents-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoDocumentsTabComponent implements OnInit {
  @Input()
  child: ChildProfileDetail;

  documents$: Observable<PaginatedChildProfileDocumentReadList>;

  page = 1;

  limit = 5;

  offset = 0;

  private documentsReload$ = new BehaviorSubject<void>(null);

  constructor(
    public iconsService: IconService,
    private documentsService: DocumentsService,
    private translocoService: TranslocoService,
    private popUp: PopUpComponent,
    private spinner: NgxSpinnerService,
    private toastr: ToastService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.documents$ = this.documentsReload$.pipe(
      switchMap(() => this.documentsService.documentsList(this.child.childId, this.limit, this.offset))
    );
  }

  reloadDocuments(): void {
    this.documentsReload$.next();
  }

  getIconMimeType(mimeType: string): string {
    return mimeType.split('/')[1];
  }

  onPageChange(page: number): void {
    this.offset = (page - 1) * this.limit;
    this.reloadDocuments();
  }

  addDocument(event): void {
    const file = event.target.files[0];

    if (file) {
      this.spinner.show();

      const reader = new FileReader();
      reader.readAsDataURL(file);

      this.documentsService
        .documentsCreateForm(file, file.name, this.child.childId)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe({
          next: (val) => {
            if (val) {
              this.toastr.success(
                this.translocoService.translate('child/uploadDocumentSuccess'),
                this.translocoService.translate('child/uploadDocument')
              );
              this.reloadDocuments();
            }
          },
          error: () => {
            this.toastr.error(
              this.translocoService.translate('child/uploadDocumentFailed'),
              this.translocoService.translate('child/uploadDocument')
            );
          },
        });
    }
  }

  async removeDocument(e: Event, document: ChildProfileDocumentRead): Promise<void> {
    e.stopPropagation();

    const title = this.translocoService.translate('child/removeDocument');
    const text = this.translocoService.translate('child/removeDocumentConfirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();
    this.documentsService
      .documentsDestroy(document.id)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('child/removeDocumentSuccess'),
            this.translocoService.translate('child/removeDocument')
          );
          this.reloadDocuments();
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('child/removeDocumentFailed'),
            this.translocoService.translate('child/removeDocument')
          );
        },
      });
  }
}

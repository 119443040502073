/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AbsenceEnum } from './absenceEnum';
import { AbsenceReasonEnum } from './absenceReasonEnum';
import { BlankEnum } from './blankEnum';
import { Gender785Enum } from './gender785Enum';
import { NullEnum } from './nullEnum';
import { Status4a6Enum } from './status4a6Enum';

export interface ChildAttendance { 
    childUuid: string;
    firstName: string;
    lastName: string;
    readonly arrivalTime: string;
    readonly departureTime: string;
    gender: Gender785Enum;
    status: Status4a6Enum;
    absence: AbsenceEnum | NullEnum;
    absenceReason?: AbsenceReasonEnum | BlankEnum | NullEnum;
    profileImage: string;
    thumbnail: string;
}
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `třída bez asistenta pedagoga` - Without Assistant * `třída s jedním asistentem pedagoga` - One Assistant * `třída s více asistenty nebo s asistentem a vychovatelem` - More Assistants
 */
export type TeacherAssistantEnum = 'třída bez asistenta pedagoga' | 'třída s jedním asistentem pedagoga' | 'třída s více asistenty nebo s asistentem a vychovatelem';

export const TeacherAssistantEnum = {
    BezAsistentaPedagoga: 'třída bez asistenta pedagoga' as TeacherAssistantEnum,
    SJednmAsistentemPedagoga: 'třída s jedním asistentem pedagoga' as TeacherAssistantEnum,
    SVceAsistentyNeboSAsistentemAVychovatelem: 'třída s více asistenty nebo s asistentem a vychovatelem' as TeacherAssistantEnum
};
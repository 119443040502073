<div class="children page-layout" *transloco="let t">
  <app-children-filter
    (filterUpdated)="setFilter($event)"
    [selectedChildren]="markedChildren"
    (refreshChildren)="reloadChildren()"
  ></app-children-filter>

  <ng-container *ngIf="children$ | withLoader | async as children">
    <div class="children-list page-layout-sub no-gutters">
      <div class="col table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="d-flex align-items-center">
                <div class="form-check mb-0">
                  <input
                    *ngIf="markedChildren.length && markedChildren.length !== children.results.length"
                    class="form-check-input indeterminate"
                    type="checkbox"
                    id="selectAllIndeterminate"
                    (click)="markAllChildren(children.results)"
                    [checked]="true"
                  />
                  <input
                    *ngIf="!markedChildren.length || areAllChildrenMarked(children.results)"
                    class="form-check-input"
                    type="checkbox"
                    id="selectAll"
                    [checked]="areAllChildrenMarked(children.results)"
                    (click)="markAllChildren(children.results)"
                    [disabled]="!children.results.length"
                  />
                </div>
                <span>{{ t('child/name') }}</span>
              </th>
              <th scope="col">{{ t('child/age') }}</th>
              <th scope="col" class="me-lg-4 m-0">{{ t('child/class') }} ({{ t('child/secondaryClass') }})</th>
              <th scope="col">{{ t('child/mainContact') }}</th>
              <th scope="col">{{ t('child/id') }}</th>
              <th scope="col">{{ t('child/action') }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="children.results.length > 0; else noResults">
              <app-child-row
                *ngFor="let child of children.results"
                [child]="child"
                (click)="openChild(child.id)"
                (childClick)="markChild($event)"
                [isChildMarked]="isChildMarked(child)"
                (refreshChildren)="reloadChildren()"
              ></app-child-row>
            </ng-container>

            <ng-template #noResults>
              <tr class="no-results" height="76px">
                <td class="d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" disabled="true" />
                  <img src="assets/img/iconsDefault/boy.svg" alt="boy" class="me-4" width="44px" />
                  {{ t('child/noChildFound') }}
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end p-2" *ngIf="children.results.length > 0">
      <ngb-pagination
        (pageChange)="onPageChange($event)"
        [collectionSize]="children.count"
        [(page)]="page"
        [pageSize]="limit"
        [maxSize]="7"
      ></ngb-pagination>
    </div>
  </ng-container>
</div>

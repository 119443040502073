import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { AddressTypeEnum, ChildProfileDetail, CloseRelativeDetail, CloseRelativesService, Gender785Enum } from '@isophi/mng-api';
import { Observable, shareReplay, tap } from 'rxjs';

import { MenuTypeEnum } from '../../../../../core/enums/menu-type.enum';
import { HelperService } from '../../../../../core/services/helper.service';

@Component({
  selector: 'app-close-relative-sidebar',
  templateUrl: './close-relative-sidebar.component.html',
  styleUrls: ['./close-relative-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class CloseRelativeSidebarComponent {
  @Input() child: ChildProfileDetail;

  @Output() isOpen = new EventEmitter<boolean>();

  sidebarState = 'out';

  closeRelative$: Observable<CloseRelativeDetail>;

  private _closeRelative: CloseRelativeDetail;

  get closeRelative(): CloseRelativeDetail {
    return this._closeRelative;
  }

  get closeRelativeAddress(): string {
    if (!this.closeRelative) return '';

    const permanentAddress = this._closeRelative.addresses.find((address) => address.addressType === AddressTypeEnum.Permanent);
    if (!permanentAddress) return '-';

    if (!permanentAddress.streetAndHouseNumber && !permanentAddress.postalCode && !permanentAddress.city) {
      return '-';
    } else {
      return `${permanentAddress.streetAndHouseNumber} ${permanentAddress.postalCode} ${permanentAddress.city}`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender785Enum;

  get gender(): Gender785Enum {
    if (!this.closeRelative) return;
    return this.helperService.getGender(this.closeRelative.relativeType);
  }

  get relativeType(): string {
    if (!this.closeRelative) return;
    return 'closeRelative/' + this.closeRelative.relativeType.toLowerCase().replace('_', '');
  }

  get status(): string {
    if (!this.closeRelative) return;
    return 'closeRelative/' + this.closeRelative.status.toLowerCase().replace('_', '');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuTypeEnum = MenuTypeEnum;

  constructor(
    private service: CloseRelativesService,
    private helperService: HelperService,
    protected iconsService: IconService,
    private cd: ChangeDetectorRef
  ) {}

  toggleSidebar(closeRelativeId: string): void {
    if (!closeRelativeId) {
      this.sidebarState = this.sidebarState === 'out' ? 'in' : 'out';
      this._emitSidebarState();
      this.cd.markForCheck();
      return;
    }

    this.closeRelative$ = this.service.closeRelativesRetrieve(closeRelativeId).pipe(
      tap((closeRelative) => {
        this._closeRelative = closeRelative;
        this.sidebarState = this.sidebarState === 'out' ? 'in' : 'out';
        this._emitSidebarState();
        this.cd.markForCheck();
      }),
      shareReplay(1)
    );
    this.cd.markForCheck();
  }

  private _emitSidebarState(): void {
    this.isOpen.emit(this.sidebarState === 'in');
  }
}

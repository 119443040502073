export enum MenuTypeEnum {
  GROUP = 'group',
  CHILD = 'child',
  CLOSE_RELATIVE = 'closeRelative',
  GRADEBOOK = 'gradebook',
  ANNOUNCEMENT = 'announcement',
  USER = 'user',
  ATTENDANCE_OVERVIEW = 'attendance_overview',
  ABSENCE = 'absence',
  DAY_OFF = 'day_off',
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `full_day` - Celodenní * `half_day` - Polodenní * `boarding` - Internátní
 */
export type KindergartenOperationTypeEnum = 'full_day' | 'half_day' | 'boarding';

export const KindergartenOperationTypeEnum = {
    FullDay: 'full_day' as KindergartenOperationTypeEnum,
    HalfDay: 'half_day' as KindergartenOperationTypeEnum,
    Boarding: 'boarding' as KindergartenOperationTypeEnum
};
import { Pipe, PipeTransform } from '@angular/core';
import { AbsenceReasonEnum, BlankEnum, NullEnum } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translateAbsenceReason',
})
export class TranslateAbsenceReasonPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(value: AbsenceReasonEnum | BlankEnum | NullEnum): string {
    switch (value) {
      case AbsenceReasonEnum.Doctor:
        return this.transloco.translate('evidence/doctor');
      case AbsenceReasonEnum.FamilyReason:
        return this.transloco.translate('evidence/family');
      case AbsenceReasonEnum.Sickness:
        return this.transloco.translate('evidence/illness');
      case AbsenceReasonEnum.Vacation:
        return this.transloco.translate('evidence/holiday');
      case AbsenceReasonEnum.Other:
        return this.transloco.translate('evidence/other');
      default:
        return 'Missing translation!';
    }
  }
}

<div class="wrap">
  <div class="page">
    <div class="page-main d-flex">
      <app-header *ngIf="showHeader$ | async"></app-header>

      <app-sidebar appHoverEffectSidebar></app-sidebar>

      <div [ngClass]="{ 'mt-0': (showHeader$ | async) === false }" class="app-content main-content d-flex flex-1">
        <div class="side-app main-container d-flex flex-1">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';

import { RouterLinkFactory } from '../router/router-link.factory';

@Injectable()
export class AuthGuard implements CanActivate {
  public constructor(private router: Router, private authService: AuthService, private links: RouterLinkFactory) {}

  public async canActivate(): Promise<boolean> {
    if (!this.authService.loggedUser) {
      this.router.navigate(this.links.login());
      return false;
    }

    return true;
  }
}

<div class="attendance-today-card border-r-16" *transloco="let t">
  <div class="top-section">
    <div class="d-flex align-items-center justify-content-between">
      <h4>{{ t('evidence/attendanceToday') }}</h4>
      <button class="btn btn-primary btn-icon" [routerLink]="links.attendanceToday()"><i [class]="icons.chevronRight"></i></button>
    </div>
    <p>
      <span class="text-uppercase">{{ group?.name }} </span
      ><span *ngIf="group?.childCount"
        >- {{ group?.childCount }} {{ group?.childCount > 1 ? t('shared/children') : t('shared/child') }}</span
      >
    </p>
  </div>

  <div class="bottom-section">
    <div>
      <p>
        <span>{{ t('evidence/cameIn') }}</span
        ><br />
        <span>{{ childAttendanceCount?.presentCount }}</span>
      </p>

      <img src="assets/img/dashboard/user-present.svg" alt="User present" />
    </div>
    <div>
      <p>
        <span>{{ t('evidence/absent') }}</span
        ><br />
        <span>{{ childAttendanceCount?.absentCount }}</span>
      </p>

      <img src="assets/img/dashboard/user-absent.svg" alt="User absent" />
    </div>
    <div>
      <p>
        <span>{{ t('evidence/noEvidence') }}</span
        ><br />
        <span>{{ childAttendanceCount?.noEvidenceCount }}</span>
      </p>

      <img src="assets/img/dashboard/user-no-evidence.svg" alt="User no-evidence" />
    </div>
  </div>
</div>

<ng-container *transloco="let t">
  <div class="d-flex app-header header">
    <div class="d-flex align-items-center">
      <div class="app-sidebar__toggle" isophiSidemenuToggle>
        <a class="open-toggle" href="javascript:;">
          <i [class]="iconService.menu"></i>
        </a>
        <a class="close-toggle" href="javascript:;">
          <i [class]="iconService.menu"></i>
        </a>
      </div>

      <div *ngIf="showBackButton" class="app-back" (click)="back()">
        <a>
          <i [class]="iconService.chevronLeft"></i>
        </a>
      </div>

      <h1 class="fw-bold mb-0">
        {{ title$ | async }}
        <span *ngIf="smallTextTitle$ | async as smallTextTitle">({{ smallTextTitle }})</span>
      </h1>
      <ng-container *ngIf="showSubMenu$ | async as showSubMenu">
        <app-context-menu *ngIf="showSubMenu.show" [menuType]="showSubMenu.menuType" [data]="data" class="ms-4"></app-context-menu>
      </ng-container>
    </div>
    <div class="right-side">
      <app-notification *ngIf="!isProduction"></app-notification>
      <app-logged-user></app-logged-user>
    </div>
  </div>

  <div class="mobile-header d-flex app-header header">
    <div class="top-section">
      <div class="app-sidebar__toggle" isophiSidemenuToggle>
        <a class="open-toggle" href="javascript:;">
          <i [class]="iconService.menu"></i>
        </a>
        <a class="close-toggle" href="javascript:;">
          <i [class]="iconService.menu"></i>
        </a>
      </div>

      <div class="right-side">
        <app-notification *ngIf="!isProduction"></app-notification>
        <app-logged-user></app-logged-user>
      </div>
    </div>

    <div class="down-section">
      <div *ngIf="showBackButton" class="app-back" (click)="back()">
        <a>
          <i [class]="iconService.chevronLeft"></i>
        </a>
      </div>

      <h1 class="fw-bold mb-0">
        {{ title$ | async }}
        <span *ngIf="smallTextTitle$ | async as smallTextTitle">({{ smallTextTitle }})</span>
      </h1>
      <ng-container *ngIf="showSubMenu$ | async as showSubMenu">
        <app-context-menu *ngIf="showSubMenu.show" [menuType]="showSubMenu.menuType" [data]="data" class="ms-auto"></app-context-menu>
      </ng-container>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LocalStorage } from '@isophi/core-legacy';
import { IconService, ToastService } from '@isophi/core-ui';
import {
  AbsenceEnum,
  AbsenceReasonEnum,
  AttendanceService,
  ChildAttendanceOverview,
  ClassCreateOrUpdateAttendance,
  Status4a6Enum,
} from '@isophi/mng-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

import { LocalStorageConstService } from '../../../../shared/services/local-storage-const.service';
import { EvidenceService } from '../../services/evidence.service';
import { timeList } from './data';

enum AttendanceType {
  PRESENT_TYPE = 'present_type',
  ABSENT_TYPE = 'absent_type',
}

@Component({
  selector: 'app-edit-absence-modal',
  templateUrl: './edit-absence-modal.component.html',
  styleUrls: ['./edit-absence-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAbsenceModalComponent implements OnInit {
  childAttendanceOverview: ChildAttendanceOverview;

  dateFrom: string;

  dateTo: string;

  children: ChildAttendanceOverview[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AbsenceEnum = AbsenceEnum;

  absence: AbsenceEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AttendanceType = AttendanceType;

  attendanceType = AttendanceType.PRESENT_TYPE;

  timeList = timeList;

  arrival = '08:00';

  departure = '16:00';

  groupUuid: string;

  constructor(
    public activeModal: NgbActiveModal,
    public icons: IconService,
    private toastr: ToastService,
    private translocoService: TranslocoService,
    private attendanceService: AttendanceService,
    private spinner: NgxSpinnerService,
    private lSConstService: LocalStorageConstService,
    private evidenceService: EvidenceService
  ) {
    const storage = new LocalStorage();
    if (storage.hasItem(this.lSConstService.childrenFilter)) {
      const storageData = storage.getItem(this.lSConstService.childrenFilter);
      if (storageData && storageData !== 'undefined') {
        const storageDataValues = JSON.parse(storageData);
        this.groupUuid = storageDataValues.group;
      }
    }
  }

  ngOnInit(): void {
    if (this.childAttendanceOverview) {
      this.arrival = this.childAttendanceOverview.attendance[0].arrivalTime || this.arrival;
      this.departure = this.childAttendanceOverview.attendance[0].departureTime || this.departure;
    } else if (this.children?.length === 1) {
      this.arrival = this.children[0].attendance[0].arrivalTime || this.arrival;
      this.departure = this.children[0].attendance[0].departureTime || this.departure;
    }
  }

  selectArrivalTime(time): void {
    this.arrival = time;
  }

  selectDepartureTime(time): void {
    this.departure = time;
  }

  onChangeAttendanceType(): void {
    if (this.attendanceType === this.AttendanceType.PRESENT_TYPE) {
      this.absence = null;
    } else {
      this.arrival = null;
      this.departure = null;
    }
  }

  submitButton(): void {
    this.spinner.show();
    const data: ClassCreateOrUpdateAttendance = {
      classUuid: this.groupUuid,
      childUuids: this.childAttendanceOverview ? [this.childAttendanceOverview.childUuid] : this.children.map((child) => child.childUuid),
      status: this.attendanceType === this.AttendanceType.PRESENT_TYPE ? Status4a6Enum.Present : Status4a6Enum.Absent,
      absenceReason: AbsenceReasonEnum.Other,
      ...(this.absence && { absence: this.absence }),
      ...(this.arrival && { arrivalTime: this.arrival }),
      ...(this.departure && { departureTime: this.departure }),
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };

    this.attendanceService
      .attendanceSetCurrentClassAttendanceCreate(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
          this.activeModal.close();
        })
      )
      .subscribe({
        next: () => {
          const successText =
            this.attendanceType === this.AttendanceType.PRESENT_TYPE ? 'evidence/presentSuccess' : 'evidence/absentSuccessToast';
          this.toastr.success(this.translocoService.translate(successText));
          this.evidenceService.reloadChildren();
        },
        error: (e) => {
          if (e.error?.arrival_time) {
            e.error.arrival_time.forEach((time) => {
              this.toastr.error(time);
            });
          } else if (e.error?.departure_time) {
            e.error.departure_time.forEach((time) => {
              this.toastr.error(time);
            });
          } else {
            this.toastr.error(this.translocoService.translate('evidence/actionError'));
          }
        },
      });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IStorage, LocalStorage } from '@isophi/core-legacy';
import { IconService } from '@isophi/core-ui';

import { environment } from '../../../../../../src/environments/environment';
import { ApiConfigurationService } from '../../../../../app/api/api-configuration.service';
import { RouterLinkFactory } from '../../../../core/router/router-link.factory';

@Component({
  selector: 'app-logged-user',
  templateUrl: './logged-user.component.html',
  styleUrls: ['./logged-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedUserComponent {
  user = {
    imageUrl: '/assets/img/ucitelka-3.png',
    fullName: 'Lucie Kratochvílová',
    email: 'kratochvilova@isophi.cz',
    role: 'Pedagog',
  };

  isProduction = environment.production;

  private storage: IStorage;

  constructor(
    protected icons: IconService,
    public links: RouterLinkFactory,
    private router: Router,
    private apiConfigurationService: ApiConfigurationService,
    private authService: AuthService
  ) {
    this.storage = new LocalStorage();
    this.user.fullName = this.authService.loggedUser?.fullName || this.authService.loggedUser?.email;
    this.user.email = this.authService.loggedUser?.email;
  }

  logout(): void {
    this.apiConfigurationService.removeAuthTokenForSwagger();
    this.authService.logoutUser(this.storage);
    this.storage.clear();
    this.router.navigate(this.links.login());
  }
}

<button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close fw-normal" tabindex="-1">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
  <h4>{{ title }}</h4>
  <p [innerHTML]="text"></p>
  <div class="d-flex align-items-center justify-content-center">
    <img *ngIf="image; else noImage" [src]="image" alt="Image" />
  </div>
  <ng-template #noImage>
    <span class="no-image"></span>
  </ng-template>
</div>

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `Český` - Czech * `Český a anglický` - Czech And English * `Český a francouzský` - Czech And French * `Český a španělský` - Czech And Spanish * `Český a italský` - Czech And Italian * `Český a německý` - Czech And German * `Český a ruský` - Czech And Russian * `Český s výukou vybraných předmětů v cizím jazyce` - Czech With Selected Courses In Foreign Language * `Český a jiný` - Czech And Other * `Slovenský` - Slovak * `Slovenský a anglický` - Slovak And English * `Slovenský a francouzský` - Slovak And French * `Slovenský a španělský` - Slovak And Spanish * `Slovenský a italský` - Slovak And Italian * `Slovenský a německý` - Slovak And German * `Slovenský a ruský` - Slovak And Russian * `Slovenský a jiný` - Slovak And Other * `Polský` - Polish * `Polský a anglický` - Polish And English * `Polský a francouzský` - Polish And French * `Polský a španělský` - Polish And Spanish * `Polský a italský` - Polish And Italian * `Polský a německý` - Polish And German * `Polský a ruský` - Polish And Russian * `Polský s výukou vybraných předmětů v cizím jazyce` - Polish With Selected Courses In Foreign Language * `Polský a jiný` - Polish And Other * `Jiný` - Other
 */
export type TeachingLanguageEnum = 'Český' | 'Český a anglický' | 'Český a francouzský' | 'Český a španělský' | 'Český a italský' | 'Český a německý' | 'Český a ruský' | 'Český s výukou vybraných předmětů v cizím jazyce' | 'Český a jiný' | 'Slovenský' | 'Slovenský a anglický' | 'Slovenský a francouzský' | 'Slovenský a španělský' | 'Slovenský a italský' | 'Slovenský a německý' | 'Slovenský a ruský' | 'Slovenský a jiný' | 'Polský' | 'Polský a anglický' | 'Polský a francouzský' | 'Polský a španělský' | 'Polský a italský' | 'Polský a německý' | 'Polský a ruský' | 'Polský s výukou vybraných předmětů v cizím jazyce' | 'Polský a jiný' | 'Jiný';

export const TeachingLanguageEnum = {
    Esk: 'Český' as TeachingLanguageEnum,
    EskAAnglick: 'Český a anglický' as TeachingLanguageEnum,
    EskAFrancouzsk: 'Český a francouzský' as TeachingLanguageEnum,
    EskAPanlsk: 'Český a španělský' as TeachingLanguageEnum,
    EskAItalsk: 'Český a italský' as TeachingLanguageEnum,
    EskANmeck: 'Český a německý' as TeachingLanguageEnum,
    EskARusk: 'Český a ruský' as TeachingLanguageEnum,
    EskSVukouVybranchPedmtVCizmJazyce: 'Český s výukou vybraných předmětů v cizím jazyce' as TeachingLanguageEnum,
    EskAJin: 'Český a jiný' as TeachingLanguageEnum,
    Slovensk: 'Slovenský' as TeachingLanguageEnum,
    SlovenskAAnglick: 'Slovenský a anglický' as TeachingLanguageEnum,
    SlovenskAFrancouzsk: 'Slovenský a francouzský' as TeachingLanguageEnum,
    SlovenskAPanlsk: 'Slovenský a španělský' as TeachingLanguageEnum,
    SlovenskAItalsk: 'Slovenský a italský' as TeachingLanguageEnum,
    SlovenskANmeck: 'Slovenský a německý' as TeachingLanguageEnum,
    SlovenskARusk: 'Slovenský a ruský' as TeachingLanguageEnum,
    SlovenskAJin: 'Slovenský a jiný' as TeachingLanguageEnum,
    Polsk: 'Polský' as TeachingLanguageEnum,
    PolskAAnglick: 'Polský a anglický' as TeachingLanguageEnum,
    PolskAFrancouzsk: 'Polský a francouzský' as TeachingLanguageEnum,
    PolskAPanlsk: 'Polský a španělský' as TeachingLanguageEnum,
    PolskAItalsk: 'Polský a italský' as TeachingLanguageEnum,
    PolskANmeck: 'Polský a německý' as TeachingLanguageEnum,
    PolskARusk: 'Polský a ruský' as TeachingLanguageEnum,
    PolskSVukouVybranchPedmtVCizmJazyce: 'Polský s výukou vybraných předmětů v cizím jazyce' as TeachingLanguageEnum,
    PolskAJin: 'Polský a jiný' as TeachingLanguageEnum,
    Jin: 'Jiný' as TeachingLanguageEnum
};
import { Component, Input } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { ChildAttendanceCount, ChildGroupList } from '@isophi/mng-api';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';

@Component({
  selector: 'app-attendance-today-card',
  templateUrl: './attendance-today-card.component.html',
  styleUrls: ['./attendance-today-card.component.scss'],
})
export class AttendanceTodayCardComponent {
  @Input() group: ChildGroupList;

  @Input() childAttendanceCount: ChildAttendanceCount;

  constructor(public icons: IconService, public links: RouterLinkFactory) {}
}

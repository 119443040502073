import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnnouncementsService } from './api/announcements.service';
import { AttendanceService } from './api/attendance.service';
import { ChildrenService } from './api/children.service';
import { ChildrenGroupService } from './api/childrenGroup.service';
import { ClassesService } from './api/classes.service';
import { CloseRelativesService } from './api/closeRelatives.service';
import { DayOffsService } from './api/dayOffs.service';
import { DocumentsService } from './api/documents.service';
import { ExportXmlService } from './api/exportXml.service';
import { GradeBookService } from './api/gradeBook.service';
import { InvitationsService } from './api/invitations.service';
import { KindergartenService } from './api/kindergarten.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AnnouncementsService,
    AttendanceService,
    ChildrenService,
    ChildrenGroupService,
    ClassesService,
    CloseRelativesService,
    DayOffsService,
    DocumentsService,
    ExportXmlService,
    GradeBookService,
    InvitationsService,
    KindergartenService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

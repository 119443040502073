import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { AnnouncementRead, AnnouncementsService } from '@isophi/mng-api';
import { debounceTime, filter, fromEvent, Subscription, tap } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';

const SCROLL_CONST = 50;

@Component({
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  announcementList: AnnouncementRead[] = [];

  limit = 5;

  offset = 0;

  isLoading = false;

  private noMoreAnnouncement = false;

  private _subs: Subscription[] = [];

  constructor(
    public icons: IconService,
    public links: RouterLinkFactory,
    private announcementsService: AnnouncementsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadAnnouncement();
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer?.nativeElement) {
      this._subs.push(
        fromEvent(this.scrollContainer?.nativeElement, 'scroll')
          .pipe(
            debounceTime(200),
            filter(() => {
              const container = this.scrollContainer.nativeElement;
              return container.scrollTop + container.clientHeight >= container.scrollHeight - SCROLL_CONST;
            }),
            tap(() => {
              if (!this.isLoading && !this.noMoreAnnouncement) {
                this.loadAnnouncement();
              }
            })
          )
          .subscribe()
      );
    }
  }

  loadAnnouncement(): void {
    if (this.isLoading) return;

    this.isLoading = true;
    this.announcementsService.announcementsList('', this.limit, this.offset).subscribe((data) => {
      this.announcementList = [...this.announcementList, ...data.results];
      if (data.results.length < this.limit) {
        this.noMoreAnnouncement = true;
      }
      this.offset += this.limit;
      this.isLoading = false;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BirthNumberService {
  convertBirthNumber(birthNumber: string): { day: number; month: number; year: number } {
    birthNumber = birthNumber.replace('/', '');

    let year = parseInt(birthNumber.substr(0, 2), 10);
    let month = parseInt(birthNumber.substr(2, 2), 10);
    const day = parseInt(birthNumber.substr(4, 2), 10);

    if (month > 50) {
      month -= 50;
    } else if (month > 20 && year >= 4) {
      month -= 20;
    }

    if (year >= 54) {
      year += 1900;
    } else {
      year += 2000;
    }

    return { day: day, month: month, year: year };
  }
}

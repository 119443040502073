import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChildProfileDetail, ChildrenService } from '@isophi/mng-api';
import { Observable, shareReplay, Subscription, tap } from 'rxjs';

import { MenuTypeEnum } from '../../../../core/enums/menu-type.enum';
import { HelperService } from '../../../../core/services/helper.service';
import { CloseRelativeSidebarComponent } from './close-relative-sidebar/close-relative-sidebar.component';

@Component({
  selector: 'app-child-detail',
  templateUrl: './child-detail.component.html',
  styleUrls: ['./child-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildDetailComponent implements OnInit, OnDestroy {
  @ViewChild('sidebar')
  sidebar: CloseRelativeSidebarComponent;

  child$: Observable<ChildProfileDetail>;

  sidebarOpened = false;

  private _subs: Subscription[] = [];

  constructor(private service: ChildrenService, private helperService: HelperService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this._subs.push(
      this.route.paramMap.subscribe((params) => {
        const id = params.get('id');
        this.child$ = this.service.childrenRetrieve(id).pipe(
          tap((child) => {
            this.helperService.setTitle(null, child.firstName + ' ' + child.lastName);
            this.helperService.showSubMenu(true, MenuTypeEnum.CHILD, child);
          }),
          shareReplay(1)
        );
      }),
      this.helperService.toggleSidebar$.subscribe((closeRelativeId: string) => {
        this.sidebar.toggleSidebar(closeRelativeId);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
    this.helperService.showSubMenu(false);
  }
}

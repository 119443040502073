import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformEnumToReadableString',
})
export class TransformEnumToReadableStringPipe implements PipeTransform {
  transform(value: any, data: any[]): string {
    return data.find((item) => item.value === value)?.text || '';
  }
}

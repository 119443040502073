<div class="attendance" *transloco="let t">
  <div class="attendance-header">
    <div class="title">{{ t('child/attendance') }}</div>
    <div class="btns-right">
      <button class="btn btn-sm btn-outline-primary" (click)="history()">
        {{ t('child/history') }}
      </button>
      <button class="btn btn-sm btn-primary" (click)="addAttendance()">
        <i [class]="iconsService.plus"></i>
      </button>
    </div>
  </div>
  <div class="attendance-content">
    <app-child-attendance-row
      *ngFor="let absence of absences$ | async"
      [absence]="absence"
      [childId]="child.childId"
      (refreshAbsence)="reloadAbsences()"
    ></app-child-attendance-row>
  </div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from '@isophi/core-ui';
import { ChildProfileDetail, CloseRelativeList, CloseRelativesService } from '@isophi/mng-api';
import { BehaviorSubject, finalize, map, Observable, switchMap } from 'rxjs';

import { RouterLinkFactory } from '../../../../../core/router/router-link.factory';

@Component({
  selector: 'app-child-close-relative',
  templateUrl: './child-close-relative.component.html',
  styleUrls: ['./child-close-relative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildCloseRelativeComponent implements OnInit {
  @Input()
  child: ChildProfileDetail;

  @HostBinding('class.loaded') get classLoaded() {
    return this._loaded;
  }

  closeRelatives$: Observable<Array<CloseRelativeList>>;

  private _loaded = false;

  private reloadCloseRelatives$ = new BehaviorSubject<void>(null);

  constructor(
    private service: CloseRelativesService,
    protected iconsService: IconService,
    private routerLinkFactory: RouterLinkFactory,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.child) return;
    this.closeRelatives$ = this.reloadCloseRelatives$.pipe(
      switchMap(() =>
        this.service.closeRelativesList(this.child.childId).pipe(
          map((list) =>
            list.results.sort((a, b) => {
              return b.isPrimary === a.isPrimary ? 0 : b.isPrimary ? 1 : -1;
            })
          ),
          finalize(() => (this._loaded = true))
        )
      )
    );
  }

  addClosePerson(): void {
    this.router.navigate(this.routerLinkFactory.createCloseRelative(this.child.childId));
  }

  reloadCloseRelatives(): void {
    this.reloadCloseRelatives$.next();
  }
}

<ng-container *transloco="let t">
  <div class="modal-header">
    <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close fw-normal" tabindex="-1">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>{{ t('evidence/excuse') }}</h2>
    <div class="date-container">
      <div>
        <label for="dateFrom">{{ t('shared/date') }} {{ t('shared/from') }}:</label>
        <div class="input-group">
          <input
            id="dateFrom"
            class="form-control"
            [(ngModel)]="dateFrom"
            ngbDatepicker
            [readOnly]="true"
            #dp="ngbDatepicker"
            (ngModelChange)="selectedData()"
            (click)="dp.toggle()"
          />
          <div class="input-group-append">
            <button class="btn" (click)="dp.toggle()" type="button">
              <img src="assets/img/calendar.svg" alt="calendar" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <label for="dateTo">{{ t('shared/date') }} {{ t('shared/to') }}:</label>
        <div class="input-group">
          <input
            id="dateTo"
            class="form-control"
            [(ngModel)]="dateTo"
            ngbDatepicker
            [readOnly]="true"
            #dp1="ngbDatepicker"
            (ngModelChange)="selectedData()"
            (click)="dp1.toggle()"
          />
          <div class="input-group-append">
            <button class="btn" (click)="dp1.toggle()" type="button">
              <img src="assets/img/calendar.svg" alt="calendar" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-group absence" role="group" aria-label="absence radio toggle button group">
      <input
        type="radio"
        class="btn-check"
        name="absence"
        id="morning"
        [value]="AbsenceEnum.AbsentMorning"
        [(ngModel)]="absence"
        [disabled]="!equalDates"
      />
      <label class="btn btn-outline-primary border-end-0" for="morning">
        <img src="assets/img/attendance/{{ AbsenceEnum.AbsentMorning }}.svg" alt="absence morning" />
        {{ t('evidence/morning') }}
      </label>

      <input
        type="radio"
        class="btn-check"
        name="absence"
        id="afternoon"
        [value]="AbsenceEnum.AbsentAfternoon"
        [(ngModel)]="absence"
        [disabled]="!equalDates"
      />
      <label class="btn btn-outline-primary" for="afternoon">
        <img src="assets/img/attendance/{{ AbsenceEnum.AbsentAfternoon }}.svg" alt="absence after" />
        {{ t('evidence/afternoon') }}
      </label>

      <input type="radio" class="btn-check" name="absence" id="all-day" [value]="AbsenceEnum.Absent" [(ngModel)]="absence" />
      <label class="btn btn-outline-primary border-start-0" for="all-day">
        <img src="assets/img/attendance/{{ AbsenceEnum.Absent }}.svg" alt="absence" />
        {{ t('evidence/allDay') }}
      </label>
    </div>

    <div class="btn-group absence-reason" role="group" aria-label="absence-reason radio toggle button group">
      <input
        type="radio"
        class="btn-check"
        name="absenceReason"
        id="doctor"
        [value]="AbsenceReasonEnum.Doctor"
        [(ngModel)]="absenceReason"
      />
      <label class="btn btn-outline-primary" for="doctor">
        <div>
          <img src="assets/img/attendance/absenceReason/doctor.svg" alt="doctor" />
        </div>
        <span>{{ t('evidence/doctor') }}</span>
      </label>

      <input
        type="radio"
        class="btn-check"
        name="absenceReason"
        id="illness"
        [value]="AbsenceReasonEnum.Sickness"
        [(ngModel)]="absenceReason"
      />
      <label class="btn btn-outline-primary" for="illness">
        <div>
          <img src="assets/img/attendance/absenceReason/illness.svg" alt="illness" />
        </div>
        {{ t('evidence/illness') }}
      </label>

      <input
        type="radio"
        class="btn-check"
        name="absenceReason"
        id="holiday"
        [value]="AbsenceReasonEnum.Vacation"
        [(ngModel)]="absenceReason"
      />
      <label class="btn btn-outline-primary" for="holiday">
        <div>
          <img src="assets/img/attendance/absenceReason/holiday.svg" alt="holiday" />
        </div>
        {{ t('evidence/holiday') }}
      </label>

      <input
        type="radio"
        class="btn-check"
        name="absenceReason"
        id="family"
        [value]="AbsenceReasonEnum.FamilyReason"
        [(ngModel)]="absenceReason"
      />
      <label class="btn btn-outline-primary" for="family">
        <div>
          <img src="assets/img/attendance/absenceReason/family.svg" alt="family" />
        </div>
        {{ t('evidence/family') }}
      </label>

      <input type="radio" class="btn-check" name="absenceReason" id="other" [value]="AbsenceReasonEnum.Other" [(ngModel)]="absenceReason" />
      <label class="btn btn-outline-primary" for="other">
        <div>
          <img src="assets/img/attendance/absenceReason/other.svg" alt="other" />
        </div>
        {{ t('evidence/other') }}
      </label>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-center">
    <button (click)="activeModal.close(null)" class="btn btn-lg btn-outline-primary me-4" tabindex="-1">{{ t('child/cancel') }}</button>
    <button (click)="submitButton()" class="btn btn-lg btn-primary" [disabled]="!absence || !absenceReason">
      {{ t('evidence/excuse') }}
    </button>
  </div>
</ng-container>

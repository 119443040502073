import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@isophi/core-ui';
import { ChildrenService, CloseRelativeCreate, CloseRelativesService } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../../core/router/router-link.factory';
import { HelperService } from '../../../../../core/services/helper.service';
import { CloseRelativeFormComponent } from '../../../components/close-relative-form/close-relative-form.component';

interface CloseRelativeCreateFormData {
  data: CloseRelativeCreate;
  navigateBack: boolean;
}

@Component({
  selector: 'app-create-close-relative',
  templateUrl: './create-close-relative.component.html',
  styleUrls: ['./create-close-relative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChildCloseRelativeComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CloseRelativeFormComponent) closeRelativeComponent: CloseRelativeFormComponent;

  private _childId: string;

  private _subs: Subscription[] = [];

  constructor(
    private service: CloseRelativesService,
    private childrenService: ChildrenService,
    private helperService: HelperService,
    public links: RouterLinkFactory,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastService,
    private translocoService: TranslocoService
  ) {
    this.helperService.setTitle(location.pathname);
  }

  ngAfterViewInit(): void {
    this._subs.push(
      this.route.params.subscribe((params) => {
        this._childId = params.id;

        this._subs.push(
          this.childrenService.childrenRetrieve(this._childId).subscribe((child) => {
            this.helperService.smallTextTitle$.next(child.firstName + ' ' + child.lastName);
          })
        );
      })
    );
  }

  createCloseRelative(closeRelativeForm: CloseRelativeCreateFormData): void {
    this.spinner.show();

    this.service
      .closeRelativesCreate(closeRelativeForm.data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('closeRelative/addCloseRelativeSuccess'),
            this.translocoService.translate('closeRelative/add')
          );

          closeRelativeForm.navigateBack
            ? this.closeRelativeComponent.navigateBack()
            : this.closeRelativeComponent.resetCloseRelativeForm();
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('closeRelative/addCloseRelativeFailed'),
            this.translocoService.translate('closeRelative/add')
          );
        },
      });
  }

  navigateBack(): void {
    this.router.navigate(this.links.childrenDetail(this._childId));
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
    this.helperService.clearSmallTextTitle();
  }
}

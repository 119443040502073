/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressTypeEnum } from './addressTypeEnum';
import { BlankEnum } from './blankEnum';
import { CountryEnum } from './countryEnum';
import { NullEnum } from './nullEnum';

export interface ChildProfileAddressCreate { 
    addressType?: AddressTypeEnum;
    catchment?: boolean;
    streetAndHouseNumber?: string;
    city?: string;
    postalCode?: string;
    country?: CountryEnum | BlankEnum | NullEnum;
    district?: string;
}
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnnouncementRead } from '@isophi/mng-api';

@Component({
  selector: 'app-announcement-card-detail',
  templateUrl: './announcement-card-detail.component.html',
  styleUrls: ['./announcement-card-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementCardDetailComponent {
  @Input() announcement: AnnouncementRead;

  get content(): string {
    return `${this.replaceNewlinesWithBreaks(this.announcement?.content)}`;
  }

  private replaceNewlinesWithBreaks(text: string = ''): string {
    return text.replace(/\r\n/g, '<br>');
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'management-app';

  constructor(private router: Router) {}

  ngOnInit(): void {
    /**
     * Workaround for Android devices.
     *
     * If app is in the background for a long time, the page is white / blank when it comes back to the foreground.
     * This is a workaround to refresh the page when the app comes back to the foreground.
     */
    try {
      document.addEventListener('visibilitychange', () => {
        const visibility: DocumentVisibilityState = document.visibilityState;
        if (visibility === 'visible' && this._isAndroid() && !this._isCreateOrEditForm()) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Determine the mobile Android operating system.
   * This function returns boolean if the user is using an Android device.
   *
   * @returns {boolean}
   */
  private _isAndroid(): boolean {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return true;
    }

    return false;
  }

  private _isCreateOrEditForm(): boolean {
    const url = this.router.url;
    return url.includes('new') || url.includes('edit');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AnnouncementCardComponent } from './components/announcement-card/announcement-card.component';
import { AnnouncementCardDetailComponent } from './components/announcement-card-detail/announcement-card-detail.component';
import { AttendanceTodayCardComponent } from './components/attendance-today-card/attendance-today-card.component';
import { GradebookCardComponent } from './components/gradebook-card/gradebook-card.component';
import { DashboardComponent } from './dashboard.component';

export const DASHBOARD_ROUTES: Routes = [{ path: '', component: DashboardComponent }];

@NgModule({
  declarations: [
    DashboardComponent,
    AttendanceTodayCardComponent,
    GradebookCardComponent,
    AnnouncementCardComponent,
    AnnouncementCardDetailComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class DashboardModule {}

@NgModule({
  imports: [RouterModule.forChild(DASHBOARD_ROUTES), DashboardModule],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { LoggedUserComponent } from './header/logged-user/logged-user.component';
import { NotificationComponent } from './header/notification/notification.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';

export const LAYOUT_ROUTES: Routes = [{ path: '', component: LayoutComponent }];

@NgModule({
  declarations: [LayoutComponent, HeaderComponent, SidebarComponent, NotificationComponent, LoggedUserComponent],
  imports: [CommonModule, SharedModule],
})
export class LayoutModule {}

@NgModule({
  imports: [RouterModule.forChild(LAYOUT_ROUTES), LayoutModule],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Gender785Enum } from './gender785Enum';
import { MainContactInvitationStatusEnum } from './mainContactInvitationStatusEnum';
import { NullEnum } from './nullEnum';

export interface ChildProfileList { 
    id: string;
    profileImage?: string;
    thumbnail?: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    gender: Gender785Enum | NullEnum;
    primaryClassName: string;
    readonly secondaryGroupNames: Array<string>;
    /**
     * Return age in years.
     */
    readonly age: number;
    idCode?: string;
    readonly mainContactFullName: string;
    readonly mainContactEmail: string;
    readonly mainContactPhone: string;
    readonly mainContactInvitationStatus: MainContactInvitationStatusEnum;
}
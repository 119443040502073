/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `transfer_to_elementary_school` - Přechod do ZŠ * `transfer_to_other_organization` - Přestup do jiné MŠ/PT/PS * `transfer_to_preparatory_class` - Přechod do přípravné třídy ZŠ * `transfer_to_preparatory_stage` - Přechod do přípravného stupně ZŠ spec. * `departure_abroad` - Odchod do zahraničí * `other` - Jiné
 */
export type AttendanceEndCodeEnum = 'transfer_to_elementary_school' | 'transfer_to_other_organization' | 'transfer_to_preparatory_class' | 'transfer_to_preparatory_stage' | 'departure_abroad' | 'other';

export const AttendanceEndCodeEnum = {
    TransferToElementarySchool: 'transfer_to_elementary_school' as AttendanceEndCodeEnum,
    TransferToOtherOrganization: 'transfer_to_other_organization' as AttendanceEndCodeEnum,
    TransferToPreparatoryClass: 'transfer_to_preparatory_class' as AttendanceEndCodeEnum,
    TransferToPreparatoryStage: 'transfer_to_preparatory_stage' as AttendanceEndCodeEnum,
    DepartureAbroad: 'departure_abroad' as AttendanceEndCodeEnum,
    Other: 'other' as AttendanceEndCodeEnum
};
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MainContactInvitationStatusEnum = 'none' | 'invited' | 'accepted_invitation';

export const MainContactInvitationStatusEnum = {
    None: 'none' as MainContactInvitationStatusEnum,
    Invited: 'invited' as MainContactInvitationStatusEnum,
    AcceptedInvitation: 'accepted_invitation' as MainContactInvitationStatusEnum
};
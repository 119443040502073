/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { Gender38aEnum } from './gender38aEnum';
import { NullEnum } from './nullEnum';

export interface ChildGroupProfile { 
    childUuid: string;
    profileImage?: string;
    firstName: string;
    lastName: string;
    gender?: Gender38aEnum | BlankEnum | NullEnum;
}
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CloseRelativeAddressCreate } from './closeRelativeAddressCreate';
import { RelativeTypeEnum } from './relativeTypeEnum';
import { Status3f1Enum } from './status3f1Enum';

export interface PatchedCloseRelativeUpdate { 
    relativeType?: RelativeTypeEnum;
    status?: Status3f1Enum;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    birthDate?: string;
    dataBox?: string;
    bankAccountNumber?: string;
    bankCode?: string;
    bankName?: string;
    iban?: string;
    swift?: string;
    note?: string;
    isPrimary?: boolean;
    addresses?: Array<CloseRelativeAddressCreate>;
}
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@isophi/core-legacy';
import { Configuration as ConfigConfiguration } from '@isophi/mng-api';

@Injectable({ providedIn: 'root' })
export class ApiConfigurationService {
  apiKeys?: { [key: string]: string };

  constructor(@Inject(ConfigConfiguration) private configConfig: ConfigConfiguration, private authService: AuthService) {}

  setAuthTokenForSwagger() {
    // set token to generated swagger service
    const token = this.authService.accessToken;
    this.configConfig.accessToken = token;
  }

  getConfiguration(): ConfigConfiguration {
    return this.configConfig;
  }

  removeAuthTokenForSwagger(): void {
    // this.configConfig.apiKeys = {};
    this.configConfig.accessToken = null;
  }
}

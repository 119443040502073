import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChildProfileDetail, Gender785Enum } from '@isophi/mng-api';

@Component({
  selector: 'app-child-detail-header',
  templateUrl: './child-detail-header.component.html',
  styleUrls: ['./child-detail-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildDetailHeaderComponent {
  @Input()
  child: ChildProfileDetail;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender785Enum;

  get childGroups(): string[] {
    const primaryGroup = this.child.childGroups.find((group) => group.uuid === this.child.primaryClass);
    const otherGroups = this.child.childGroups.filter((group) => group.uuid !== this.child.primaryClass);

    const groupsArray = [];

    if (primaryGroup && primaryGroup.name) {
      groupsArray.push(primaryGroup.name);
    }

    otherGroups.forEach((group) => {
      if (group.name) {
        groupsArray.push(group.name);
      }
    });

    return groupsArray;
  }
}

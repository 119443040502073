import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@isophi/core-ui';
import { ChildProfileDetail, ChildrenService } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { HelperService } from '../../../../core/services/helper.service';
import { HandleErrorService } from '../../../../shared/services/handle-error.service';
import { ChildFormComponent } from '../../components/child-form/child-form.component';

@Component({
  selector: 'app-create-child',
  templateUrl: './create-child.component.html',
  styleUrls: ['./create-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChildComponent {
  @ViewChild(ChildFormComponent) childFormComponent: ChildFormComponent;

  constructor(
    private helperService: HelperService,
    public links: RouterLinkFactory,
    private spinner: NgxSpinnerService,
    private childrenService: ChildrenService,
    private translocoService: TranslocoService,
    private toastr: ToastService,
    private router: Router,
    private handleErrorService: HandleErrorService
  ) {
    this.helperService.setTitle(location.pathname);
  }

  createChild(childForm): void {
    this.spinner.show();

    this.childrenService.childrenCreate(childForm.data).subscribe({
      next: (value: ChildProfileDetail) => {
        this.toastr.success(this.translocoService.translate('child/addChildSuccess'), this.translocoService.translate('child/addChild'));

        if (childForm.file instanceof Blob) {
          this.childrenService
            .childrenUploadProfileImageUpdateForm(value.childId, childForm.file)
            .pipe(
              finalize(() => {
                this.spinner.hide();
                this.childFormComponent.resetChildForm();
                this.router.navigate(this.links.childrenDetail(value.childId));
              })
            )
            .subscribe({
              error: () => {
                this.toastr.error(
                  this.translocoService.translate('child/uploadImageFailed'),
                  this.translocoService.translate('child/addChild')
                );
              },
            });
        } else {
          this.spinner.hide();
          this.childFormComponent.resetChildForm();
          this.router.navigate(this.links.childrenDetail(value.childId));
        }
      },
      error: (e) => {
        this.handleErrorService.handleFormError(e, 'child/addChild', 'child/addChildFailed');
        this.spinner.hide();
      },
    });
  }
}

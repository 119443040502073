import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { AbsenceReasonEnum, CurrentChildAbsence } from '@isophi/mng-api';

import { MenuTypeEnum } from '../../../../../../core/enums/menu-type.enum';

@Component({
  selector: 'app-child-attendance-row',
  templateUrl: './child-attendance-row.component.html',
  styleUrls: ['./child-attendance-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildAttendanceRowComponent {
  @Input()
  absence: CurrentChildAbsence;

  @Input() childId: string;

  @Output() refreshAbsence = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AbsenceReasonEnum = AbsenceReasonEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuTypeEnum = MenuTypeEnum;

  constructor(public iconService: IconService) {}
}

import { Injectable } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { Gender38aEnum, RelativeTypeEnum } from '@isophi/mng-api';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { isWithinInterval, parseISO } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';

import { MenuTypeEnum } from '../enums/menu-type.enum';
import { MenuItem } from '../models/menu.model';
import { RouterLinkFactory } from '../router/router-link.factory';

const female = [RelativeTypeEnum.Mother, RelativeTypeEnum.StepMother, RelativeTypeEnum.Grandmother, RelativeTypeEnum.Aunt];

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  titleChanged$: BehaviorSubject<string> = new BehaviorSubject(null);

  smallTextTitle$: BehaviorSubject<string> = new BehaviorSubject(null);

  showSubMenu$: BehaviorSubject<{ show: boolean; menuType: MenuTypeEnum }> = new BehaviorSubject(null);

  data;

  toggleSidebar$: Subject<string> = new Subject();

  showHeader$ = new BehaviorSubject<boolean>(true);

  get menuItems(): MenuItem[] {
    const items = [
      {
        url: this.links.homepage()[0],
        icon: this.iconService.dashboard,
        menuText: this.transloco.translate('dashboard/title'),
        title: this.transloco.translate('dashboard/title'),
      },
      {
        url: this.links.attendanceToday()[0],
        icon: this.iconService.calendar,
        menuText: this.transloco.translate('evidence/attendance'),
        title: '',
        subMenu: [
          {
            url: this.links.attendanceToday().join('/'),
            menuText: this.transloco.translate('evidence/today'),
            title: this.transloco.translate('evidence/attendanceToday'),
          },
          {
            url: this.links.attendanceOverview().join('/'),
            menuText: this.transloco.translate('evidence/overview'),
            title: this.transloco.translate('evidence/attendanceOverview'),
          },
        ],
      },
      {
        url: this.links.gradebookList()[0],
        icon: this.iconService.book,
        menuText: this.transloco.translate('gradebook/title'),
        title: this.transloco.translate('gradebook/title'),
        subItems: [
          {
            url: this.links.createGradebook()[1],
            title: this.transloco.translate('gradebook/addGradebook'),
          },
          {
            url: this.links.editGradebook('')[2],
            title: this.transloco.translate('gradebook/editGradebook'),
          },
        ],
      },
      {
        url: this.links.announcementList()[0],
        icon: this.iconService.volume2,
        menuText: this.transloco.translate('announcement/title'),
        title: this.transloco.translate('announcement/title'),
        subItems: [
          {
            url: this.links.createAnnouncement()[1],
            title: this.transloco.translate('announcement/addAnnouncement'),
          },
          {
            url: this.links.editAnnouncement('')[2],
            title: this.transloco.translate('announcement/editAnnouncement'),
          },
        ],
      },
      {
        url: this.links.children()[0],
        icon: this.iconService.children,
        menuText: this.transloco.translate('sidebar/children'),
        title: this.transloco.translate('child/title'),
        subItems: [
          {
            url: this.links.createCloseRelative('')[2],
            title: this.transloco.translate('closeRelative/addTitle'),
          },
          {
            url: this.links.editCloseRelative('', '')[2],
            title: this.transloco.translate('closeRelative/edit'),
          },
          {
            url: this.links.createChild()[1],
            title: this.transloco.translate('child/addChild'),
          },
          {
            url: this.links.editChild('')[2],
            title: this.transloco.translate('child/editChild'),
          },
        ],
      },
      {
        url: this.links.groups()[0],
        icon: this.iconService.star,
        menuText: this.transloco.translate('groups/title'),
        title: this.transloco.translate('groups/title'),
        subItems: [
          {
            url: this.links.createGroup()[1],
            title: this.transloco.translate('groups/addGroup'),
          },
          {
            url: this.links.editGroup('')[2],
            title: this.transloco.translate('group/editGroup'),
          },
        ],
      },
    ];

    return items;
  }

  constructor(
    private transloco: TranslocoService,
    private iconService: IconService,
    private links: RouterLinkFactory,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {}

  setTitle(pathnameFromMenuItems?: string, customTitle?: string): void {
    if (pathnameFromMenuItems) {
      const menuItem = this.menuItems.find((item) => pathnameFromMenuItems.includes(item.url));
      const subMenuItem = menuItem?.subMenu ? menuItem.subMenu.find((item) => pathnameFromMenuItems.includes(item.url)) : null;
      const subItem = menuItem?.subItems ? menuItem.subItems.find((item) => pathnameFromMenuItems.includes(item.url)) : null;
      const title = subMenuItem ? subMenuItem.title : subItem ? subItem.title : menuItem ? menuItem.title : '';
      this.titleChanged$.next(title);
      return;
    }

    if (customTitle) {
      const title = customTitle ? customTitle : '';
      this.titleChanged$.next(title);
    }
  }

  setSmallTextTitle(smallText: string): void {
    this.smallTextTitle$.next(smallText);
  }

  clearSmallTextTitle(): void {
    this.smallTextTitle$.next(null);
  }

  showSubMenu(show: boolean, menuType?: MenuTypeEnum, data?): void {
    if (show) {
      this.data = data;
    }
    this.showSubMenu$.next({ show, menuType });
  }

  prepareData(dataObject: { [key: string]: string }, enumObject): { [key: string]: string }[] {
    const data = [];
    for (const val in enumObject) {
      const value = enumObject[val];
      data.push({ text: dataObject[value], value });
    }
    return data;
  }

  formateDate(date): string | null {
    return date ? this.ngbDateParserFormatter.format(date).split('.').reverse().join('-') : null;
  }

  parseDate(date: string | null): NgbDateStruct {
    return date ? this.ngbDateParserFormatter.parse(date.split('-').reverse().join('.')) : null;
  }

  getGender(type: RelativeTypeEnum): Gender38aEnum {
    // currently if not female, then male, will be fixed in future
    return female.includes(type) ? Gender38aEnum.Female : Gender38aEnum.Male;
  }

  checkHolidayDates(date: NgbDateStruct, holidays): boolean {
    const day = new Date(date.year, date.month - 1, date.day).getDay();

    // Check if the date is a weekend
    const isWeekend = day === DAYS_OF_WEEK.SUNDAY || day === DAYS_OF_WEEK.SATURDAY;

    // Convert NgbDateStruct to a Date object
    const currentDate = new Date(date.year, date.month - 1, date.day);

    // Check if the date is a holiday or falls within a holiday range
    const isHoliday = this.isHoliday(currentDate, holidays);

    return isWeekend || isHoliday;
  }

  // Check if a date falls on a holiday
  isHoliday(date: Date, holidays): boolean {
    return holidays.some((holiday) => {
      const fromDate = parseISO(holiday.date);
      const toDate = holiday.dateTo ? parseISO(holiday.dateTo) : fromDate;
      return isWithinInterval(date, { start: fromDate, end: toDate });
    });
  }
}

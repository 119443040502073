<div class="gradebook-card border-r-16" *transloco="let t">
  <div class="top-section">
    <div class="d-flex align-items-center justify-content-between">
      <h4>{{ t('gradebook/title') }}</h4>
      <button class="btn btn-primary btn-icon" [routerLink]="links.gradebookList()"><i [class]="icons.chevronRight"></i></button>
    </div>
    <p>
      <span class="text-uppercase">{{ group?.name }} </span>
    </p>
  </div>
  <div class="bottom-section">
    <div class="bottom-section-1">
      <p>
        <span>{{ t('gradebook/morning') }} {{ t('shared/enrollment') }}</span>
      </p>
      <div class="section-text" *ngIf="gradebook?.morningActivities; else noActivities">
        <i [class]="icons.check + ' check-circle'"></i>
        <p>{{ gradebook?.morningActivities }}</p>
      </div>
      <ng-template #noActivities>
        <button class="btn btn-outline-primary btn-icon" (click)="navigateToGradebookForm()">
          <img src="assets/img/dashboard/pen.svg" alt="Pen" />
          {{ t('gradebook/writeInClass') }}
        </button>
      </ng-template>
    </div>
    <div class="bottom-section-1">
      <p>
        <span>{{ t('gradebook/afternoon') }} {{ t('shared/enrollment') }}</span>
      </p>
      <div class="section-text" *ngIf="gradebook?.afternoonActivities; else noActivities">
        <i [class]="icons.check + ' check-circle'"></i>
        <p>{{ gradebook?.afternoonActivities }}</p>
      </div>
      <ng-template #noActivities>
        <button class="btn btn-outline-primary btn-icon" (click)="navigateToGradebookForm()">
          <img src="assets/img/dashboard/pen.svg" alt="Pen" />
          {{ t('gradebook/writeInClass') }}
        </button>
      </ng-template>
    </div>
  </div>
</div>

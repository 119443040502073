<div class="absence" *ngIf="absence">
  <div class="header">
    <ng_container [ngSwitch]="absence.absenceReason">
      <img *ngSwitchCase="AbsenceReasonEnum.Doctor" src="assets/img/attendance/absenceReason/doctor.svg" alt="doctor" />
      <img *ngSwitchCase="AbsenceReasonEnum.FamilyReason" src="assets/img/attendance/absenceReason/family.svg" alt="family" />
      <img *ngSwitchCase="AbsenceReasonEnum.Sickness" src="assets/img/attendance/absenceReason/illness.svg" alt="illness" />
      <img *ngSwitchCase="AbsenceReasonEnum.Vacation" src="assets/img/attendance/absenceReason/holiday.svg" alt="holiday" />
      <img *ngSwitchCase="AbsenceReasonEnum.Other" src="assets/img/attendance/absenceReason/other.svg" alt="other" />
    </ng_container>
    <div class="info">
      <div class="reason">{{ absence.absenceReason | translateAbsenceReason }}</div>
      <div class="date">{{ absence.dateFrom | date: 'd. M.' }} - {{ absence.dateTo | date: 'd. M. YYYY' }}</div>
    </div>
    <div>
      <app-context-menu
        [icon]="iconService.verticalDots"
        [menuType]="MenuTypeEnum.ABSENCE"
        [childId]="childId"
        [data]="absence"
        [backgroundColor]="'transparent'"
        (refreshData)="refreshAbsence.emit()"
      ></app-context-menu>
    </div>
  </div>
  <hr />
  <div class="metadata">
    <div class="date-created">{{ absence.createdBy }}</div>
    <div class="name">{{ absence.created | date: 'd. M. YYYY' }}</div>
  </div>
</div>

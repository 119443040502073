/* eslint-disable @typescript-eslint/naming-convention */
export const nationalityData = {
  AF: 'Afghánistán',
  AX: 'Ålandy',
  AL: 'Albánie',
  DZ: 'Alžírsko',
  AS: 'Americká Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktida',
  AG: 'Antigua a Barbuda',
  AR: 'Argentina',
  AM: 'Arménie',
  AW: 'Aruba',
  AU: 'Austrálie',
  AT: 'Rakousko',
  AZ: 'Ázerbájdžán',
  BS: 'Bahamy',
  BH: 'Bahrajn',
  BD: 'Bangladéš',
  BB: 'Barbados',
  BY: 'Bělorusko',
  BE: 'Belgie',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermudy',
  BT: 'Bhútán',
  BO: 'Bolívie',
  BQ: 'Bonaire, Svatý Eustach a Saba',
  BA: 'Bosna a Hercegovina',
  BW: 'Botswana',
  BV: 'Bouvetův ostrov',
  BR: 'Brazílie',
  IO: 'Britské indickooceánské území',
  BN: 'Brunej',
  BG: 'Bulharsko',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Kapverdy',
  KH: 'Kambodža',
  CM: 'Kamerun',
  CA: 'Kanada',
  KY: 'Kajmanské ostrovy',
  CF: 'Středoafrická republika',
  TD: 'Čad',
  CL: 'Chile',
  CN: 'Čína',
  CX: 'Vánoční ostrov',
  CC: 'Kokosové (Keelingovy) ostrovy',
  CO: 'Kolumbie',
  KM: 'Komory',
  CG: 'Kongo',
  CD: 'Demokratická republika Kongo',
  CK: 'Cookovy ostrovy',
  CR: 'Kostarika',
  CI: 'Pobřeží slonoviny',
  HR: 'Chorvatsko',
  CU: 'Kuba',
  CW: 'Curaçao',
  CY: 'Kypr',
  CZ: 'Česko',
  DK: 'Dánsko',
  DJ: 'Džibutsko',
  DM: 'Dominika',
  DO: 'Dominikánská republika',
  EC: 'Ekvádor',
  EG: 'Egypt',
  SV: 'Salvador',
  GQ: 'Rovníková Guinea',
  ER: 'Eritrea',
  EE: 'Estonsko',
  SZ: 'Eswatini',
  ET: 'Etiopie',
  FK: 'Falklandy (Malvíny)',
  FO: 'Faerské ostrovy',
  FJ: 'Fidži',
  FI: 'Finsko',
  FR: 'Francie',
  GF: 'Francouzská Guyana',
  PF: 'Francouzská Polynésie',
  TF: 'Francouzská jižní a antarktická území',
  GA: 'Gabon',
  GM: 'Gambie',
  GE: 'Gruzie',
  DE: 'Německo',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Řecko',
  GL: 'Grónsko',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heardův ostrov a McDonaldovy ostrovy',
  VA: 'Svatý stolec',
  HN: 'Honduras',
  HK: 'Hongkong',
  HU: 'Maďarsko',
  IS: 'Island',
  IN: 'Indie',
  ID: 'Indonésie',
  IR: 'Írán',
  IQ: 'Irák',
  IE: 'Irsko',
  IM: 'Ostrov Man',
  IL: 'Izrael',
  IT: 'Itálie',
  JM: 'Jamajka',
  JP: 'Japonsko',
  JE: 'Jersey',
  JO: 'Jordánsko',
  KZ: 'Kazachstán',
  KE: 'Keňa',
  KI: 'Kiribati',
  KP: 'Severní Korea',
  KR: 'Jižní Korea',
  KW: 'Kuvajt',
  KG: 'Kyrgyzstán',
  LA: 'Laos',
  LV: 'Lotyšsko',
  LB: 'Libanon',
  LS: 'Lesotho',
  LR: 'Libérie',
  LY: 'Libye',
  LI: 'Lichtenštejnsko',
  LT: 'Litva',
  LU: 'Lucembursko',
  MO: 'Macao',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malajsie',
  MV: 'Maledivy',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshallovy ostrovy',
  MQ: 'Martinik',
  MR: 'Mauretánie',
  MU: 'Mauricius',
  YT: 'Mayotte',
  MX: 'Mexiko',
  FM: 'Mikronésie',
  MD: 'Moldavsko',
  MC: 'Monako',
  MN: 'Mongolsko',
  ME: 'Černá Hora',
  MS: 'Montserrat',
  MA: 'Maroko',
  MZ: 'Mosambik',
  MM: 'Myanmar (Barma)',
  NA: 'Namibie',
  NR: 'Nauru',
  NP: 'Nepál',
  NL: 'Nizozemsko',
  NC: 'Nová Kaledonie',
  NZ: 'Nový Zéland',
  NI: 'Nikaragua',
  NE: 'Niger',
  NG: 'Nigérie',
  NU: 'Niue',
  NF: 'Norfolk',
  MK: 'Severní Makedonie',
  MP: 'Severní Mariany',
  NO: 'Norsko',
  OM: 'Omán',
  PK: 'Pákistán',
  PW: 'Palau',
  PS: 'Palestinská území',
  PA: 'Panama',
  PG: 'Papua-Nová Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Filipíny',
  PN: 'Pitcairnovy ostrovy',
  PL: 'Polsko',
  PT: 'Portugalsko',
  PR: 'Portoriko',
  QA: 'Katar',
  RE: 'Réunion',
  RO: 'Rumunsko',
  RU: 'Rusko',
  RW: 'Rwanda',
  BL: 'Svatý Bartoloměj',
  SH: 'Svatá Helena',
  KN: 'Svatý Kitts a Nevis',
  LC: 'Svatá Lucie',
  MF: 'Svatý Martin (francouzská část)',
  PM: 'Svatý Pierre a Miquelon',
  VC: 'Svatý Vincenc a Grenadiny',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Svatý Tomáš a Princův ostrov',
  SA: 'Saúdská Arábie',
  SN: 'Senegal',
  RS: 'Srbsko',
  SC: 'Seychely',
  SL: 'Sierra Leone',
  SG: 'Singapur',
  SX: 'Svatý Martin (nizozemská část)',
  SK: 'Slovensko',
  SI: 'Slovinsko',
  SB: 'Šalamounovy ostrovy',
  SO: 'Somálsko',
  ZA: 'Jihoafrická republika',
  GS: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
  SS: 'Jižní Súdán',
  ES: 'Španělsko',
  LK: 'Srí Lanka',
  SD: 'Súdán',
  SR: 'Surinam',
  SJ: 'Špicberky a Jan Mayen',
  SE: 'Švédsko',
  CH: 'Švýcarsko',
  SY: 'Sýrie',
  TW: 'Tchaj-wan',
  TJ: 'Tádžikistán',
  TZ: 'Tanzanie',
  TH: 'Thajsko',
  TL: 'Východní Timor',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad a Tobago',
  TN: 'Tunisko',
  TR: 'Turecko',
  TM: 'Turkmenistán',
  TC: 'Turks a Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukrajina',
  AE: 'Spojené arabské emiráty',
  GB: 'Spojené království',
  US: 'Spojené státy americké',
  UM: 'Menší odlehlé ostrovy USA',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Britské Panenské ostrovy',
  VI: 'Americké Panenské ostrovy',
  WF: 'Wallis a Futuna',
  EH: 'Západní Sahara',
  YE: 'Jemen',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
};

export const nationalityQualifierData = {
  without_nationality: 'Osoba bez st.občanství',
  czech_republic: 'Občan ČR',
  foreigner_with_permanent_residence: 'Cizinec s trv.pobyt.v ČR',
  foreigner_without_permanent_residence: 'Cizinec bez trv.pobytu',
  unknown_or_not_declared: 'Obč. neznámé, neudané',
  asylum_seeker: 'Azylant, žadatel o azyl',
  person_with_temporary_protection: 'Osoba s dočasnou ochranou',
  person_with_supplementary_protection: 'Osoba s doplňkovou ochranou',
};

export const insuranceCompanyData = {
  vzp: '111 - Všeobecná zdravotní pojišťovna ČR',
  vozp: '201 - Vojenská zdravotní pojišťovna ČR',
  cpzp: '205 - Česká průmyslová zdravotní pojišťovna',
  ozp: '207 - Oborová zdravotní pojišťovna zaměstnanců bank, pojišťoven a stavebnictví',
  zps: '209 - Zaměstnanecká pojišťovna Škoda',
  zpmv: '211 - Zdravotní pojišťovna ministerstva vnitra ČR',
  rbp: '213 - RBP, zdravotní pojišťovna',
};

export const attendanceStartCodeData = {
  standard: 'Standardní',
  transfer_from_other_school: 'Přestup z jiné školy',
  additional_deferment: 'Dodatečný odklad (ze ZŠ)',
  return_from_abroad: 'Návrat ze zahraničí',
};

export const attendanceEndCodeData = {
  transfer_to_elementary_school: 'Přechod do ZŠ',
  transfer_to_other_organization: 'Přestup do jiné MŠ/PT/PS',
  transfer_to_preparatory_class: 'Přechod do přípravné třídy ZŠ',
  transfer_to_preparatory_stage: 'Přechod do přípravného stupně ZŠ spec.',
  departure_abroad: 'Odchod do zahraničí',
  other: 'Jiné',
};

export const attendanceFulfillmentMethodData = {
  standard: 'Standardní předškolní vzdělávání',
  education_under_34_10: 'Vzdělávání podle § 34 odst. 10 ŠZ',
  individual_education_under_34b: 'Individuální vzdělávání podle § 34b ŠZ',
};

export const educationCourseChoiceData = {
  standard: 'Řádné vzdělávání',
  after_interrupted_education: 'Řádné vzdělávání po přerušení vzdělávání',
  repeating_year_for_academic_reasons: 'Opakování ročníku z prospěchových důvodů',
  transfer_to_higher_year: 'Přeřazení do vyššího ročníku (z důvodu mimořádného nadání)',
  placement_in_lower_year: 'Zařazení do nižšího ročníku (bez opakování)',
  interruption_of_education: 'Přerušení vzdělávání',
  education_terminated: 'Vzdělávání ukončeno',
  repeating_year_for_health_reasons: 'Opakování ročníku ze zdravotních důvodů',
  full_day_education_in_kindergarten: 'Celodenní vzdělávání v MŠ',
  boarding_education_in_kindergarten: 'Internátní vzdělávání v MŠ',
  half_day_education_in_kindergarten: 'Polodenní vzdělávání v MŠ',
  education_in_ps_pt: 'Vzdělávání v PS/PT',
  education_in_kindergarten_ps_pt_terminated: 'Vzdělávání v MŠ/PT/PS ukončeno',
};

export const socialCategoryDisadvantageData = {
  without_influence: 'bez vlivu kulturního prostředí nebo jiných životních podmínek',
  cultural_environment: 'SVP vyplývají převážně z kulturního prostředí žáka',
  life_conditions: 'SVP vyplývají převážně z dopadu jiných životních podmínek žáka do vzdělávání',
  multiple_factors: 'SVP vyplývají z více faktorů uvedených pod body K a Z',
};

export const disadvantagesData = {
  without_disadvantage: 'Bez (dalšího) znevýhodnění',
  short_term_svp: 'Krátkodobé SVP vyplýv. ze zdrav. stavu či jiných okolností, mimo 1M až 8T',
  long_term_svp: 'Dlouhodobé SVP vyplýv. ze zdrav. stavu či jiných okolností, mimo 1M až 8T',
  mild_mental_disability: 'Lehké mentální postižení',
  moderate_mental_disability: 'Středně těžké mentální postižení',
  severe_mental_disability: 'Těžké mentální postižení',
  deep_mental_disability: 'Hluboké mentální postižení',
  mild_hearing_impairment: 'Mírné sluchové postižení, pokud vyžaduje PO',
  moderate_hearing_impairment: 'Středně těžké sluchové postižení',
  severe_hearing_impairment: 'Těžké sluchové postižení',
  deaf: 'Neslyšící',
  mild_visual_impairment: 'Mírné zrakové postižení, pokud vyžaduje PO',
  moderate_visual_impairment: 'Středně těžké zrakové postižení',
  severe_visual_impairment: 'Těžké zrakové postižení',
  blind: 'Nevidomí',
  mild_speech_defects: 'Mírné vady řeči, pokud vyžadují PO',
  moderate_speech_defects: 'Středně závažné vady řeči',
  severe_speech_defects: 'Závažné vady řeči',
  mild_physical_disability: 'Mírné tělesné postižení, pokud vyžaduje PO',
  moderate_physical_disability: 'Středně těžké tělesné postižení',
  severe_physical_disability: 'Těžké tělesné postižení',
  mild_behavioral_disorders: 'Mírné poruchy chování, pokud vyžadují PO',
  moderate_behavioral_disorders: 'Středně závažné poruchy chování',
  severe_behavioral_disorders: 'Závažné poruchy chování',
  mild_learning_disorders: 'Mírné poruchy učení, pokud vyžadují PO',
  moderate_learning_disorders: 'Středně závažné poruchy učení',
  severe_learning_disorders: 'Závažné poruchy učení',
  autism_spectrum_disorder: 'Poruchy autist. spektra mimo dětského autismu se závažným odrazem do vzdělávání',
  mild_autism_spectrum_disorder: 'Poruchy autistického spektra s mírným odrazem do vzdělávání žáka',
  childhood_autism: 'Dětský autismus (se závažným odrazem do vzdělávání žáka)',
};

export const measureLevelData = {
  first_level: '1. stupeň',
  second_level: '2. stupeň',
  third_level: '3. stupeň',
  fourth_level: '4. stupeň',
  fifth_level: '5. stupeň',
};

export const closeRelativeStatusData = {
  legal_representative: 'Zákonný zástupce',
  authorized_person: 'Zmocněná osoba vyzvedávat',
};

export const closeRelativeTypeData = {
  mother: 'Matka',
  father: 'Otec',
  step_mother: 'Matka (nevlastní)',
  step_father: 'Otec (nevlastní)',
  grandmother: 'Babička',
  grandfather: 'Děda',
  sibling: 'Sourozenec',
  aunt: 'Teta',
  uncle: 'Strýc',
  foster_parent: 'Pěstoun/ka',
  guardian: 'Poručník/ce',
  family_friend: 'Rodinný přítel/kyně',
  relative: 'Příbuzný/á',
  close_relative: 'Blízký/á',
  other: 'Ostatní',
};

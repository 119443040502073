<ng-container *transloco="let t">
  <button
    (click)="$event.stopPropagation()"
    class="btn fw-bold p-0 border-r-full"
    id="context-menu"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [style.background-color]="backgroundColor"
  >
    <i [class]="icon"></i>
  </button>
  <div id="dropdownContextMenu" class="dropdown-menu" aria-labelledby="context-menu">
    <ul>
      <a
        *ngIf="
          menuType !== MenuTypeEnum.ATTENDANCE_OVERVIEW && menuType !== MenuTypeEnum.ABSENCE && data?.dayOffType !== DayOffTypeEnum.Holiday
        "
        [routerLink]="navToEdit()"
        class="dropdown-item"
        >{{ t('shared/edit') }}</a
      >
      <a
        *ngIf="menuType === MenuTypeEnum.CHILD"
        type="button"
        (click)="archiveChild($event, data?.id || data?.childId)"
        class="dropdown-item"
        >{{ t('shared/archive') }}</a
      >
      <a *ngIf="menuType === MenuTypeEnum.ATTENDANCE_OVERVIEW" (click)="openModal()" class="dropdown-item">{{ t('shared/edit') }}</a>
      <a *ngIf="menuType === MenuTypeEnum.ANNOUNCEMENT" (click)="removeAnnouncement($event)" class="dropdown-item">{{
        t('shared/remove')
      }}</a>
      <a *ngIf="menuType === MenuTypeEnum.USER && !data?.teacher.isManager()" (click)="removeUser($event)" class="dropdown-item">{{
        t('shared/remove')
      }}</a>
      <a *ngIf="menuType === MenuTypeEnum.CLOSE_RELATIVE" (click)="inviteParent()" class="dropdown-item">{{ t('child/inviteParent') }}</a>
      <a *ngIf="menuType === MenuTypeEnum.ABSENCE" (click)="removeAbsence()" class="dropdown-item">{{ t('shared/remove') }}</a>
      <a *ngIf="menuType === MenuTypeEnum.DAY_OFF" (click)="removeDayOff()" class="dropdown-item">{{ t('shared/remove') }}</a>
    </ul>
  </div>
</ng-container>

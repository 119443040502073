<ng-container *transloco="let t">
  <div class="modal-header">
    <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close fw-normal" tabindex="-1">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>{{ t('evidence/editAttendance') }}</h2>
    <p>
      <span class="text-capitalize">{{ t('evidence/selected') }}</span>
      {{ childAttendanceOverview || children?.length === 1 ? t('shared/child') : t('shared/children') }}:
      <span>{{ childAttendanceOverview || children?.length === 1 ? 1 : children?.length }}</span>
    </p>

    <div class="btn-group absence attendance-type" role="attendance-type" aria-label="attendance-type radio toggle button">
      <input
        type="radio"
        class="btn-check"
        name="attendanceType"
        id="present"
        [value]="AttendanceType.PRESENT_TYPE"
        [(ngModel)]="attendanceType"
        (ngModelChange)="onChangeAttendanceType()"
      />
      <label class="btn btn-outline-primary border-end-0" for="present">
        {{ t('evidence/present') }}
      </label>

      <input
        type="radio"
        class="btn-check"
        name="attendanceType"
        id="absent"
        [value]="AttendanceType.ABSENT_TYPE"
        [(ngModel)]="attendanceType"
        (ngModelChange)="onChangeAttendanceType()"
      />
      <label class="btn btn-outline-primary border-start-0" for="absent">
        {{ t('evidence/absent') }}
      </label>
    </div>

    <div *ngIf="attendanceType === AttendanceType.PRESENT_TYPE" class="time-container">
      <div>
        <label for="arrival">{{ t('evidence/arrival') }}:</label>
        <div class="input-group">
          <input id="arrival" class="form-control" [(ngModel)]="arrival" [placeholder]="t('evidence/enterTime')" />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="$event.stopPropagation()"
              id="time-menu-1"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i [class]="icons.clock"></i>
            </button>
            <div id="timeMenu-1" class="dropdown-menu" aria-labelledby="time-menu-1" data-popper-placement="bottom">
              <ul>
                <li *ngFor="let i of timeList" class="dropdown-item" (click)="selectArrivalTime(i)">{{ i }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label for="departure">{{ t('evidence/departure') }}:</label>
        <div class="input-group">
          <input id="departure" class="form-control" [(ngModel)]="departure" [placeholder]="t('evidence/enterTime')" />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="$event.stopPropagation()"
              id="time-menu-1"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i [class]="icons.clock"></i>
            </button>
            <div id="timeMenu-2" class="dropdown-menu" aria-labelledby="time-menu-2" data-popper-placement="bottom">
              <ul>
                <li *ngFor="let i of timeList" class="dropdown-item" (click)="selectDepartureTime(i)">{{ i }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="attendanceType === AttendanceType.ABSENT_TYPE"
      class="btn-group absence"
      role="group"
      aria-label="absence radio toggle button group"
    >
      <input type="radio" class="btn-check" name="absence" id="morning" [value]="AbsenceEnum.AbsentMorning" [(ngModel)]="absence" />
      <label class="btn btn-outline-primary border-end-0" for="morning">
        <img src="assets/img/attendance/{{ AbsenceEnum.AbsentMorning }}.svg" alt="absence morning" />
        {{ t('evidence/morning') }}
      </label>

      <input type="radio" class="btn-check" name="absence" id="afternoon" [value]="AbsenceEnum.AbsentAfternoon" [(ngModel)]="absence" />
      <label class="btn btn-outline-primary" for="afternoon">
        <img src="assets/img/attendance/{{ AbsenceEnum.AbsentAfternoon }}.svg" alt="absence after" />
        {{ t('evidence/afternoon') }}
      </label>

      <input type="radio" class="btn-check" name="absence" id="all-day" [value]="AbsenceEnum.Absent" [(ngModel)]="absence" />
      <label class="btn btn-outline-primary border-start-0" for="all-day">
        <img src="assets/img/attendance/{{ AbsenceEnum.Absent }}.svg" alt="absence" />
        {{ t('evidence/allDay') }}
      </label>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-center">
    <button (click)="activeModal.close(null)" class="btn btn-lg btn-outline-primary me-4" tabindex="-1">{{ t('child/cancel') }}</button>
    <button (click)="submitButton()" class="btn btn-lg btn-primary" [disabled]="attendanceType === AttendanceType.ABSENT_TYPE && !absence">
      {{ t('shared/edit') }}
    </button>
  </div>
</ng-container>

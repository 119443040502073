/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AnnouncementRead } from '../model/announcementRead';
import { PaginatedAnnouncementReadList } from '../model/paginatedAnnouncementReadList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AnnouncementsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * ViewSet for managing announcements
     * @param title 
     * @param content 
     * @param image 
     * @param wholeKindergarten 
     * @param groupUuids 
     * @param isInternal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsCreateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, observe?: 'body', reportProgress?: boolean): Observable<AnnouncementRead>;
    public announcementsCreateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnnouncementRead>>;
    public announcementsCreateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnnouncementRead>>;
    public announcementsCreateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling announcementsCreate.');
        }

        if (content === null || content === undefined) {
            throw new Error('Required parameter content was null or undefined when calling announcementsCreate.');
        }

        if (image === null || image === undefined) {
            throw new Error('Required parameter image was null or undefined when calling announcementsCreate.');
        }

        if (wholeKindergarten === null || wholeKindergarten === undefined) {
            throw new Error('Required parameter wholeKindergarten was null or undefined when calling announcementsCreate.');
        }

        if (groupUuids === null || groupUuids === undefined) {
            throw new Error('Required parameter groupUuids was null or undefined when calling announcementsCreate.');
        }

        if (isInternal === null || isInternal === undefined) {
            throw new Error('Required parameter isInternal was null or undefined when calling announcementsCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('image', <any>image) as any || formParams;
        }
        if (wholeKindergarten !== undefined) {
            formParams = formParams.append('whole_kindergarten', <any>wholeKindergarten) as any || formParams;
        }
        if (groupUuids) {
            groupUuids.forEach((element) => {
                formParams = formParams.append('group_uuids', <any>element) as any || formParams;
            })
        }
        if (isInternal !== undefined) {
            formParams = formParams.append('is_internal', <any>isInternal) as any || formParams;
        }

        return this.httpClient.request<AnnouncementRead>('post',`${this.basePath}/announcements/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * ViewSet for managing announcements
     * @param id A UUID string identifying this Oznámení.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsDestroy(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public announcementsDestroy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public announcementsDestroy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public announcementsDestroy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling announcementsDestroy.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/announcements/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * ViewSet for managing announcements
     * @param groupUuid Filter by group UUID. If value is &#x27;whole_kindergarten&#x27; it will return announcements for whole kindergarten.
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsList(groupUuid?: string, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean): Observable<PaginatedAnnouncementReadList>;
    public announcementsList(groupUuid?: string, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedAnnouncementReadList>>;
    public announcementsList(groupUuid?: string, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedAnnouncementReadList>>;
    public announcementsList(groupUuid?: string, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupUuid !== undefined && groupUuid !== null) {
            queryParameters = queryParameters.set('group_uuid', <any>groupUuid);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedAnnouncementReadList>('get',`${this.basePath}/announcements/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * ViewSet for managing announcements
     * @param id A UUID string identifying this Oznámení.
     * @param id2 
     * @param title 
     * @param content 
     * @param image 
     * @param imageThumbnail 
     * @param created 
     * @param createdByUserId 
     * @param createdByUserFullName 
     * @param modified 
     * @param modifiedByUserId 
     * @param modifiedByUserFullName 
     * @param wholeKindergarten 
     * @param groupUuids 
     * @param isInternal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsPartialUpdateForm(id: string, id2?: string, title?: string, content?: string, image?: string, imageThumbnail?: string, created?: Date, createdByUserId?: number, createdByUserFullName?: string, modified?: Date, modifiedByUserId?: number, modifiedByUserFullName?: string, wholeKindergarten?: boolean, groupUuids?: Array<string>, isInternal?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AnnouncementRead>;
    public announcementsPartialUpdateForm(id: string, id2?: string, title?: string, content?: string, image?: string, imageThumbnail?: string, created?: Date, createdByUserId?: number, createdByUserFullName?: string, modified?: Date, modifiedByUserId?: number, modifiedByUserFullName?: string, wholeKindergarten?: boolean, groupUuids?: Array<string>, isInternal?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnnouncementRead>>;
    public announcementsPartialUpdateForm(id: string, id2?: string, title?: string, content?: string, image?: string, imageThumbnail?: string, created?: Date, createdByUserId?: number, createdByUserFullName?: string, modified?: Date, modifiedByUserId?: number, modifiedByUserFullName?: string, wholeKindergarten?: boolean, groupUuids?: Array<string>, isInternal?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnnouncementRead>>;
    public announcementsPartialUpdateForm(id: string, id2?: string, title?: string, content?: string, image?: string, imageThumbnail?: string, created?: Date, createdByUserId?: number, createdByUserFullName?: string, modified?: Date, modifiedByUserId?: number, modifiedByUserFullName?: string, wholeKindergarten?: boolean, groupUuids?: Array<string>, isInternal?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling announcementsPartialUpdate.');
        }















        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (id !== undefined) {
            formParams = formParams.append('id', <any>id) as any || formParams;
        }
        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('image', <any>image) as any || formParams;
        }
        if (imageThumbnail !== undefined) {
            formParams = formParams.append('image_thumbnail', <any>imageThumbnail) as any || formParams;
        }
        if (created !== undefined) {
            formParams = formParams.append('created', <any>created) as any || formParams;
        }
        if (createdByUserId !== undefined) {
            formParams = formParams.append('created_by_user_id', <any>createdByUserId) as any || formParams;
        }
        if (createdByUserFullName !== undefined) {
            formParams = formParams.append('created_by_user_full_name', <any>createdByUserFullName) as any || formParams;
        }
        if (modified !== undefined) {
            formParams = formParams.append('modified', <any>modified) as any || formParams;
        }
        if (modifiedByUserId !== undefined) {
            formParams = formParams.append('modified_by_user_id', <any>modifiedByUserId) as any || formParams;
        }
        if (modifiedByUserFullName !== undefined) {
            formParams = formParams.append('modified_by_user_full_name', <any>modifiedByUserFullName) as any || formParams;
        }
        if (wholeKindergarten !== undefined) {
            formParams = formParams.append('whole_kindergarten', <any>wholeKindergarten) as any || formParams;
        }
        if (groupUuids) {
            groupUuids.forEach((element) => {
                formParams = formParams.append('group_uuids', <any>element) as any || formParams;
            })
        }
        if (isInternal !== undefined) {
            formParams = formParams.append('is_internal', <any>isInternal) as any || formParams;
        }

        return this.httpClient.request<AnnouncementRead>('patch',`${this.basePath}/announcements/${encodeURIComponent(String(id))}/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * ViewSet for managing announcements
     * @param id A UUID string identifying this Oznámení.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsRetrieve(id: string, observe?: 'body', reportProgress?: boolean): Observable<AnnouncementRead>;
    public announcementsRetrieve(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnnouncementRead>>;
    public announcementsRetrieve(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnnouncementRead>>;
    public announcementsRetrieve(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling announcementsRetrieve.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AnnouncementRead>('get',`${this.basePath}/announcements/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * ViewSet for managing announcements
     * @param title 
     * @param content 
     * @param image 
     * @param wholeKindergarten 
     * @param groupUuids 
     * @param isInternal 
     * @param id A UUID string identifying this Oznámení.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public announcementsUpdateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, id: string, observe?: 'body', reportProgress?: boolean): Observable<AnnouncementRead>;
    public announcementsUpdateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnnouncementRead>>;
    public announcementsUpdateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnnouncementRead>>;
    public announcementsUpdateForm(title: string, content: string, image: Blob, wholeKindergarten: boolean, groupUuids: Array<string>, isInternal: boolean, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling announcementsUpdate.');
        }

        if (content === null || content === undefined) {
            throw new Error('Required parameter content was null or undefined when calling announcementsUpdate.');
        }

        if (image === null || image === undefined) {
            throw new Error('Required parameter image was null or undefined when calling announcementsUpdate.');
        }

        if (wholeKindergarten === null || wholeKindergarten === undefined) {
            throw new Error('Required parameter wholeKindergarten was null or undefined when calling announcementsUpdate.');
        }

        if (groupUuids === null || groupUuids === undefined) {
            throw new Error('Required parameter groupUuids was null or undefined when calling announcementsUpdate.');
        }

        if (isInternal === null || isInternal === undefined) {
            throw new Error('Required parameter isInternal was null or undefined when calling announcementsUpdate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling announcementsUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('image', <any>image) as any || formParams;
        }
        if (wholeKindergarten !== undefined) {
            formParams = formParams.append('whole_kindergarten', <any>wholeKindergarten) as any || formParams;
        }
        if (groupUuids) {
            groupUuids.forEach((element) => {
                formParams = formParams.append('group_uuids', <any>element) as any || formParams;
            })
        }
        if (isInternal !== undefined) {
            formParams = formParams.append('is_internal', <any>isInternal) as any || formParams;
        }

        return this.httpClient.request<AnnouncementRead>('put',`${this.basePath}/announcements/${encodeURIComponent(String(id))}/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

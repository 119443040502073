import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@isophi/core-ui';
import { ChildProfileDetail, ChildrenService } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { HelperService } from '../../../../core/services/helper.service';
import { HandleErrorService } from '../../../../shared/services/handle-error.service';
import { ChildFormComponent } from '../../components/child-form/child-form.component';

@Component({
  selector: 'app-edit-child',
  templateUrl: './edit-child.component.html',
  styleUrls: ['./edit-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditChildComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ChildFormComponent) childFormComponent: ChildFormComponent;

  child: ChildProfileDetail;

  private _subs: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    public links: RouterLinkFactory,
    private spinner: NgxSpinnerService,
    private childrenService: ChildrenService,
    private translocoService: TranslocoService,
    private toastr: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private handleErrorService: HandleErrorService
  ) {
    this.helperService.setTitle(location.pathname);
  }

  ngAfterViewInit(): void {
    this._subs.push(
      this.route.paramMap.subscribe((params) => {
        const id = params.get('id');
        this._subs.push(
          this.childrenService.childrenRetrieve(id).subscribe((childData: ChildProfileDetail) => {
            this.child = childData;
            this.childFormComponent.setFormData(childData);
            this.helperService.smallTextTitle$.next(childData.firstName + ' ' + childData.lastName);
          })
        );
      })
    );
  }

  editChild(childForm): void {
    this.spinner.show();

    this.childrenService.childrenUpdate(childForm.data, this.child.childId).subscribe({
      next: () => {
        this.toastr.success(this.translocoService.translate('child/editChildSuccess'), this.translocoService.translate('child/editChild'));

        if (childForm.file instanceof Blob) {
          this.childrenService
            .childrenUploadProfileImageUpdateForm(this.child.childId, childForm.file)
            .pipe(
              finalize(() => {
                this.spinner.hide();
                this.childFormComponent.resetChildForm();
                this.router.navigate(this.links.childrenDetail(this.child.childId));
              })
            )
            .subscribe({
              error: () => {
                this.toastr.error(
                  this.translocoService.translate('child/uploadImageFailed'),
                  this.translocoService.translate('child/editChild')
                );
              },
            });
        } else {
          this.spinner.hide();
          this.childFormComponent.resetChildForm();
          this.router.navigate(this.links.childrenDetail(this.child.childId));
        }
      },
      error: (e) => {
        this.handleErrorService.handleFormError(e, 'child/editChild', 'child/editChildFailed');
        this.spinner.hide();
      },
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
    this.helperService.clearSmallTextTitle();
  }
}

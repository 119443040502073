<div class="children-detail page-layout" *transloco="let t">
  <div class="grid-container" *ngIf="child$ | withLoader | async as child">
    <div class="x detail-header">
      <app-child-detail-header [child]="child"></app-child-detail-header>
    </div>
    <div class="y actual-attendance">
      <app-child-actual-attendance [child]="child"></app-child-actual-attendance>
    </div>
    <div class="z detailed-info">
      <app-child-detailed-info [child]="child"></app-child-detailed-info>
    </div>
    <div class="v close-person">
      <app-child-close-relative [child]="child"></app-child-close-relative>
    </div>
  </div>
</div>
<app-close-relative-sidebar
  *ngIf="child$ | withLoader | async as child"
  [child]="child"
  (isOpen)="sidebarOpened = $event"
  #sidebar
></app-close-relative-sidebar>
<div *ngIf="sidebarOpened" class="sb-overlay"></div>

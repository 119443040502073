import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IconService, ToastService } from '@isophi/core-ui';
import {
  AbsenceEnum,
  AbsenceReasonEnum,
  AttendanceService,
  ChildProfileDetail,
  ChildrenService,
  ClassCreateOrUpdateAttendance,
  CurrentChildAbsence,
  Status4a6Enum,
} from '@isophi/mng-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, finalize, Observable, switchMap } from 'rxjs';

import { RouterLinkFactory } from '../../../../../core/router/router-link.factory';
import { ExcuseModalComponent } from '../../../../../shared/components/excuse-modal/excuse-modal.component';

@Component({
  selector: 'app-child-actual-attendance',
  templateUrl: './child-actual-attendance.component.html',
  styleUrls: ['./child-actual-attendance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildActualAttendanceComponent implements OnChanges {
  @Input()
  child: ChildProfileDetail;

  @HostBinding('class.loaded') get classLoaded() {
    return this._loaded;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AttendanceTypeEnum = Status4a6Enum;

  absences$: Observable<Array<CurrentChildAbsence>>;

  private _loaded = false;

  private reloadAbsences$ = new BehaviorSubject<void>(null);

  constructor(
    private childrenService: ChildrenService,
    protected iconsService: IconService,
    private modal: NgbModal,
    private spinner: NgxSpinnerService,
    private attendanceService: AttendanceService,
    private toastr: ToastService,
    private translocoService: TranslocoService,
    private routerLinkFactory: RouterLinkFactory,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.child && changes.child.currentValue) {
      this._loadAbsences();
    }
  }

  addAttendance(): void {
    const modalRef = this.modal.open(ExcuseModalComponent, { size: 'md', centered: true });

    modalRef.closed.subscribe((data) => {
      if (data) {
        this.callSetCurrentClassAttendance(
          this.AttendanceTypeEnum.Absent,
          'evidence/absenceSuccess',
          data.absence,
          data.absenceReason,
          data.dateFrom,
          data.dateTo
        );
      }
    });
  }

  callSetCurrentClassAttendance(
    status: Status4a6Enum,
    successText: string,
    absence?: AbsenceEnum,
    absenceReason: AbsenceReasonEnum = null,
    dateFrom = null,
    dateTo = null
  ): void {
    this.spinner.show();
    const data: ClassCreateOrUpdateAttendance = {
      classUuid: this.child.primaryClass,
      childUuids: [this.child.childId],
      status: status,
      absence: absence,
      absenceReason: absenceReason,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    this.attendanceService
      .attendanceCreateClassAttendanceGroupCreate(data)
      .pipe(
        finalize(() => {
          this.spinner.hide();
          this.cd.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate(successText));
          this._loadAbsences();
        },
        error: (error) => {
          this.toastr.error(error.error.error);
        },
      });
  }

  history(): void {
    this.router.navigate(this.routerLinkFactory.attendanceOverview(), { queryParams: { groupId: this.child.primaryClass } });
  }

  reloadAbsences(): void {
    this.reloadAbsences$.next();
  }

  private _loadAbsences(): void {
    try {
      this.spinner.show();
      this.absences$ = this.reloadAbsences$.pipe(
        switchMap(() => this.childrenService.childrenAbsenceList(this.child.childId).pipe(finalize(() => (this._loaded = true))))
      );
      this.cd.markForCheck();
    } finally {
      this.spinner.hide();
      this.cd.markForCheck();
    }
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconService } from '@isophi/core-ui';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() childForm: FormGroup;

  @Input() formControlName: string;

  @Input() label: string;

  @Input() required? = false;

  @Input() formControlError?: string;

  @Input() customValidation? = false;

  @Input() customErrorName?: string;

  @Input() formControlCustomError?: string;

  @Input() placeholder?: string = '';

  @Input() requiredColor? = false;

  @Input() parentVisible? = false;

  @Output() valueChange? = new EventEmitter<string>();

  constructor(protected iconService: IconService) {}

  onInputChange(value) {
    this.valueChange.emit(value);
  }

  getFormControl(): FormControl {
    return this.childForm.get(this.formControlName) as FormControl;
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: any): void {
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

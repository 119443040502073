<div
  (click)="onClick($event)"
  [attr.role]="disabled ? '' : 'button'"
  class="group-detail-card border-r-16"
  [class]="{ 'clicked-style': isChildMarked }"
  *transloco="let t"
>
  <div class="d-flex align-items-center">
    <div *ngIf="child.thumbnail; else profileImage" class="preview me-4 rounded-circle">
      <img [src]="child.thumbnail" alt="child image" />
    </div>
    <ng-template #profileImage>
      <div *ngIf="child.profileImage; else noImage" class="preview me-4 rounded-circle">
        <img [src]="child.profileImage" alt="child image" />
      </div>
    </ng-template>
    <ng-template #noImage>
      <img *ngIf="child.gender === GenderEnum.Male" src="assets/img/iconsDefault/boy.svg" alt="boy" class="me-4" width="44px" />
      <img *ngIf="child.gender === GenderEnum.Female" src="assets/img/iconsDefault/girl.svg" alt="girl" class="me-4" width="44px" />
    </ng-template>
    <div>
      <p class="mb-0">
        <span class="fw-bold">{{ child.lastName }}</span> {{ child.firstName }}
      </p>
      <p *ngIf="child.departureTime" class="mb-0"><img src="assets/img/attendance/out.svg" alt="out" /> {{ child.departureTime }}</p>
    </div>
  </div>
  <div>
    <img *ngIf="child.absence" src="assets/img/attendance/{{ child.absence }}.svg" [alt]="child.absence" />
    <input
      class="form-check-input ms-4"
      type="checkbox"
      (click)="$event.stopPropagation()"
      (change)="onClick($event)"
      [checked]="isChildMarked"
      [disabled]="disabled"
    />
  </div>
</div>

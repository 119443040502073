import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IStorage, LocalStorage } from '@isophi/core-legacy';
import { IconService, ToastService } from '@isophi/core-ui';
import {
  Archive,
  ChildGroupSuggestion,
  ChildProfileBulkDelete,
  ChildProfileList,
  ChildrenService,
  ClassesService,
  InvitationsService,
  InviteCloseRelatives,
} from '@isophi/mng-api';
import { PopUpComponent } from '@isophi/teachers-shared';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, finalize, Observable, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { HandleErrorService } from '../../../../shared/services/handle-error.service';
import { LocalStorageConstService } from '../../../../shared/services/local-storage-const.service';
import { FilterCriteria } from './filter-criteria.model';

enum ChildMenuEnum {
  ARCHIVE = 'archive',
  REMOVE = 'remove',
  INVITE = 'invite',
}

@Component({
  selector: 'app-children-filter',
  templateUrl: './children-filter.component.html',
  styleUrls: ['./children-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildrenFilterComponent implements OnInit, OnDestroy {
  @ViewChild('toastTemplate') toastTemplate: TemplateRef<any>;

  @ViewChild('toastTemplate2') toastTemplate2: TemplateRef<any>;

  infoMessage: string;

  infoMessage2: string;

  @Input() selectedChildren: ChildProfileList[] = [];

  @Output() filterUpdated: EventEmitter<FilterCriteria> = new EventEmitter();

  @Output() refreshChildren = new EventEmitter<void>();

  childrenGroups$: Observable<ChildGroupSuggestion[]>;

  selectedGroup: string;

  nameFilter: FormControl = new FormControl('');

  filteredChildren$: Observable<ChildProfileList[]>;

  archivedChildren = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ChildMenuEnum = ChildMenuEnum;

  initChildMenu: FormControl<string | null>;

  private _subs: Subscription[] = [];

  private storage: IStorage;

  constructor(
    private classesService: ClassesService,
    public iconService: IconService,
    private router: Router,
    private links: RouterLinkFactory,
    private lSConstService: LocalStorageConstService,
    private translocoService: TranslocoService,
    private popUp: PopUpComponent,
    private childrenService: ChildrenService,
    private spinner: NgxSpinnerService,
    private toastr: ToastService,
    private invitationsService: InvitationsService,
    private handleErrorService: HandleErrorService
  ) {
    this.childrenGroups$ = this.classesService.classesSuggestionsList();
    this.storage = new LocalStorage();
    this.initChildMenu = new FormControl(null);
  }

  ngOnInit(): void {
    if (this.storage.hasItem(this.lSConstService.childrenFilter)) {
      const storageData = this.storage.getItem(this.lSConstService.childrenFilter);
      if (storageData && storageData !== 'undefined') {
        const filterCriteria: FilterCriteria = JSON.parse(storageData);
        this.nameFilter.patchValue(filterCriteria.name);
        this.selectedGroup = filterCriteria.group;
        this.archivedChildren = filterCriteria.archived;
        this.filterChildren();
      }
    }

    this._subs.push(
      this.nameFilter.valueChanges
        .pipe(
          debounceTime(300) // Adjust the delay time as needed (e.g., 300ms)
        )
        .subscribe((name) => {
          const childName = name.trim().toLowerCase();
          this.filterChildren(childName);
        })
    );
  }

  createChild() {
    this.router.navigate(this.links.createChild());
  }

  filterChildren(name?: string): void {
    const filterCriteria: FilterCriteria = {
      group: this.selectedGroup,
      name: name || this.nameFilter.value.trim().toLowerCase(),
      archived: this.archivedChildren,
    };
    this.storage.setItem(this.lSConstService.childrenFilter, JSON.stringify(filterCriteria));
    this.filterUpdated.emit(filterCriteria);
  }

  onOptionSelected(option): void {
    this.initChildMenu.patchValue(this.translocoService.translate('child/select'));

    if (option === this.ChildMenuEnum.ARCHIVE) {
      this.archiveChildren(this.selectedChildren);
    } else if (option === this.ChildMenuEnum.REMOVE) {
      this.removeChildren(this.selectedChildren);
    } else if (option === this.ChildMenuEnum.INVITE) {
      this.inviteParents(this.selectedChildren);
    }
  }

  async archiveChildren(selectedChildren: ChildProfileList[]): Promise<void> {
    const title = this.translocoService.translate('group/archiveChildren');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    const childrenArchive: Archive = {
      childUuids: selectedChildren.map((child) => child.id),
    };

    await this.spinner.show();
    this.childrenService
      .childrenBulkArchiveCreate(childrenArchive)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('group/archiveSuccess'),
            this.translocoService.translate('group/archiveChildren')
          );
          this.refreshChildren.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'group/archiveChildren', 'group/archiveError');
        },
      });
  }

  async removeChildren(selectedChildren: ChildProfileList[]): Promise<void> {
    const title = this.translocoService.translate('child/removeChildren');
    const text = this.translocoService.translate('shared/confirm');
    const yesText = this.translocoService.translate('shared/yes');
    const noText = this.translocoService.translate('shared/no');
    const confirm = await this.popUp.confirm(title, text, null, yesText, noText, 'btn-primary', 'btn-outline-primary');

    if (!confirm) return;

    await this.spinner.show();

    const data: ChildProfileBulkDelete = {
      childUuids: selectedChildren.map((child) => child.id),
    };

    this.childrenService
      .childrenBulkDeleteCreate(data)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('child/removeChildrenSuccess'),
            this.translocoService.translate('child/removeChildren')
          );
          this.refreshChildren.emit();
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'child/removeChildren', 'child/removeChildrenFailed');
        },
      });
  }

  inviteParents(selectedChildren: ChildProfileList[]): void {
    this.spinner.show();

    const data: InviteCloseRelatives = {
      childUuids: selectedChildren.map((child) => child.id),
    };

    this.invitationsService
      .invitationsInviteCloseRelativesCreate(data)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: (val) => {
          if (val.childUuidsWithoutLegalRepresentativeEmail.length > 0 || val.childUuidsWithoutLegalRepresentative.length > 0) {
            if (val.childUuidsWithoutLegalRepresentativeEmail.length > 0) {
              const childrenWithoutMails = selectedChildren
                .filter((child) => val.childUuidsWithoutLegalRepresentativeEmail.includes(child.id))
                .map((child) => child.lastName + ' ' + child.firstName)
                .join(', ');
              this.infoMessage = `${this.translocoService.translate('child/parentsWithoutMailsError')}:<br> ${childrenWithoutMails}`;
              this.toastr.error(this.toastTemplate, '', false);
            }
            if (val.childUuidsWithoutLegalRepresentative.length > 0) {
              const childrenWithoutMails = selectedChildren
                .filter((child) => val.childUuidsWithoutLegalRepresentative.includes(child.id))
                .map((child) => child.lastName + ' ' + child.firstName)
                .join(', ');
              this.infoMessage2 = `${this.translocoService.translate(
                'child/parentsWithoutLegalRepresentativeError'
              )}:<br> ${childrenWithoutMails}`;
              this.toastr.error(this.toastTemplate2, '', false);
            }

            const successChildUuids = selectedChildren
              .filter((child) => !val.childUuidsWithoutLegalRepresentativeEmail.some((uuid) => uuid === child.id))
              .filter((child) => !val.childUuidsWithoutLegalRepresentative.some((uuid) => uuid === child.id));

            if (successChildUuids.length > 0) {
              this.toastr.success(
                this.translocoService.translate('child/invitationPartialSuccess'),
                this.translocoService.translate('child/inviteParent')
              );
              this.refreshChildren.emit();
            }
          } else {
            this.toastr.success(
              this.translocoService.translate('child/inviteParentSuccess'),
              this.translocoService.translate('child/inviteParent')
            );
            this.refreshChildren.emit();
          }
        },
        error: (e) => {
          this.handleErrorService.handleFormError(e, 'child/inviteParent', 'child/inviteParentError');
        },
      });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}

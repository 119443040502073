import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-products-modal',
  templateUrl: './preview-products-modal.component.html',
  styleUrls: ['./preview-products-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewProductsModalComponent {
  title: string;

  image: string;

  text: string;

  constructor(public activeModal: NgbActiveModal) {}
}

<div class="announcement-card border-r-16" *transloco="let t">
  <div class="top-section">
    <h4 class="me-2">{{ t('announcement/title') }}</h4>
    <button class="btn btn-outline-primary btn-icon" [routerLink]="links.createAnnouncement()">
      <i [class]="icons.plus"></i> {{ t('announcement/addAnnouncement') }}
    </button>
  </div>
  <div class="bottom-section" #scrollContainer>
    <app-announcement-card-detail
      *ngFor="let announcement of announcementList"
      [announcement]="announcement"
    ></app-announcement-card-detail>
  </div>
</div>

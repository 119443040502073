<ng-container *transloco="let t">
  <div class="avatar">
    <img *ngIf="child.thumbnail; else profileImage" [src]="child.thumbnail" alt="Preview" class="profile-image h-100" />
    <ng-template #profileImage>
      <img *ngIf="child.profileImage; else noImage" [src]="child.profileImage" alt="Preview" class="profile-image h-100" />
    </ng-template>
    <ng-template #noImage>
      <img *ngIf="child.gender === GenderEnum.Male" src="assets/img/iconsDefault/boy.svg" alt="boy" width="64px" />
      <img *ngIf="child.gender === GenderEnum.Female" src="assets/img/iconsDefault/girl.svg" alt="girl" width="64px" />
    </ng-template>
    <ngx-spinner name="primary" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-pulse" name="preview">
      {{ t('shared/loading') }}
    </ngx-spinner>
  </div>

  <div class="left">
    <div class="child-info">
      <h2 class="fw-bold">
        {{ child.nickname ? child.nickname : child.firstName }}
      </h2>
      <ng-container *ngIf="child.gender === GenderEnum.Male">{{ t('child/boy') }}</ng-container>
      <ng-container *ngIf="child.gender === GenderEnum.Female">{{ t('child/girl') }}</ng-container>
      <ng-container *ngIf="+child.age === 1">, {{ child.age }} {{ t('child/year') }}</ng-container>
      <ng-container *ngIf="+child.age > 1 && +child.age <= 4">, {{ child.age }} {{ t('child/twoToFourYears') }}</ng-container>
      <ng-container *ngIf="+child.age === 0 || +child.age > 4">, {{ child.age }} {{ t('child/years') }}</ng-container>
    </div>

    <div class="group" *ngIf="child.childGroups?.length > 0">
      <button class="btn btn-lg btn-outline-primary" *ngFor="let group of child.childGroups; let i = index">
        {{ childGroups[i] }}
      </button>
    </div>
  </div>
</ng-container>

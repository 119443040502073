import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Gender38aEnum } from '@isophi/mng-api';

@Component({
  selector: 'app-group-detail-card',
  templateUrl: './group-detail-card.component.html',
  styleUrls: ['./group-detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupDetailCardComponent {
  // Types: ChildGroupProfile || ChildAttendance
  @Input() child: any;

  @Input() isChildMarked: boolean;

  @Input() disabled: boolean;

  // Types: ChildGroupProfile || ChildAttendance
  @Output() childClick = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender38aEnum;

  onClick(e: Event) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.childClick.emit(this.child);
      this.isChildMarked = !this.isChildMarked;
    }
  }
}

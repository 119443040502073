import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@isophi/core-ui';
import { CloseRelativeDetail, CloseRelativesService, CloseRelativeUpdate } from '@isophi/mng-api';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../../core/router/router-link.factory';
import { HelperService } from '../../../../../core/services/helper.service';
import { CloseRelativeFormComponent } from '../../../components/close-relative-form/close-relative-form.component';

interface CloseRelativeEditFormData {
  data: CloseRelativeUpdate;
  navigateBack: boolean;
}

@Component({
  selector: 'app-edit-close-relative',
  templateUrl: './edit-close-relative.component.html',
  styleUrls: ['./edit-close-relative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCloseRelativeComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CloseRelativeFormComponent) closeRelativeFormComponent: CloseRelativeFormComponent;

  closeRelative: CloseRelativeDetail;

  private _subs: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    public links: RouterLinkFactory,
    private spinner: NgxSpinnerService,
    private closeRelativeService: CloseRelativesService,
    private translocoService: TranslocoService,
    private toastr: ToastService,
    private route: ActivatedRoute
  ) {
    this.helperService.setTitle(location.pathname);
  }

  ngAfterViewInit(): void {
    this.spinner.show();
    this._subs.push(
      this.route.paramMap.subscribe((params) => {
        const id = params.get('closeRelativeId');
        this._subs.push(
          this.closeRelativeService
            .closeRelativesRetrieve(id)
            .pipe(
              finalize(() => {
                this.spinner.hide();
              })
            )
            .subscribe((closeRelativeData: CloseRelativeDetail) => {
              this.closeRelative = closeRelativeData;
              this.closeRelativeFormComponent.setFormData(closeRelativeData);
              this.helperService.smallTextTitle$.next(closeRelativeData.firstName + ' ' + closeRelativeData.lastName);
            })
        );
      })
    );
  }

  editCloseRelative(closeRelativeEditForm: CloseRelativeEditFormData): void {
    this.spinner.show();

    this.closeRelativeService
      .closeRelativesUpdate(closeRelativeEditForm.data, this.closeRelative.id)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate('closeRelative/editCloseRelativeSuccess'),
            this.translocoService.translate('closeRelative/edit')
          );

          closeRelativeEditForm.navigateBack
            ? this.closeRelativeFormComponent.navigateBack()
            : this.closeRelativeFormComponent.resetCloseRelativeForm();
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('closeRelative/editCloseRelativeFailed'),
            this.translocoService.translate('closeRelative/edit')
          );
        },
      });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
    this.helperService.clearSmallTextTitle();
  }
}

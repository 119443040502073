import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from '@isophi/core-ui';
import { ChildGroupList, GradeBookRecordRead } from '@isophi/mng-api';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';

@Component({
  selector: 'app-gradebook-card',
  templateUrl: './gradebook-card.component.html',
  styleUrls: ['./gradebook-card.component.scss'],
})
export class GradebookCardComponent {
  @Input() group: ChildGroupList;

  @Input() gradebook: GradeBookRecordRead;

  constructor(public icons: IconService, public links: RouterLinkFactory, private router: Router) {}

  navigateToGradebookForm(): void {
    if (this.gradebook) {
      this.router.navigate(this.links.editGradebook(this.gradebook.id));
    } else {
      this.router.navigate(this.links.createGradebook(), {
        queryParams: {
          groupUuid: this.group?.uuid,
        },
      });
    }
  }
}

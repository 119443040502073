/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PatchedAnnouncementRead { 
    readonly id?: string;
    title?: string;
    content?: string;
    image?: string;
    imageThumbnail?: string;
    readonly created?: Date;
    createdByUserId?: number;
    createdByUserFullName?: string;
    readonly modified?: Date;
    modifiedByUserId?: number;
    modifiedByUserFullName?: string;
    wholeKindergarten?: boolean;
    readonly groupUuids?: Array<string>;
    isInternal?: boolean;
}
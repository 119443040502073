/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `without_distinction` - Mateřská škola bez rozlišení * `company_kindergarten` - Firemní mateřská škola * `forest_kindergarten` - Lesní mateřská škola * `kindergarten_at_an_institutional_education_facility` - Mateřská škola při zařízení pro ústavní výchovu nebo ochrannou výchovu
 */
export type DistinctionEnum = 'without_distinction' | 'company_kindergarten' | 'forest_kindergarten' | 'kindergarten_at_an_institutional_education_facility';

export const DistinctionEnum = {
    WithoutDistinction: 'without_distinction' as DistinctionEnum,
    CompanyKindergarten: 'company_kindergarten' as DistinctionEnum,
    ForestKindergarten: 'forest_kindergarten' as DistinctionEnum,
    KindergartenAtAnInstitutionalEducationFacility: 'kindergarten_at_an_institutional_education_facility' as DistinctionEnum
};
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `Celodenní` - Full Day * `Polodenní` - Half Day * `Internátní` - Boarding School
 */
export type OperationTypeEnum = 'Celodenní' | 'Polodenní' | 'Internátní';

export const OperationTypeEnum = {
    Celodenn: 'Celodenní' as OperationTypeEnum,
    Polodenn: 'Polodenní' as OperationTypeEnum,
    Interntn: 'Internátní' as OperationTypeEnum
};
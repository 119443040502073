<div class="d-flex ms-auto align-items-center" *transloco="let t">
  <div class="dropdown">
    <div *ngIf="user" class="user-info" type="button" id="user" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img class="user-avatar" src="assets/img/iconsDefault/user.svg" alt="User profile image" />
      <div class="user-details mobile-hidden">
        <div class="user-name">{{ user.fullName }}</div>
        <div class="user-role">{{ user.role }}</div>
      </div>
      <div class="dropdown-icon mobile-hidden">
        <i [class]="icons.chevronDown"></i>
      </div>
    </div>

    <!-- Dropdown menu -->
    <div id="dropdownUser" class="dropdown-menu dropdown-notification" aria-labelledby="user">
      <ul>
        <div class="dropdown-item disabled">{{ user.email }}</div>
        <hr />
        <a [routerLink]="links.changePassword()" class="dropdown-item">{{ t('user/passwordChange') }}</a>
        <a *ngIf="!isProduction" href="#" class="dropdown-item">{{ t('shared/editData') }}</a>
        <a (click)="logout()" class="dropdown-item">{{ t('shared/logOut') }}</a>
      </ul>
    </div>
  </div>
</div>

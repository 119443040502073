/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AbsenceEnum } from './absenceEnum';
import { AbsenceReasonEnum } from './absenceReasonEnum';
import { BlankEnum } from './blankEnum';
import { NullEnum } from './nullEnum';

export interface CurrentChildAbsence { 
    readonly created: Date;
    createdBy: string;
    dateFrom: string;
    dateTo: string;
    absenceReason?: AbsenceReasonEnum | BlankEnum | NullEnum;
    absence: AbsenceEnum;
    readonly id: string;
}
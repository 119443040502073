/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `standard` - Řádné vzdělávání * `after_interrupted_education` - Řádné vzdělávání po přerušení vzdělávání * `repeating_year_for_academic_reasons` - Opakování ročníku z prospěchových důvodů * `transfer_to_higher_year` - Přeřazení do vyššího ročníku (z důvodu mimořádného nadání) * `placement_in_lower_year` - Zařazení do nižšího ročníku (bez opakování) * `interruption_of_education` - Přerušení vzdělávání * `education_terminated` - Vzdělávání ukončeno * `repeating_year_for_health_reasons` - Opakování ročníku ze zdravotních důvodů * `full_day_education_in_kindergarten` - Celodenní vzdělávání v MŠ * `boarding_education_in_kindergarten` - Internátní vzdělávání v MŠ * `half_day_education_in_kindergarten` - Polodenní vzdělávání v MŠ * `education_in_ps_pt` - Vzdělávání v PS/PT * `education_in_kindergarten_ps_pt_terminated` - Vzdělávání v MŠ/PT/PS ukončeno
 */
export type EducationCourseChoiceEnum = 'standard' | 'after_interrupted_education' | 'repeating_year_for_academic_reasons' | 'transfer_to_higher_year' | 'placement_in_lower_year' | 'interruption_of_education' | 'education_terminated' | 'repeating_year_for_health_reasons' | 'full_day_education_in_kindergarten' | 'boarding_education_in_kindergarten' | 'half_day_education_in_kindergarten' | 'education_in_ps_pt' | 'education_in_kindergarten_ps_pt_terminated';

export const EducationCourseChoiceEnum = {
    Standard: 'standard' as EducationCourseChoiceEnum,
    AfterInterruptedEducation: 'after_interrupted_education' as EducationCourseChoiceEnum,
    RepeatingYearForAcademicReasons: 'repeating_year_for_academic_reasons' as EducationCourseChoiceEnum,
    TransferToHigherYear: 'transfer_to_higher_year' as EducationCourseChoiceEnum,
    PlacementInLowerYear: 'placement_in_lower_year' as EducationCourseChoiceEnum,
    InterruptionOfEducation: 'interruption_of_education' as EducationCourseChoiceEnum,
    EducationTerminated: 'education_terminated' as EducationCourseChoiceEnum,
    RepeatingYearForHealthReasons: 'repeating_year_for_health_reasons' as EducationCourseChoiceEnum,
    FullDayEducationInKindergarten: 'full_day_education_in_kindergarten' as EducationCourseChoiceEnum,
    BoardingEducationInKindergarten: 'boarding_education_in_kindergarten' as EducationCourseChoiceEnum,
    HalfDayEducationInKindergarten: 'half_day_education_in_kindergarten' as EducationCourseChoiceEnum,
    EducationInPsPt: 'education_in_ps_pt' as EducationCourseChoiceEnum,
    EducationInKindergartenPsPtTerminated: 'education_in_kindergarten_ps_pt_terminated' as EducationCourseChoiceEnum
};
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GradeBookRecordCreate } from '../model/gradeBookRecordCreate';
import { GradeBookRecordRead } from '../model/gradeBookRecordRead';
import { GradeBookRecordUpdate } from '../model/gradeBookRecordUpdate';
import { PaginatedGradeBookRecordReadList } from '../model/paginatedGradeBookRecordReadList';
import { PatchedGradeBookRecordRead } from '../model/patchedGradeBookRecordRead';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GradeBookService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookCreate(body: GradeBookRecordCreate, observe?: 'body', reportProgress?: boolean): Observable<GradeBookRecordRead>;
    public gradeBookCreate(body: GradeBookRecordCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GradeBookRecordRead>>;
    public gradeBookCreate(body: GradeBookRecordCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GradeBookRecordRead>>;
    public gradeBookCreate(body: GradeBookRecordCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gradeBookCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GradeBookRecordRead>('post',`${this.basePath}/grade-book/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param id A UUID string identifying this grade book record.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookDestroy(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public gradeBookDestroy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public gradeBookDestroy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public gradeBookDestroy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gradeBookDestroy.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/grade-book/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param dateFrom 
     * @param dateTo 
     * @param groupUuid 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @param orderBy Řazení  * &#x60;date&#x60; - Date * &#x60;-date&#x60; - Date (sestupně)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookList(dateFrom?: string, dateTo?: string, groupUuid?: string, limit?: number, offset?: number, orderBy?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PaginatedGradeBookRecordReadList>;
    public gradeBookList(dateFrom?: string, dateTo?: string, groupUuid?: string, limit?: number, offset?: number, orderBy?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedGradeBookRecordReadList>>;
    public gradeBookList(dateFrom?: string, dateTo?: string, groupUuid?: string, limit?: number, offset?: number, orderBy?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedGradeBookRecordReadList>>;
    public gradeBookList(dateFrom?: string, dateTo?: string, groupUuid?: string, limit?: number, offset?: number, orderBy?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('date_from', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('date_to', <any>dateTo);
        }
        if (groupUuid !== undefined && groupUuid !== null) {
            queryParameters = queryParameters.set('group_uuid', <any>groupUuid);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (orderBy) {
            queryParameters = queryParameters.set('order_by', orderBy.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedGradeBookRecordReadList>('get',`${this.basePath}/grade-book/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param id A UUID string identifying this grade book record.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookPartialUpdate(id: string, body?: PatchedGradeBookRecordRead, observe?: 'body', reportProgress?: boolean): Observable<GradeBookRecordRead>;
    public gradeBookPartialUpdate(id: string, body?: PatchedGradeBookRecordRead, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GradeBookRecordRead>>;
    public gradeBookPartialUpdate(id: string, body?: PatchedGradeBookRecordRead, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GradeBookRecordRead>>;
    public gradeBookPartialUpdate(id: string, body?: PatchedGradeBookRecordRead, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gradeBookPartialUpdate.');
        }


        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GradeBookRecordRead>('patch',`${this.basePath}/grade-book/${encodeURIComponent(String(id))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param id A UUID string identifying this grade book record.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookRetrieve(id: string, observe?: 'body', reportProgress?: boolean): Observable<GradeBookRecordRead>;
    public gradeBookRetrieve(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GradeBookRecordRead>>;
    public gradeBookRetrieve(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GradeBookRecordRead>>;
    public gradeBookRetrieve(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gradeBookRetrieve.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GradeBookRecordRead>('get',`${this.basePath}/grade-book/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Mixin for django rest framework (djangorestframework) view sets to define basic fields for iSophi security.  It should be used together with ISophiEcosystemGranted permission class.  Class Attributes ---------------- isophi_permission_application_groups: List[str]     Check if application (where token was generated) belongs to required app groups.     Groups for applications are set in auth-portal,     typically group is \&quot;isophi-portal\&quot; &#x3D; BE applications managed by isophi,     but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_user_groups: List[str]     Check if user (owner of token) belongs to required user groups.     Groups for users are set in auth-portal,     typically groups are \&quot;admin\&quot;/\&quot;super-admin\&quot;, but there can be others.     Multiple required groups are joined by OR (1 group is enough to pass check). isophi_permission_query: List[enums.PermissionField]     List all data you want to load from token,     all this data are then available in field isophi_permission_data.     For usable fields and their meaning see enums.PermissionField. isophi_required_api_client_type: List[enums.ApiClientType]     Check if client with token is required type (isophi BE app, FE app, ...)     Multiple required types are joined by OR (1 type is enough to pass check). isophi_required_api_client_role: List[enums.ApiClientRole]     Check if client is required type (isophi admin, teacher, parent ...)     Multiple required roles are joined by OR (1 role is enough to pass check). isophi_permission_data: dict     Data loaded from token by specification in isophi_permission_query field. isophi_no_permission_check: dict     Set view_names, which are not checked for permission.     Set this field in format: {&#x27;GET&#x27;: [&#x27;view_name&#x27;, ...], &#x27;POST&#x27;: [], ...}
     * @param body 
     * @param id A UUID string identifying this grade book record.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gradeBookUpdate(body: GradeBookRecordUpdate, id: string, observe?: 'body', reportProgress?: boolean): Observable<GradeBookRecordRead>;
    public gradeBookUpdate(body: GradeBookRecordUpdate, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GradeBookRecordRead>>;
    public gradeBookUpdate(body: GradeBookRecordUpdate, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GradeBookRecordRead>>;
    public gradeBookUpdate(body: GradeBookRecordUpdate, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gradeBookUpdate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling gradeBookUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GradeBookRecordRead>('put',`${this.basePath}/grade-book/${encodeURIComponent(String(id))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

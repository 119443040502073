import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@isophi/core-legacy';
import { map, Observable } from 'rxjs';

import { toCamelcaseFun, toSnakecaseFun } from '../core/utils/object.utils';

const EXCLUDED_URLS = ['/token/', '/logged-user/', '/licenses/'];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = req.headers;

    if (!headers.get('Authorization') && this.authService.accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + this.authService.accessToken);
    }

    const body = req.body !== null && typeof req.body === 'object' && !(req.body instanceof FormData) ? toSnakecaseFun(req.body) : req.body;

    return next.handle(req.clone({ headers, body })).pipe(map(this.convertResponse));
  }

  convertResponse = (event: HttpEvent<any>) => {
    if (event instanceof HttpResponse && event.body && !this.hasExcludedUrl(event.url)) {
      const contentType = event.headers.get('content-type');
      if (contentType && contentType.startsWith('application/zip')) {
        return event;
      }

      return event.clone({ body: toCamelcaseFun(event.body) });
    }
    return event;
  };

  hasExcludedUrl(url: string) {
    return EXCLUDED_URLS.some((exclUrl) => url.includes(exclUrl));
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AidPurchaseFormEnum } from './aidPurchaseFormEnum';
import { AttendanceEndCodeEnum } from './attendanceEndCodeEnum';
import { AttendanceFulfillmentMethodEnum } from './attendanceFulfillmentMethodEnum';
import { AttendanceStartCodeEnum } from './attendanceStartCodeEnum';
import { BlankEnum } from './blankEnum';
import { ChildProfileAddressCreate } from './childProfileAddressCreate';
import { DisabilityTypeEnum } from './disabilityTypeEnum';
import { DisadvantagesEnum } from './disadvantagesEnum';
import { EducationCourseChoiceEnum } from './educationCourseChoiceEnum';
import { Gender785Enum } from './gender785Enum';
import { InsuranceCompanyEnum } from './insuranceCompanyEnum';
import { LevelOfTalentEnum } from './levelOfTalentEnum';
import { MeasureLevelEnum } from './measureLevelEnum';
import { NationalityEnum } from './nationalityEnum';
import { NationalityQualifierEnum } from './nationalityQualifierEnum';
import { NullEnum } from './nullEnum';
import { SocialCategoryDisadvantageEnum } from './socialCategoryDisadvantageEnum';

export interface ChildProfileCreate { 
    firstName: string;
    lastName: string;
    nickname?: string;
    idCode?: string;
    gender: Gender785Enum;
    birthDate: string;
    birthPlace?: string;
    birthNumber?: string;
    nationalityQualifier?: NationalityQualifierEnum | NullEnum;
    nationality?: NationalityEnum | BlankEnum | NullEnum;
    insuranceCompany?: InsuranceCompanyEnum | NullEnum;
    gdprConsent?: boolean;
    importantNote?: string;
    attendanceStart?: string;
    attendanceEnd?: string;
    attendanceStartCode?: AttendanceStartCodeEnum | NullEnum;
    attendanceEndCode?: AttendanceEndCodeEnum | NullEnum;
    attendanceFulfillmentMethod?: AttendanceFulfillmentMethodEnum | NullEnum;
    educationCourseChoice?: EducationCourseChoiceEnum | NullEnum;
    socialCategoryDisadvantage?: SocialCategoryDisadvantageEnum | NullEnum;
    isHealthDisadvantaged?: boolean;
    disadvantages?: Array<DisadvantagesEnum | NullEnum>;
    measureLevel?: MeasureLevelEnum | NullEnum;
    ignoranceOfLanguage?: boolean;
    levelOfTalent?: LevelOfTalentEnum | BlankEnum | NullEnum;
    primaryClass?: string;
    addresses?: Array<ChildProfileAddressCreate>;
    disabilityType?: DisabilityTypeEnum | BlankEnum | NullEnum;
    hasHeavyDisability?: boolean;
    izoSpz?: string;
    spzRecommendationIssued?: string;
    spzRecommendationDue?: string;
    supportingMeasures?: number;
    aidPurchaseForm?: AidPurchaseFormEnum | BlankEnum | NullEnum;
    isFundingRequired?: boolean;
    supportingMeasuresStart?: string;
    supportingMeasuresEnd?: string;
    setGroups?: Array<string>;
}
<div class="announcement-card-detail">
  <p>
    <span>{{ announcement?.createdByUserFullName }}</span>
    <br *ngIf="announcement?.createdByUserFullName" />
    <span>{{ announcement?.created | date: 'dd. M. v HH:mm ' }}</span>
  </p>
  <h4>{{ announcement?.title }}</h4>
  <p [innerHTML]="content"></p>
  <div class="d-flex align-items-center justify-content-center">
    <img
      *ngIf="announcement?.image || announcement?.imageThumbnail; else noImage"
      [src]="announcement?.image ? announcement?.image : announcement?.imageThumbnail"
      alt="Image"
    />
  </div>
  <ng-template #noImage>
    <span class="no-image"></span>
  </ng-template>
</div>

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `without_influence` - bez vlivu kulturního prostředí nebo jiných životních podmínek * `cultural_environment` - SVP vyplývají převážně z kulturního prostředí žáka * `life_conditions` - SVP vyplývají převážně z dopadu jiných životních podmínek žáka do vzdělávání * `multiple_factors` - SVP vyplývají z více faktorů uvedených pod body K a Z
 */
export type SocialCategoryDisadvantageEnum = 'without_influence' | 'cultural_environment' | 'life_conditions' | 'multiple_factors';

export const SocialCategoryDisadvantageEnum = {
    WithoutInfluence: 'without_influence' as SocialCategoryDisadvantageEnum,
    CulturalEnvironment: 'cultural_environment' as SocialCategoryDisadvantageEnum,
    LifeConditions: 'life_conditions' as SocialCategoryDisadvantageEnum,
    MultipleFactors: 'multiple_factors' as SocialCategoryDisadvantageEnum
};
/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `Bez postižení` - Without Disability * `Mentální postižení` - Mental Disability * `Sluchové postižení` - Hearing Disability * `Zrakové postižení` - Visual Disability * `Vady reči` - Speech Disability * `Tělesné postižení` - Physical Disability * `Vývojové poruchy` - Developmental Disability * `Souběžné postižení více vadami` - Multiple Disabilities * `Autismus` - Autism * `Žák v zařízení pro výkon ústavní-ochranné výchovy` - Institutional Education * `Těžké mentální postižení` - Heavy Mental Disability
 */
export type DisabilityTypeEnum = 'Bez postižení' | 'Mentální postižení' | 'Sluchové postižení' | 'Zrakové postižení' | 'Vady reči' | 'Tělesné postižení' | 'Vývojové poruchy' | 'Souběžné postižení více vadami' | 'Autismus' | 'Žák v zařízení pro výkon ústavní-ochranné výchovy' | 'Těžké mentální postižení';

export const DisabilityTypeEnum = {
    BezPostien: 'Bez postižení' as DisabilityTypeEnum,
    MentlnPostien: 'Mentální postižení' as DisabilityTypeEnum,
    SluchovPostien: 'Sluchové postižení' as DisabilityTypeEnum,
    ZrakovPostien: 'Zrakové postižení' as DisabilityTypeEnum,
    VadyRei: 'Vady reči' as DisabilityTypeEnum,
    TlesnPostien: 'Tělesné postižení' as DisabilityTypeEnum,
    VvojovPoruchy: 'Vývojové poruchy' as DisabilityTypeEnum,
    SoubnPostienVceVadami: 'Souběžné postižení více vadami' as DisabilityTypeEnum,
    Autismus: 'Autismus' as DisabilityTypeEnum,
    KVZazenProVkonStavnOchrannVchovy: 'Žák v zařízení pro výkon ústavní-ochranné výchovy' as DisabilityTypeEnum,
    TkMentlnPostien: 'Těžké mentální postižení' as DisabilityTypeEnum
};
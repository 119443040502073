/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `first_level` - 1. stupeň * `second_level` - 2. stupeň * `third_level` - 3. stupeň * `fourth_level` - 4. stupeň * `fifth_level` - 5. stupeň
 */
export type MeasureLevelEnum = 'first_level' | 'second_level' | 'third_level' | 'fourth_level' | 'fifth_level';

export const MeasureLevelEnum = {
    FirstLevel: 'first_level' as MeasureLevelEnum,
    SecondLevel: 'second_level' as MeasureLevelEnum,
    ThirdLevel: 'third_level' as MeasureLevelEnum,
    FourthLevel: 'fourth_level' as MeasureLevelEnum,
    FifthLevel: 'fifth_level' as MeasureLevelEnum
};
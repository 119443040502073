import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IconService } from '@isophi/core-ui';
import { BehaviorSubject, filter, Subscription } from 'rxjs';

import { environment } from '../../../../../src/environments/environment';
import { MenuTypeEnum } from '../../../core/enums/menu-type.enum';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy {
  showBackButton = false;

  currentUrl = '';

  data;

  get title$(): BehaviorSubject<string> {
    return this.helperService.titleChanged$;
  }

  get smallTextTitle$(): BehaviorSubject<string> {
    return this.helperService.smallTextTitle$;
  }

  get showSubMenu$(): BehaviorSubject<{ show: boolean; menuType: MenuTypeEnum }> {
    this.data = this.helperService.data;
    return this.helperService.showSubMenu$;
  }

  isProduction = environment.production;

  private _subs: Subscription[] = [];

  constructor(public iconService: IconService, private helperService: HelperService, private router: Router) {
    // TODO: this doesn't work when paste URL directly to browser
    this._subs.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;

        if (event.url.includes('attendance') || event.url.includes('user') || event.url.includes('settings')) {
          this.showBackButton = event.url.split('/').slice(1).length > 2;
        } else {
          this.showBackButton = event.url.split('/').length > 2;
        }
      })
    );
  }

  back(): void {
    // Split the URL into segments
    const segments = this.currentUrl.split('/').filter((segment) => segment);

    // Remove the last segment to navigate one level back
    segments.pop(); // Removes the last segment, e.g., the ID

    if (
      segments.includes('edit-close-relative') ||
      ((segments.includes('gradebook') || segments.includes('announcement') || segments.includes('holidays-dates')) &&
        this.currentUrl.includes('edit')) ||
      segments.includes('edit-user')
    ) {
      segments.pop();
    }

    // Construct the new URL
    const newUrl = '/' + segments.join('/');

    // Use navigateByUrl to navigate
    this.router.navigateByUrl(newUrl);
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}

<div class="basic-info-detail" *transloco="let t">
  <app-child-detail-alert-info
    *ngIf="
      child.importantNote ||
      child.isHealthDisadvantaged ||
      (child.levelOfTalent && child.levelOfTalent !== LevelOfTalentEnum.BezIdentifikovanhoNadnNeboMimodnhoNadn)
    "
    [child]="child"
  ></app-child-detail-alert-info>

  <form class="flex-form">
    <div class="row no-gutters">
      <div class="form-group">
        <label for="birthDate">{{ t('child/birthDate') }}</label>
        <div id="birthDate" class="value">{{ child.birthDate | date: 'd. M. y' }}</div>
      </div>
      <div class="form-group">
        <label for="birthNumber">{{ t('child/birthNumber') }}</label>
        <div id="birthNumber" class="value">{{ child.birthNumber }}</div>
      </div>
      <div class="form-group">
        <label for="idCode">{{ t('child/idCode') }}</label>
        <div id="idCode" class="value">{{ child.idCode }}</div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="birthPlace"> {{ t('child/birthPlace') }}</label>
        <div id="birthPlace" class="value">{{ child.birthPlace }}</div>
      </div>
      <div class="form-group">
        <label for="nationalityQualifier">{{ t('child/nationalityQualifier') }}</label>
        <div id="nationalityQualifier" class="value">
          {{ child.nationalityQualifier | transformEnumToReadableString: nationalityQualifier }}
        </div>
      </div>
      <div class="form-group">
        <label for="nationality">{{ t('child/nationality') }}</label>
        <div id="nationality" class="value">{{ child.nationality | transformEnumToReadableString: nationality }}</div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="gdprConsent">{{ t('child/gdprConsent') }}</label>
        <i *ngIf="child.gdprConsent" [class]="iconsService.checkCircle + ' check'"></i>
        <i *ngIf="!child.gdprConsent" [class]="iconsService.xCircle + ' warning'"></i>
      </div>
      <div class="form-group">
        <label for="catchment">{{ t('child/withinSchoolDistrict') }}</label>
        <i *ngIf="catchment" [class]="iconsService.checkCircle + ' check'"></i>
        <i *ngIf="!catchment" [class]="iconsService.xCircle + ' warning'"></i>
      </div>
      <div class="form-group empty"></div>
    </div>

    <div class="row no-gutters">
      <div class="form-group">
        <label for="streetAndHouseNumber">{{ t('child/permanentAddress') }}</label>
        <div id="streetAndHouseNumber" class="value">{{ permanentAddress }}</div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="temporaryAddress">
      <div class="form-group">
        <label for="temporaryAddress">{{ t('child/temporaryAddress') }}</label>
        <div id="temporaryAddress" class="value">{{ temporaryAddress }}</div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="contactAddress">
      <div class="form-group">
        <label for="contactAddress">{{ t('child/contactAddress') }}</label>
        <div id="contactAddress" class="value">{{ contactAddress }}</div>
      </div>
    </div>
  </form>
</div>

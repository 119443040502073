<tr *transloco="let t">
  <td class="d-flex align-items-center">
    <input class="form-check-input" type="checkbox" (click)="$event.stopPropagation()" (change)="onClick()" [checked]="isChildMarked" />
    <div *ngIf="child.thumbnail; else profileImage" class="preview me-4 rounded-circle">
      <img [src]="child.thumbnail" alt="profile image" />
    </div>
    <ng-template #profileImage>
      <div *ngIf="child.profileImage; else noImage" class="preview me-4 rounded-circle">
        <img [src]="child.profileImage" alt="profile image" />
      </div>
    </ng-template>
    <ng-template #noImage>
      <img *ngIf="child.gender === GenderEnum.Male" src="assets/img/iconsDefault/boy.svg" alt="boy" class="me-4" />
      <img *ngIf="child.gender === GenderEnum.Female" src="assets/img/iconsDefault/girl.svg" alt="girl" class="me-4" />
    </ng-template>
    <span class="fw-bold me-1">{{ child.lastName }}</span> {{ child.firstName }}
    <!-- <i [class]="icons.warning"></i> -->
    <app-context-menu
      class="ms-auto show-mobile"
      [menuType]="MenuTypeEnum.CHILD"
      [data]="child"
      (refreshChildren)="refreshChildren.emit()"
    ></app-context-menu>
  </td>
  <td>
    {{ child.age }}
    <ng-container *ngIf="+child.age === 1">{{ t('child/year') }}</ng-container>
    <ng-container *ngIf="+child.age > 1 && +child.age <= 4">{{ t('child/twoToFourYears') }}</ng-container>
    <ng-container *ngIf="+child.age === 0 || +child.age > 4">{{ t('child/years') }}</ng-container>
  </td>
  <td class="me-lg-4 m-0">
    {{ child.primaryClassName }}
    <ng-container *ngIf="child?.secondaryGroupNames?.length > 0">
      <br *ngIf="child.primaryClassName" />
      {{ secondaryClasses }}
    </ng-container>
  </td>
  <td>
    {{ child.mainContactFullName }}
    <img
      *ngIf="child?.mainContactInvitationStatus && child?.mainContactInvitationStatus === MainContactInvitationStatusEnum.Invited"
      class="r-icon"
      src="assets/img/logo/iSophiR-grey.svg"
      alt="iSophiR grey"
      placement="top"
      [ngbTooltip]="t('child/infoGreyR')"
    />
    <img
      *ngIf="
        child?.mainContactInvitationStatus && child?.mainContactInvitationStatus === MainContactInvitationStatusEnum.AcceptedInvitation
      "
      class="r-icon"
      src="assets/img/logo/iSophiR-color.svg"
      alt="iSophiR pink"
      placement="top"
      [ngbTooltip]="t('child/infoPinkR')"
    />
    <div>
      <a *ngIf="child.mainContactPhone" (click)="$event.stopPropagation()" href="tel:{{ child.mainContactPhone }}"
        ><i [class]="icons.phone"></i
      ></a>
      <a *ngIf="child.mainContactEmail" (click)="$event.stopPropagation()" href="mailto:{{ child.mainContactEmail }}"
        ><i [class]="icons.mail"></i
      ></a>
    </div>
  </td>
  <td>{{ child.idCode }}</td>
  <td>
    <app-context-menu [menuType]="MenuTypeEnum.CHILD" [data]="child" (refreshData)="refreshChildren.emit()"></app-context-menu>
  </td>
</tr>

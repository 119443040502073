import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IStorage, LocalStorage } from '@isophi/core-legacy';
import {
  AttendanceService,
  ChildAttendanceCount,
  ChildGroupList,
  ClassesService,
  GradeBookRecordRead,
  GradeBookService,
  PaginatedChildGroupListList,
  PaginatedGradeBookRecordReadList,
} from '@isophi/mng-api';
import { formatDate } from 'date-fns';
import { Observable, tap } from 'rxjs';

import { FORMAT_DATE } from '../../core/constants/constant';
import { RouterLinkFactory } from '../../core/router/router-link.factory';
import { HelperService } from '../../core/services/helper.service';
import { LocalStorageConstService } from '../../shared/services/local-storage-const.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  groups$: Observable<PaginatedChildGroupListList>;

  group: ChildGroupList;

  childAttendanceCount$: Observable<ChildAttendanceCount>;

  gradeBookList$: Observable<PaginatedGradeBookRecordReadList>;

  gradebook: GradeBookRecordRead;

  private storage: IStorage;

  filterCriteria: any;

  constructor(
    public links: RouterLinkFactory,
    private helperService: HelperService,
    private attendanceService: AttendanceService,
    private gradeBookService: GradeBookService,
    private classesService: ClassesService,
    private lSConstService: LocalStorageConstService
  ) {
    this.storage = new LocalStorage();
    this.helperService.setTitle(location.pathname);
    let group = '';

    if (this.storage.hasItem(this.lSConstService.childrenFilter)) {
      let storageData: any = this.storage.getItem(this.lSConstService.childrenFilter);
      if (storageData && storageData !== 'undefined') {
        storageData = JSON.parse(storageData);
        group = storageData.group;
      }
    }

    this.initViewData(group);
  }

  initViewData(group: string): void {
    this.groups$ = this.classesService.classesList(false, 1000).pipe(
      tap({
        next: (groups) => {
          this.group = group ? groups.results.find((val) => val.uuid === group) : groups.results[0];

          this.childAttendanceCount$ =
            this.group?.uuid && this.attendanceService.attendanceCurrentClassAttendanceCountsRetrieve(this.group.uuid);

          const date = formatDate(new Date(), FORMAT_DATE, {});
          this.gradeBookList$ =
            this.group?.uuid &&
            this.gradeBookService.gradeBookList(date, date, this.group.uuid).pipe(
              tap({
                next: (val) => {
                  if (val.results.length > 0) {
                    this.gradebook = val.results[0];
                  } else {
                    this.gradebook = null;
                  }
                },
              })
            );
        },
      })
    );
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `common_class` - Běžná třída/studijní skupina * `class_for_mild_mental_disability` - Třída pro lehce mentálně postižené * `class_for_moderate_mental_disability` - Třída pro středně mentálně postižené * `class_for_severe_mental_disability` - Třída pro těžce mentálně postižené * `class_for_moderate_hearing_impairment` - Třída/studijní skupina pro středně těžce sluchově postižené * `class_for_severe_hearing_impairment` - Třída/studijní skupina pro těžce sluchově postižené * `class_for_moderate_visual_impairment` - Třída/studijní skupina pro středně těžce zrakově postižené * `class_for_severe_visual_impairment` - Třída/studijní skupina pro těžce zrakově postižené * `class_for_speech_defects` - Třída pro žáky s vadami řeči * `class_for_severe_speech_defects` - Třída pro žáky s těžkou vadou řeči * `class_for_physical_disability` - Třída/studijní skupina pro tělesně postižené * `class_for_severe_physical_disability` - Třída pro žáky s těžkým tělesným postižením * `class_for_behavioral_disorders` - Třída pro žáky s vývojovými poruchami chování * `class_for_severe_behavioral_disorders` - Třída pro žáky s těžkými poruchami chování * `class_for_learning_disorders` - Třída pro žáky s vývojovými poruchami učení * `class_for_multi_disability` - Třída pro žáky se souběžným postižením více vadami * `class_for_deaf_blind` - Třída pro žáky hluchoslepé * `class_for_autism_spectrum_disorder` - Třída/studijní skupina pro autistické žáky/studenty * `class_for_gifted` - Třída pro mimořádně nadané * `class_for_dd` - Třída pro žáky v DD se školou, VÚ, DgÚ
 */
export type ClassTypeEnum = 'common_class' | 'class_for_mild_mental_disability' | 'class_for_moderate_mental_disability' | 'class_for_severe_mental_disability' | 'class_for_moderate_hearing_impairment' | 'class_for_severe_hearing_impairment' | 'class_for_moderate_visual_impairment' | 'class_for_severe_visual_impairment' | 'class_for_speech_defects' | 'class_for_severe_speech_defects' | 'class_for_physical_disability' | 'class_for_severe_physical_disability' | 'class_for_behavioral_disorders' | 'class_for_severe_behavioral_disorders' | 'class_for_learning_disorders' | 'class_for_multi_disability' | 'class_for_deaf_blind' | 'class_for_autism_spectrum_disorder' | 'class_for_gifted' | 'class_for_dd';

export const ClassTypeEnum = {
    CommonClass: 'common_class' as ClassTypeEnum,
    ClassForMildMentalDisability: 'class_for_mild_mental_disability' as ClassTypeEnum,
    ClassForModerateMentalDisability: 'class_for_moderate_mental_disability' as ClassTypeEnum,
    ClassForSevereMentalDisability: 'class_for_severe_mental_disability' as ClassTypeEnum,
    ClassForModerateHearingImpairment: 'class_for_moderate_hearing_impairment' as ClassTypeEnum,
    ClassForSevereHearingImpairment: 'class_for_severe_hearing_impairment' as ClassTypeEnum,
    ClassForModerateVisualImpairment: 'class_for_moderate_visual_impairment' as ClassTypeEnum,
    ClassForSevereVisualImpairment: 'class_for_severe_visual_impairment' as ClassTypeEnum,
    ClassForSpeechDefects: 'class_for_speech_defects' as ClassTypeEnum,
    ClassForSevereSpeechDefects: 'class_for_severe_speech_defects' as ClassTypeEnum,
    ClassForPhysicalDisability: 'class_for_physical_disability' as ClassTypeEnum,
    ClassForSeverePhysicalDisability: 'class_for_severe_physical_disability' as ClassTypeEnum,
    ClassForBehavioralDisorders: 'class_for_behavioral_disorders' as ClassTypeEnum,
    ClassForSevereBehavioralDisorders: 'class_for_severe_behavioral_disorders' as ClassTypeEnum,
    ClassForLearningDisorders: 'class_for_learning_disorders' as ClassTypeEnum,
    ClassForMultiDisability: 'class_for_multi_disability' as ClassTypeEnum,
    ClassForDeafBlind: 'class_for_deaf_blind' as ClassTypeEnum,
    ClassForAutismSpectrumDisorder: 'class_for_autism_spectrum_disorder' as ClassTypeEnum,
    ClassForGifted: 'class_for_gifted' as ClassTypeEnum,
    ClassForDd: 'class_for_dd' as ClassTypeEnum
};
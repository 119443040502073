<div class="child-detailed-info" *transloco="let t">
  <ul class="nav" role="tablist">
    <li class="nav-item">
      <a (click)="selectTab(tabs.BASIC, $event)" [class.active]="selectedTab === tabs.BASIC" id="basic" role="tab">
        {{ t('child/basicInfo') }}
      </a>
    </li>
    <li class="nav-item">
      <a (click)="selectTab(tabs.EDUCATION, $event)" [class.active]="selectedTab === tabs.EDUCATION" id="education" role="tab">
        {{ t('child/educationHealth') }}
      </a>
    </li>
    <li class="nav-item" *ngIf="!isProduction">
      <a (click)="selectTab(tabs.DIAGNOSTIC, $event)" [class.active]="selectedTab === tabs.DIAGNOSTIC" id="diagnostic" role="tab">
        {{ t('child/diagnostic') }}
      </a>
    </li>
    <li class="nav-item">
      <a (click)="selectTab(tabs.DOCUMENTS, $event)" [class.active]="selectedTab === tabs.DOCUMENTS" id="documents" role="tab">
        {{ t('child/documents') }}
      </a>
    </li>
  </ul>

  <div class="tab-content" [ngSwitch]="selectedTab">
    <app-child-info-basic-tab *ngSwitchCase="tabs.BASIC" [child]="child"></app-child-info-basic-tab>
    <app-child-info-education-tab *ngSwitchCase="tabs.EDUCATION" [child]="child"></app-child-info-education-tab>
    <app-child-info-diagnostic-tab *ngSwitchCase="tabs.DIAGNOSTIC" [child]="child"></app-child-info-diagnostic-tab>
    <app-child-info-documents-tab *ngSwitchCase="tabs.DOCUMENTS" [child]="child"></app-child-info-documents-tab>
  </div>
</div>

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `výpůjčka` - Loan * `nákup` - Purchase * `jiné` - Other
 */
export type AidPurchaseFormEnum = 'výpůjčka' | 'nákup' | 'jiné';

export const AidPurchaseFormEnum = {
    Vpjka: 'výpůjčka' as AidPurchaseFormEnum,
    Nkup: 'nákup' as AidPurchaseFormEnum,
    Jin: 'jiné' as AidPurchaseFormEnum
};
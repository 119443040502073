/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Mixin for django rest framework (djangorestframework) serializers to allow define fields in request URL.  Define 'default_fields' in Meta class, it allows to send only some subset of all fields for default request without any special params.  Define map 'field_groups' in format {'__group_name__': ('field1', 'field2'), ...} to allow use predefined field groups. Then in url use '.../endpoint/?fields=__group_name__' to get fields defined in the group. I suggest to use dunder names for groups to prevent any name collisions, i.e. __group_name__. There is automatically defined __all__ group, which returns all fields. (You can rename name of the group with attribute 'dynamic_fields_all_group_name')  You can define exact fields with keyword: 'fields' (editable by 'dynamic_fields_include_key'). Server then return ONLY these fields.  You can add extra fields to default_fields with keyword: 'extra_fields' (editable by 'dynamic_fields_extra_key'). Server then return all default_fields and these extra fields.  You can exclude some fields from default_fields with keyword: 'exclude_fields' (editable by 'dynamic_fields_exclude_key'). Server then return default_fields minus these excluded fields.  All fields are separated by comma (e.g. fields=field1,field2,...). You can edit field separator by 'dynamic_fields_separator'.
 */
export interface Child { 
    readonly id: number;
    uuid: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly birthDate: string;
    readonly gender: string;
    readonly fullName: string;
    kindergartenId?: number;
    readonly revision: string;
    readonly created: string;
    readonly modified: string;
    readonly groupNames: string;
    readonly groupIds: string;
    readonly groupUuids: string;
    /**
     * Return if child is in any kindergarten.
     */
    readonly inAnyKindergarten: boolean;
    setGroups?: Array<string>;
    readonly kindergarten: { [key: string]: any; };
    readonly isConnectedToParent: boolean;
}
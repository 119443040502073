import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ToastService } from '@isophi/core-ui';
import { AbsenceEnum, AbsenceReasonEnum, DayOffsService } from '@isophi/mng-api';
import { NgbActiveModal, NgbDate, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { compareDesc, formatDate, isEqual } from 'date-fns';
import { Subscription } from 'rxjs';

import { FORMAT_DATE } from '../../../core/constants/constant';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-excuse-modal',
  templateUrl: './excuse-modal.component.html',
  styleUrls: ['./excuse-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcuseModalComponent implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AbsenceEnum = AbsenceEnum;

  absence: AbsenceEnum;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AbsenceReasonEnum = AbsenceReasonEnum;

  absenceReason: string;

  hoveredDate: NgbDate | null = null;

  dateFrom = this.helperService.parseDate(formatDate(new Date(), FORMAT_DATE, {}));

  dateTo = this.dateFrom;

  equalDates = true;

  private _subs: Subscription[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private helperService: HelperService,
    private toastr: ToastService,
    private translocoService: TranslocoService,
    private config: NgbDatepickerConfig,
    private dayOffsService: DayOffsService
  ) {
    // Disable weekends
    this._subs.push(
      this.dayOffsService.dayOffsList().subscribe((holidays) => {
        this.config.markDisabled = (date: NgbDateStruct) => this.helperService.checkHolidayDates(date, holidays.results);
      })
    );
  }

  selectedData() {
    this.equalDates = isEqual(this.helperService.formateDate(this.dateFrom), this.helperService.formateDate(this.dateTo));
    if (!this.equalDates) {
      this.absence = this.AbsenceEnum.Absent;
    }
  }

  submitButton(): void {
    const dateFrom = this.helperService.formateDate(this.dateFrom);
    const dateTo = this.helperService.formateDate(this.dateTo);

    if (compareDesc(dateFrom, dateTo) > -1) {
      this.activeModal.close({
        absence: this.absence,
        absenceReason: this.absenceReason,
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
    } else {
      this.toastr.error(this.translocoService.translate('evidence/dateError'));
    }
  }

  ngOnDestroy(): void {
    this.config.markDisabled = undefined;
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}

/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { ChildGroupProfile } from './childGroupProfile';
import { ClassTypeDesignationEnum } from './classTypeDesignationEnum';
import { ClassTypeEnum } from './classTypeEnum';
import { NullEnum } from './nullEnum';
import { OperationTypeEnum } from './operationTypeEnum';
import { TeacherAssistantEnum } from './teacherAssistantEnum';
import { TeachingLanguageEnum } from './teachingLanguageEnum';

export interface ChildGroupDetail { 
    uuid?: string;
    name?: string;
    classType?: ClassTypeEnum | BlankEnum | NullEnum;
    teacherNames?: string;
    readonly childCount: number;
    readonly children: Array<ChildGroupProfile>;
    operationType?: OperationTypeEnum;
    teachingLanguage?: TeachingLanguageEnum;
    classTypeDesignation?: ClassTypeDesignationEnum | BlankEnum | NullEnum;
    teacherAssistant?: TeacherAssistantEnum | NullEnum;
    readonly isArchived: string;
}
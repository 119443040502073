/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `bez identifikovaného nadání nebo mimořádného nadání` - No Talent * `s identifikovaným nadáním, s potřebou PO` - Is Talented * `s identifikovaným mimořádným nadáním, s potřebou PO` - Is Extraordinary Talented
 */
export type LevelOfTalentEnum = 'bez identifikovaného nadání nebo mimořádného nadání' | 's identifikovaným nadáním, s potřebou PO' | 's identifikovaným mimořádným nadáním, s potřebou PO';

export const LevelOfTalentEnum = {
    BezIdentifikovanhoNadnNeboMimodnhoNadn: 'bez identifikovaného nadání nebo mimořádného nadání' as LevelOfTalentEnum,
    SIdentifikovanmNadnmSPotebouPO: 's identifikovaným nadáním, s potřebou PO' as LevelOfTalentEnum,
    SIdentifikovanmMimodnmNadnmSPotebouPO: 's identifikovaným mimořádným nadáním, s potřebou PO' as LevelOfTalentEnum
};